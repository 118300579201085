import React from 'react';
import { Redirect } from 'react-router-dom';
import { shape, oneOfType, object } from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import PrescriptionForm from '../../containers/PrescriptionForm/PrescriptionForm';

import { VERIFIED } from '../../constants/deaAndLicenseStatus';
import { ROLE_VET } from '../../constants/roles';

const propTypes = {
  match: oneOfType([object]).isRequired,
  history: shape({}).isRequired,
  stateStore: mobxPropTypes.observableObject.isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
};

const PrescriptionEditPage = ({
  match,
  history,
  stateStore,
  authStore: { getRole, getLicenseStatus },
}) => {
  const states = stateStore.states
    ? stateStore.states.reduce(
      (acc, state) => acc.concat({
        label: state.name,
        value: state.id,
      }),
      [],
    )
    : [];
  const prescriptionId = parseInt(match.params.prescriptionId, 10);
  const typeId = parseInt(match.params.typeId, 10);
  const props = {
    history,
    match,
    states,
    role: getRole,
    type: match.params.type,
  };

  if (!Number.isNaN(typeId)) {
    props.typeId = typeId;
  }

  if (!Number.isNaN(prescriptionId)) {
    props.prescriptionId = prescriptionId;
  }

  const isValid = getRole === ROLE_VET ? getLicenseStatus === VERIFIED : true;

  return isValid ? <PrescriptionForm {...props} /> : <Redirect to="/prescriptions" />;
};

PrescriptionEditPage.propTypes = propTypes;
export default inject('authStore', 'stateStore')(observer(PrescriptionEditPage));
