import { observable, action, decorate } from 'mobx';

class LoadingStore {
  constructor() {
    this.ready = true;
  }

  setReady(status) {
    this.ready = status;
  }

  get isReady() {
    return this.ready;
  }
}

decorate(LoadingStore, {
  ready: observable,
  setReady: action,
});

export default LoadingStore;
