export const VERIFIED = 'Verified';
export const REJECTED = 'Rejected';
export const NEW = 'New';
export const EXPIRED = 'Expired';

export const DEA_LICENSE_STATUS_LABEL = {
  [VERIFIED]: 'Verified',
  [REJECTED]: 'Rejected',
  [NEW]: 'New',
  [EXPIRED]: 'Expired',
};

export const DEA_LICENSE_STATUS_COLOR = {
  [VERIFIED]: 'green',
  [REJECTED]: 'yellow',
  [NEW]: 'blue',
  [EXPIRED]: 'red',
};

export const DEA_LICENSE_STATUS_LIST = Object.keys(DEA_LICENSE_STATUS_LABEL).map(value => ({
  label: DEA_LICENSE_STATUS_LABEL[value],
  value,
}));
