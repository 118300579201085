import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  LinearProgress,
} from '@material-ui/core';
import {
  Menu as MenuIcon, AccountCircle, Person, ExitToApp, ArrowBack,
} from '@material-ui/icons';
import grey from '@material-ui/core/colors/grey';

import {
  LOGIN,
  PROFILE,
  PATH_NAME,
  USERS_EDIT,
  PATH_LABEL,
  CUSTOMERS_ADD,
  CUSTOMERS_EDIT,
  MEDICATIONS_VIEW,
  TECH_BULLETINS_VIEW,
  JOURNAL_ARTICLES_AND_PUBLICATIONS,
  PRESCRIPTIONS_ADD,
  PRESCRIPTIONS_EDIT,
  ONLINE_REFILL_EDIT,
  REFILL_REQUEST_EDIT,
} from '../../constants/paths';

const styles = theme => ({
  barRoot: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  barItem: {
    alignSelf: 'center',
  },
  accountIcon: {
    fontSize: 45,
  },
  menuIcon: {
    fontSize: 20,
    color: grey[600],
    margin: theme.spacing.unit,
  },
  appBar: {
    position: 'fixed',
    marginLeft: theme.drawer ? theme.drawer.width : 240,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${theme.drawer ? theme.drawer.width : 240}px)`,
    },
    boxShadow: 'none',
  },
  navIconHide: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  iconButton: {
    padding: 0,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  toggleHandler: PropTypes.func.isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
  loadingStore: mobxPropTypes.observableObject.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

class AppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  getTitle = () => {
    const { history } = this.props;
    const { pathname } = history.location;

    let title = PATH_LABEL[pathname];

    if (title === undefined && pathname.indexOf(PATH_NAME[USERS_EDIT]) !== -1) {
      title = PATH_LABEL[PATH_NAME[USERS_EDIT]];
    }

    if (title === undefined && pathname.indexOf(PATH_NAME[CUSTOMERS_EDIT]) !== -1) {
      title = PATH_LABEL[PATH_NAME[CUSTOMERS_EDIT]];
    }

    if (title === undefined && pathname.indexOf(PATH_NAME[ONLINE_REFILL_EDIT]) !== -1) {
      title = PATH_LABEL[PATH_NAME[ONLINE_REFILL_EDIT]];
    }

    if (title === undefined && pathname.indexOf(PATH_NAME[PRESCRIPTIONS_EDIT]) !== -1) {
      title = PATH_LABEL[PATH_NAME[PRESCRIPTIONS_EDIT]];
    }

    if (title === undefined && pathname.indexOf(PATH_NAME[PRESCRIPTIONS_ADD]) !== -1) {
      title = PATH_LABEL[PATH_NAME[PRESCRIPTIONS_ADD]];
    }

    if (title === undefined && pathname.indexOf(PATH_NAME[MEDICATIONS_VIEW]) !== -1) {
      title = PATH_LABEL[PATH_NAME[MEDICATIONS_VIEW]];
    }

    if (title === undefined
      && pathname.indexOf(PATH_NAME[JOURNAL_ARTICLES_AND_PUBLICATIONS]) !== -1) {
      title = PATH_LABEL[PATH_NAME[JOURNAL_ARTICLES_AND_PUBLICATIONS]];
    }

    if (title === undefined && pathname.indexOf(PATH_NAME[TECH_BULLETINS_VIEW]) !== -1) {
      title = PATH_LABEL[PATH_NAME[TECH_BULLETINS_VIEW]];
    }

    if (title === undefined && pathname.indexOf(PATH_NAME[REFILL_REQUEST_EDIT]) !== -1) {
      title = PATH_LABEL[PATH_NAME[REFILL_REQUEST_EDIT]];
    }

    if (title === undefined && pathname.indexOf(PATH_NAME[CUSTOMERS_ADD]) !== -1) {
      title = PATH_LABEL[PATH_NAME[CUSTOMERS_ADD]];
    }

    return title;
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleProfile = () => {
    const { history } = this.props;

    history.push(PATH_NAME[PROFILE]);
    this.handleClose();
  };

  handleLogout = () => {
    const { authStore, history } = this.props;
    history.push(PATH_NAME[LOGIN]);
    authStore.logout();
  };

  render() {
    const {
      classes, toggleHandler, loadingStore, history,
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const isSubPage = history.location.pathname.split('/').length > 2;

    let loader = null;
    if (!loadingStore.isReady) {
      loader = <LinearProgress color="secondary" />;
    }

    return (
      <MuiAppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="Open drawer"
            onClick={toggleHandler}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.barRoot}>
            <div className={classes.barItem}>
              <Typography className={classes.title} variant="h6" color="primary" noWrap>
                {isSubPage && (
                  <IconButton color="primary" aria-label="Go Back" onClick={history.goBack}>
                    <ArrowBack />
                  </IconButton>
                )}
                {this.getTitle()}
              </Typography>
            </div>
            <div className={classes.barItem}>
              <IconButton
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="primary"
                className={classes.iconButton}
              >
                <AccountCircle className={classes.accountIcon} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.handleProfile}>
                  <Person className={classes.menuIcon} />
                  <Typography variant="subtitle1">Profile</Typography>
                </MenuItem>
                <MenuItem onClick={this.handleLogout}>
                  <ExitToApp className={classes.menuIcon} />
                  <Typography variant="subtitle1">Logout</Typography>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
        {loader}
      </MuiAppBar>
    );
  }
}

AppBar.propTypes = propTypes;
const AppBarStore = inject('authStore', 'loadingStore')(observer(AppBar));
const AppBarWithRouter = withRouter(AppBarStore);
export default withStyles(styles, { withTheme: true })(AppBarWithRouter);
