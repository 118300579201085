import React from 'react';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import FormCard from '../../components/Form/FormCard';

const fields = [
  {
    title: 'Change Password',
    fields: [
      {
        id: 'oldPassword',
        name: 'oldPassword',
        label: 'Current Password',
        type: 'password',
        validateAs: 'string',
        defaultVal: '',
        required: true,
        span: { xs: 0, md: 3, sm: 3 },
      },
      {
        id: 'newPassword',
        name: 'newPassword',
        label: 'New Password',
        type: 'password',
        validateAs: 'password',
        defaultVal: '',
        required: true,
        passwordSchema: {
          min: 8,
          matches: /(\d*[a-zA-Z]+\d*)+/,
          errorMsg: "New Password can't be entirely numeric.",
        },
      },
      {
        id: 'confirmNewPassword',
        name: 'confirmNewPassword',
        label: 'Confirm New Password',
        type: 'password',
        validateAs: 'confirm',
        defaultVal: '',
        required: true,
        confirmSchema: {
          compareAgainst: 'newPassword',
          errorMsg: "Passwords don't match.",
        },
      },
    ],
  },
];

const propTypes = {
  authStore: mobxPropTypes.observableObject.isRequired,
};

const ChangePasswordForm = ({ authStore }) => (
  <FormCard
    fields={fields}
    onSubmit={async (values, { resetForm }) => {
      try {
        await authStore.changePassword(values);
        resetForm();
      } catch (e) {
        resetForm();
      }
    }}
    submitColor="secondary"
    submitLabel="Update Password"
    columns={4}
    spacing={24}
  />
);

ChangePasswordForm.propTypes = propTypes;
export default inject('authStore')(observer(ChangePasswordForm));
