import React from 'react';
import PropTypes from 'prop-types';
import { Fab as MuiFab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 10,
    right: theme.spacing.unit * 3,
    zIndex: 999,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
};

const Fab = ({ classes, onClick }) => (
  <MuiFab color="secondary" className={classes.fab} onClick={onClick}>
    <AddIcon />
  </MuiFab>
);

Fab.propTypes = propTypes;
export default withStyles(styles)(Fab);
