import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

class ConfirmEmail extends React.Component {
  static propTypes = {
    authStore: mobxPropTypes.observableObject.isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  async componentWillMount() {
    const { match, authStore } = this.props;
    const { confirmationToken } = match.params;

    await authStore.confirmEmail(confirmationToken);
  }

  render() {
    return <Redirect to="/login/" />;
  }
}

export default inject('authStore')(observer(ConfirmEmail));
