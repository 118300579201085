import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PrescriptionsTable from '../../containers/PrescriptionsTable/PrescriptionsTable';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const PrescriptionsListPage = ({ classes, ...props }) => (
  <div className={classes.list}>
    <PrescriptionsTable {...props} paginate />
  </div>
);

PrescriptionsListPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(PrescriptionsListPage);
