import React from 'react';
import {
  string, oneOfType, object, arrayOf, shape, func, bool,
} from 'prop-types';
import {
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import StatusIcon from '../StatusIcon/StatusIcon';

const styles = theme => ({
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  icon: {},
  primary: {},
  rightIcon: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: '-20px',
  },
  blue: {
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 137, 255)',
    '&:hover': {
      backgroundColor: 'rgb(0, 137, 255)',
    },
  },
  yellow: {
    color: '#FFFFFF',
    backgroundColor: 'rgb(255, 186, 0)',
    '&:hover': {
      backgroundColor: 'rgb(255, 186, 0)',
    },
  },
  green: {
    color: '#FFFFFF',
    backgroundColor: 'rgb(70, 201, 58)',
    '&:hover': {
      backgroundColor: 'rgb(70, 201, 58)',
    },
  },
  red: {
    color: '#FFFFFF',
    backgroundColor: 'rgb(255, 71, 87)',
    '&:hover': {
      backgroundColor: 'rgb(255, 71, 87)',
    },
  },
  blueDisabled: {
    color: 'rgb(0, 137, 255)',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  yellowDisabled: {
    color: 'rgb(255, 186, 0)',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  greenDisabled: {
    color: 'rgb(70, 201, 58)',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  redDisabled: {
    color: 'rgb(255, 71, 87)',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  popper: {
    zIndex: 999,
  },
});

const propTypes = {
  classes: oneOfType([object]).isRequired,
  list: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  colors: oneOfType([object]).isRequired,
  selected: string.isRequired,
  onChange: func,
  readOnly: bool,
};

const defaultProps = {
  onChange: () => {},
  readOnly: false,
};

class StatusButton extends React.Component {
  state = {
    open: false,
    selectedColor: null,
    selectedLabel: null,
    list: [],
  };

  componentWillMount() {
    const { selected } = this.props;
    this.setSelected(selected);
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  setSelected = (value) => {
    const { colors, list } = this.props;
    const color = colors[value];
    const { label } = list.find(item => item.value === value);
    const result = list.filter(item => item.value !== value);
    this.setState({
      selectedColor: color,
      selectedLabel: label,
      list: result,
      open: false,
    });
  };

  handleChange = async (value) => {
    const { onChange } = this.props;

    if (await onChange(value)) {
      this.setSelected(value);
    } else {
      this.setState({ open: false });
    }
  };

  render() {
    const { classes, colors, readOnly } = this.props;
    const {
      open, selectedColor, selectedLabel, list,
    } = this.state;
    if (readOnly) {
      return (
        <Button
          aria-label={selectedLabel}
          size="large"
          variant="contained"
          color="primary"
          disableRipple
          className={classes[`${selectedColor}Disabled`]}
        >
          <StatusIcon color={selectedColor} spacing="right" />
          {selectedLabel}
        </Button>
      );
    }
    const arrow = open ? (
      <ArrowDropUp className={classes.rightIcon} />
    ) : (
      <ArrowDropDown className={classes.rightIcon} />
    );
    return (
      <div>
        <Button
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
          size="large"
          variant="contained"
          color="primary"
          className={classes[selectedColor]}
        >
          {selectedLabel}
          {arrow}
        </Button>
        <Popper
          open={open}
          className={classes.popper}
          anchorEl={this.anchorEl}
          transition
          placement="bottom-end"
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom-end' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {list.map(item => (
                      <MenuItem
                        key={item.value}
                        className={classes.menuItem}
                        onClick={() => {
                          this.handleChange(item.value);
                        }}
                      >
                        <ListItemIcon className={classes.icon}>
                          <StatusIcon color={colors[item.value]} spacing="right" />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.primary }}
                          inset
                          primary={item.label}
                        />
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

StatusButton.propTypes = propTypes;
StatusButton.defaultProps = defaultProps;
export default withStyles(styles, { withTheme: true })(StatusButton);
