import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TablePagination, TableRow, Hidden } from '@material-ui/core';
import moment from 'moment';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import DataTable from '../../components/DataTable/DataTable';
import SearchForm from '../../components/SearchForm/SearchForm';
import TableCell from '../../components/DataTable/TableCell';

import removeEmptyKeys from '../../utils/removeEmptyKeys';

class AuditLogTable extends Component {
  static propTypes = {
    auditLogStore: mobxPropTypes.observableObject.isRequired,
    loadingStore: mobxPropTypes.observableObject.isRequired,
    userStore: mobxPropTypes.observableObject.isRequired,
    paginate: PropTypes.bool,
    showEmptyRows: PropTypes.bool,
    search: PropTypes.bool,
    defaultLimit: PropTypes.number,
  };

  static defaultProps = {
    paginate: false,
    search: false,
    showEmptyRows: false,
    defaultLimit: 10,
  };

  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;

    this.state = {
      data: [],
      _limit: defaultLimit,
      filters: {},
      total: 0,
      page: 0,
      defaultUsers: [],
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'Logged User' },
      { id: 2, label: 'Affected User', hidden: { xsDown: true } },
      { id: 3, label: 'Action' },
      { id: 4, label: 'Date' },
      { id: 5, label: 'IP Address', hidden: { smDown: true } },
    ];
  }

  handleClear = (clearObj) => {
    this.setState(clearObj);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.fetchData());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ _limit: event.target.value }, () => this.fetchData());
  };

  handleAutoComplete = async (input) => {
    if (!input) return [];

    const { userStore } = this.props;
    return userStore.fetchUsers(input);
  };

  handleReset = async () => {
    this.setState(
      {
        filters: {},
        clearLoggedUser: true,
        clearAffectedUser: true,
      },
      () => this.fetchData(),
    );
  };

  fetchData = async (searchFilters = null, reset = false) => {
    const { auditLogStore, loadingStore } = this.props;
    loadingStore.setReady(false);

    const { _limit, page, filters } = this.state;
    const newFilters = removeEmptyKeys(searchFilters || filters);

    if (newFilters.created_at_gte && newFilters.created_atb_lte) {
      newFilters.created_at_gte = moment(newFilters.created_at_gte)
        .startOf('day')
        .format();
      newFilters.created_atb_lte = moment(newFilters.created_atb_lte)
        .endOf('day')
        .format();
    }

    const offset = _limit * page;
    const params = Object.assign(
      { _limit, _start: offset, _sort: 'id:DESC' },
      reset ? {} : newFilters,
    );

    try {
      const data = await auditLogStore.api.activitylogs.get({ id: null, params });

      this.setState({
        data: data.results || [],
        filters: reset ? {} : newFilters,
        total: data.totalCount || 0,
      });
    } catch (error) {
      this.setState({
        data: [],
        filters: newFilters,
      });
    }

    loadingStore.setReady(true);
  };

  fetchUsers = async () => {
    const { userStore } = this.props;
    const users = await userStore.fetchUsers();
    this.setState({ defaultUsers: users });
  };

  renderRows = () => {
    const { data } = this.state;

    return data.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell component="th" scope="row" padding="default">
          {`${row.loggedUser.firstName} ${row.loggedUser.lastName}`}
        </TableCell>
        <Hidden xsDown>
          <TableCell>
            {row.affectedUser ? `${row.affectedUser.firstName} ${row.affectedUser.lastName}` : '-'}
          </TableCell>
        </Hidden>
        <TableCell>
          {row.action}
        </TableCell>
        <TableCell>
          {moment(row.createdAt).format('MMM DD, YYYY hh:mm:ss a')}
        </TableCell>
        <Hidden smDown>
          <TableCell>
            {row.ipAddress}
          </TableCell>
        </Hidden>
      </TableRow>
    ));
  };

  renderPagination = () => {
    const { total, _limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={_limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  };

  renderSearch = () => {
    const { defaultUsers, clearLoggedUser, clearAffectedUser } = this.state;
    const fields = [
      {
        id: 'loggedUser',
        name: 'loggedUser',
        label: 'Logged User',
        type: 'autocomplete',
        validateAs: 'number',
        defaultVal: '',
        required: false,
        customProps: {
          defaultOptions: defaultUsers,
          loadOptions: this.handleAutoComplete,
          handleClear: this.handleClear,
          clearLoggedUser,
        },
      },
      {
        id: 'affectedUser',
        name: 'affectedUser',
        label: 'Affected User',
        type: 'autocomplete',
        validateAs: 'number',
        defaultVal: '',
        required: false,
        customProps: {
          defaultOptions: defaultUsers,
          loadOptions: this.handleAutoComplete,
          handleClear: this.handleClear,
          clearAffectedUser,
        },
      },
      {
        id: 'created_at_gte',
        name: 'created_at_gte',
        label: 'Date From',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        id: 'created_at_lte',
        name: 'created_at_lte',
        label: 'Date To',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
    ];

    return <SearchForm onSubmit={this.fetchData} onReset={this.handleReset} fields={fields} />;
  };

  render() {
    const { data, _limit, page } = this.state;
    const { paginate, search, showEmptyRows } = this.props;
    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = _limit - Math.min(_limit, data.length - page * _limit);
    }
    const searchComponent = search ? this.renderSearch() : null;
    return (
      <Fragment>
        {searchComponent}
        <DataTable
          header={AuditLogTable.getTableHeader()}
          renderRows={this.renderRows}
          emptyRows={emptyRows}
          paginate={paginate}
          renderPagination={this.renderPagination}
        />
      </Fragment>
    );
  }
}

export default inject('auditLogStore', 'userStore', 'loadingStore')(observer(AuditLogTable));
