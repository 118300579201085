import React, { Fragment } from 'react';
import {
  object, arrayOf, bool, func,
} from 'prop-types';
import { TableRow, Hidden } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import ConfirmDialogButton from '../../components/ConfirmDialogButton/ConfirmDialogButton';
import FormDialogButton from '../../components/FormDialogButton/FormDialogButton';

const propTypes = {
  prescriptions: arrayOf(object).isRequired,
  readOnly: bool,
  empty: bool,
  onEdit: func,
  onDelete: func,
};

const defaultProps = {
  readOnly: false,
  empty: true,
  onEdit: () => {},
  onDelete: () => {},
};

class PrescriptionsInnerTable extends React.Component {
  static getTableHeader() {
    return [
      { id: 1, label: 'Prescription ID' },
      { id: 2, label: 'Medication' },
      { id: 3, label: 'Animal', hidden: { xsDown: true } },
      { id: 4, label: 'Quantity', hidden: { smDown: true } },
      { id: 5, label: 'Comments', hidden: { smDown: true } },
      { id: 6, label: '' },
    ];
  }

  renderRows = () => {
    const {
      prescriptions, readOnly, onEdit, onDelete,
    } = this.props;
    if (!prescriptions) return null;

    return prescriptions.map((row, key) => (
      <TableRow hover tabIndex={-1} key={key.toString()}>
        <TableCell component="th" scope="row" padding="default">
          {`# ${row.prescriptionNumber}`}
        </TableCell>
        <TableCell component="th" scope="row" padding="default">
          {row.medicationName}
        </TableCell>
        <Hidden xsDown>
          <TableCell>
            {row.animalName}
          </TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell>
            {row.quantity}
          </TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell>
            {row.message}
          </TableCell>
        </Hidden>
        <TableCell padding="none" isActions>
          {!readOnly && (
            <Fragment>
              <FormDialogButton
                title={`Edit Prescription # ${row.prescriptionNumber}`}
                icon={<EditIcon />}
                fields={[
                  {
                    id: 'quantity',
                    name: 'quantity',
                    label: 'Quantity',
                    type: 'text',
                    validateAs: 'string',
                    defaultVal: row ? row.quantity : '',
                    required: true,
                  },
                  {
                    id: 'message',
                    name: 'message',
                    label: 'Comments',
                    type: 'text',
                    validateAs: 'string',
                    defaultVal: row ? row.message : '',
                    required: true,
                  },
                ]}
                onConfirm={async (value) => {
                  onEdit(row, value);
                }}
              />
              {prescriptions.length > 1 && (
                <ConfirmDialogButton
                  onConfirm={() => onDelete(row)}
                  title={`Delete Prescription # ${row.prescriptionNumber}`}
                  body={`Are you sure you want to delete the prescription # ${
                    row.prescriptionNumber
                  } ?`}
                  confirmText="Yes, Delete"
                  icon={<DeleteIcon color="error" />}
                />
              )}
            </Fragment>
          )}
        </TableCell>
      </TableRow>
    ));
  };

  render() {
    const { empty } = this.props;
    return (
      <DataTable
        header={PrescriptionsInnerTable.getTableHeader()}
        renderRows={this.renderRows}
        emptyRows={0}
        paginate={false}
        card={false}
        empty={empty}
      />
    );
  }
}

PrescriptionsInnerTable.propTypes = propTypes;
PrescriptionsInnerTable.defaultProps = defaultProps;
export default PrescriptionsInnerTable;
