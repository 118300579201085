import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Snackbar as BaseSnackbar,
  SnackbarContent,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  root: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 4,
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit + 8,
  },
  message: {
    display: 'block',
    paddingRight: 40,
  },
  close: {
    position: 'absolute',
    right: 15,
    top: 5,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  snackbarStore: mobxPropTypes.observableObject.isRequired,
};

class Snackbar extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { snackbarStore } = this.props;
    snackbarStore.close();
  }

  render() {
    const { classes, snackbarStore } = this.props;
    const {
      isOpen, duration, variant, title, messages, vertical, horizontal,
    } = snackbarStore;
    const Icon = variantIcon[variant];

    return (
      <BaseSnackbar
        open={isOpen}
        autoHideDuration={duration}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical,
          horizontal,
        }}
      >
        <SnackbarContent
          classes={{
            root: classes.root,
          }}
          className={classes[variant]}
          message={(
            <div id="client-snackbar" className={classes.message}>
              <div className={classes.title}>
                <Icon className={classes.iconVariant} />
                <span>
                  {title}
                </span>
              </div>
              {messages.length > 0 && (
              <ul>
                {messages.map(text => (
                  <li key={text}>
                    {text}
                  </li>
                ))}
              </ul>
              )}
            </div>
)}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </BaseSnackbar>
    );
  }
}

Snackbar.propTypes = propTypes;
const SnackbarStore = inject('snackbarStore')(observer(Snackbar));
export default withStyles(styles)(SnackbarStore);
