import React from 'react';
import { shape, oneOfType, object } from 'prop-types';
import MedicationView from '../../containers/MedicationView/MedicationView';

const propTypes = {
  match: oneOfType([object]).isRequired,
  history: shape({}).isRequired,
};

const MedicationViewPage = ({ match, history }) => {
  const medicationId = parseInt(match.params.medicationId, 10);
  const props = {
    match,
    history,
  };

  if (!Number.isNaN(medicationId)) {
    props.medicationId = medicationId;
  }

  return <MedicationView {...props} />;
};

MedicationViewPage.propTypes = propTypes;
export default MedicationViewPage;
