import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import Form from '../../components/Form/FormCard';
import { ROLE_VET, ROLE_NON_VETERINARIAN } from '../../constants/roles';

import removeEmptyKeys from '../../utils/removeEmptyKeys';

class ProfileForm extends Component {
  static propTypes = {
    authStore: mobxPropTypes.observableObject.isRequired,
    stateStore: mobxPropTypes.observableObject.isRequired,
    user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  formatPayload = (data) => {
    const { user } = this.props;
    const payload = new FormData();
    const {
      firstName, lastName, email, phoneNumber, ...rest
    } = data;

    payload.append('role', user.role.id);
    payload.append('username', user.username);
    payload.append('firstName', firstName);
    payload.append('lastName', lastName);
    payload.append('email', email);
    payload.append('phoneNumber', phoneNumber);

    if (user.role.id === ROLE_VET) {
      const { deaFile, licenseFile, ...restVet } = rest;

      payload.append(
        'vetProfile',
        JSON.stringify({
          ...removeEmptyKeys(restVet),
        }),
      );

      if (deaFile) {
        payload.append('deaFile', deaFile);
      }
      payload.append('licenseFile', licenseFile);
    } else if (user.role.id === ROLE_NON_VETERINARIAN) {
      payload.append(
        'nonVeterinarian',
        JSON.stringify({
          ...removeEmptyKeys(rest),
        }),
      );
    }

    return payload;
  };

  render() {
    const { user, authStore, stateStore } = this.props;

    const states = stateStore.states
      ? stateStore.states.reduce(
        (acc, state) => acc.concat({
          label: state.name,
          value: state.id,
        }),
        [],
      )
      : [];

    const fields = [
      {
        title: 'User Information',
        fields: [
          {
            id: 'firstName',
            name: 'firstName',
            label: 'First Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.firstName || '',
            required: true,
          },
          {
            id: 'lastName',
            name: 'lastName',
            label: 'Last Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.lastName || '',
            required: true,
          },
          {
            id: 'email',
            name: 'email',
            label: 'Email',
            type: 'email',
            validateAs: 'email',
            defaultVal: user.email || '',
            required: true,
          },
          {
            id: 'phoneNumber',
            name: 'phoneNumber',
            label: 'Phone Number',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.phoneNumber || '',
            required: false,
          },
        ],
      },
    ];

    const vetFields = [
      {
        title: 'Veterinary Clinic / Practice',
        fields: [
          {
            id: 'clinicName',
            name: 'clinicName',
            label: 'Name of Veterinary Clinic / Practice',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.vetprofile ? user.vetprofile.clinicName : '',
            required: true,
          },
          {
            id: 'clinicStreet',
            name: 'clinicStreet',
            label: 'Street',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.vetprofile ? user.vetprofile.clinicStreet : '',
            required: true,
          },
          {
            id: 'clinicCity',
            name: 'clinicCity',
            label: 'City',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.vetprofile ? user.vetprofile.clinicCity : '',
            required: true,
          },
          {
            id: 'clinicState',
            name: 'clinicState',
            label: 'State',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: user.vetprofile ? user.vetprofile.clinicState : '',
            required: true,
            customProps: {
              inputProps: {
                name: 'clinicState',
                id: 'clinicState',
              },
              data: states,
            },
          },
          {
            id: 'clinicZip',
            name: 'clinicZip',
            label: 'Zip Code',
            type: 'number',
            validateAs: 'number',
            defaultVal: user.vetprofile ? user.vetprofile.clinicZip : '',
            required: true,
          },
        ],
      },
      {
        title: 'Licence Information',
        fields: [
          {
            id: 'licenseNumber',
            name: 'licenseNumber',
            label: 'License Number',
            type: 'text',
            validateAs: 'text',
            defaultVal: user.vetprofile ? user.vetprofile.licenseNumber : '',
            required: true,
          },
          {
            id: 'licenseExpiration',
            name: 'licenseExpiration',
            label: 'License Expiration Date',
            type: 'date',
            validateAs: 'string',
            defaultVal: user.vetprofile
              ? moment(user.vetprofile.licenseExpiration).format('YYYY-MM-DD')
              : '',
            required: false,
            customProps: {
              InputLabelProps: {
                shrink: true,
              },
            },
          },
          {
            id: 'licenseFile',
            name: 'licenseFile',
            label: 'License File',
            type: 'upload',
            validateAs: 'string',
            defaultVal: '',
            required: false,
            customProps: {
              defaultFile: user.vetprofile ? user.vetprofile.licenseFile : null,
              accept: 'application/pdf, .jpg, .jpeg, .png',
              model: 'vetprofiles',
            },
          },
        ],
      },
      {
        title: 'DEA Information',
        fields: [
          {
            id: 'deaNumber',
            name: 'deaNumber',
            label: 'DEA Number',
            type: 'text',
            validateAs: 'text',
            defaultVal: user.vetprofile ? user.vetprofile.deaNumber || '' : '',
            required: false,
          },
          {
            id: 'deaFile',
            name: 'deaFile',
            label: 'Dea File',
            type: 'upload',
            validateAs: 'string',
            defaultVal: '',
            required: false,
            customProps: {
              defaultFile: user.vetprofile ? user.vetprofile.deaFile : null,
              accept: 'application/pdf, .jpg, .jpeg, .png',
              model: 'vetprofiles',
            },
          },
        ],
      },
    ];

    const nonVetFields = [
      {
        title: '',
        fields: [
          {
            id: 'actualRole',
            name: 'actualRole',
            label: 'Role',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.nonveterinarian ? user.nonveterinarian.actualRole : '',
            required: true,
          },
          {
            id: 'organization',
            name: 'organization',
            label: 'Organization Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.nonveterinarian ? user.nonveterinarian.organization : '',
            required: true,
          },
          {
            id: 'street',
            name: 'street',
            label: 'Street',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.nonveterinarian ? user.nonveterinarian.street : '',
            required: true,
          },
          {
            id: 'state',
            name: 'state',
            label: 'State',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: user.nonveterinarian ? user.nonveterinarian.state : '',
            required: true,
            customProps: {
              inputProps: {
                name: 'state',
                id: 'state',
              },
              data: states,
            },
          },
          {
            id: 'city',
            name: 'city',
            label: 'City',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.nonveterinarian ? user.nonveterinarian.city : '',
            required: true,
          },
          {
            id: 'zip',
            name: 'zip',
            label: 'Zip',
            type: 'text',
            validateAs: 'string',
            defaultVal: user.nonveterinarian ? user.nonveterinarian.zip : '',
            required: true,
          },
        ],
      },
    ];

    let elements = fields;

    if (user.role.id === ROLE_VET) {
      elements = fields.concat(vetFields);
    } else if (user.role.id === ROLE_NON_VETERINARIAN) {
      elements = fields.concat(nonVetFields);
    }

    return (
      <Form
        fields={elements}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await authStore.updateProfile(user.id, this.formatPayload(values));
            setSubmitting(false);
          } catch (e) {
            setSubmitting(false);
          }
        }}
        submitColor="secondary"
        submitLabel="Save Profile"
        columns={4}
        spacing={24}
      />
    );
  }
}

export default inject('authStore', 'stateStore')(observer(ProfileForm));
