import React from 'react';
import { Redirect } from 'react-router-dom';
import { oneOfType, object } from 'prop-types';
import {
  Grid, Paper, Typography, Button, Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import config from '../config';
import logo from '../assets/images/login-logo.png';
import {
  ROLE_VET, ROLE_PHARMACIST, ROLE_WATCHER, ROLE_NON_VETERINARIAN,
} from '../constants/roles';

const styles = theme => ({
  contentWrapper: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing.unit * 3,
  },
  mainPaper: { margin: theme.spacing.unit },
  logoContainer: { padding: theme.spacing.unit },
  registerBtn: { color: `${theme.palette.primary.contrastText} !important` },
});

const propTypes = {
  classes: oneOfType([object]).isRequired,
  history: oneOfType([object]).isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
};

const PortalLogin = ({ classes, history, authStore }) => {
  const goToLoginPage = () => history.push('/login');
  const { isLoggedIn } = authStore;
  if (isLoggedIn) {
    let from = '';
    const { getRole } = authStore;

    switch (getRole) {
      case ROLE_PHARMACIST:
      case ROLE_WATCHER:
        from = '/prescriptions';
        break;
      case ROLE_VET:
        from = '/medications';
        break;
      case ROLE_NON_VETERINARIAN:
        from = '/medications';
        break;
      default:
        from = '/profile';
        break;
    }
    return <Redirect to={from} />;
  }

  return (
    <div className={classes.contentWrapper}>
      <Paper className={classes.mainPaper}>
        <Grid item container justify="center" xs={12}>
          <Grid item className={classes.logoContainer}>
            <img src={logo} alt="ZooPharm" />
          </Grid>
        </Grid>
        <Divider />
        <Grid item container justify="space-around" alignItems="center" xs={12}>
          <Grid item xs={12} sm={5}>
            <Paper elevation={0} className={classes.paper}>
              <Typography paragraph variant="h6">
                CREATE AN ACCOUNT
              </Typography>
              <Typography paragraph variant="body2">
                To access ZooPharm Portal you need to create an account.
              </Typography>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.registerBtn}
                href={`${config.site}/register-for-access/`}
              >
                REGISTER NOW
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5} style={{ backgroundColor: 'green' }}>
            <Paper elevation={0} className={classes.paper}>
              <Typography paragraph variant="h6">
                PORTAL LOGIN
              </Typography>
              <Typography paragraph variant="body2">
                If you already have an account on ZooPharm Portal, access through the link below.
              </Typography>
              <Button fullWidth color="primary" variant="contained" onClick={goToLoginPage}>
                LOGIN
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

PortalLogin.propTypes = propTypes;
const PortalLoginStyled = withStyles(styles, { withTheme: true })(PortalLogin);
export default inject('authStore')(observer(PortalLoginStyled));
