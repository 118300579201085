import { observable, action, decorate } from 'mobx';

import errorParse from '../utils/errorParse';

class UserStore {
  constructor(api, snackbarStore, loadingStore) {
    this.api = api;
    this.snackbarStore = snackbarStore;
    this.loadingStore = loadingStore;
    this.users = null;
  }

  setUsers(users) {
    this.users = users;
  }

  get getUsers() {
    return this.users;
  }

  async fetch() {
    try {
      const response = await this.api.users.get();
      if (response) {
        this.setUsers(response);
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    } catch (e) {
      e.error = true;
      return Promise.reject(e);
    }
  }

  async fetchUser(id) {
    this.loadingStore.setReady(false);

    try {
      const response = await this.api.users.get({ id });
      this.loadingStore.setReady(true);
      return response;
    } catch (e) {
      this.snackbarStore.setError({
        title: e.response.status === 404 ? 'User not found' : 'Sorry, an error occurred.',
      });
      this.loadingStore.setReady(true);
      e.error = true;
      return e;
    }
  }

  async fetchUsers(name, role = null) {
    const params = {
      firstName_contains: name,
      ...(role ? { role } : null),
    };

    let results = [];
    const response = await this.api.users.get({ id: null, params });

    if (response && response.results) {
      results = response.results.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));
    }
    return results;
  }

  async create(payload) {
    this.loadingStore.setReady(false);

    try {
      await this.api.users.create(payload);

      this.snackbarStore.setSuccess({
        title: 'User has been successfully created.',
      });
      this.loadingStore.setReady(true);

      return true;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred. ${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });
      this.loadingStore.setReady(true);
      return false;
    }
  }

  async update(id, payload) {
    this.loadingStore.setReady(false);

    try {
      const result = await this.api.users.update(id, payload);

      this.snackbarStore.setSuccess({
        title: 'User has been successfully updated.',
      });
      this.loadingStore.setReady(true);

      return result;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred.${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return false;
    }
  }
}

decorate(UserStore, {
  users: observable,
  setUsers: action,
});

export default UserStore;
