import { observable, action, decorate } from 'mobx';

class TechBulletinsStore {
  constructor(api, snackbarStore, loadingStore) {
    this.api = api;
    this.snackbarStore = snackbarStore;
    this.loadingStore = loadingStore;
    this.techBulletins = null;
  }
}

decorate(TechBulletinsStore, {
  techBulletins: observable,
  setTechBulletins: action,
});

export default TechBulletinsStore;
