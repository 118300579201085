import React from 'react';
import { Typography } from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import Banner from '../../components/Banner/Banner';
import {
  VERIFIED,
} from '../../constants/deaAndLicenseStatus';

const propTypes = {
  authStore: mobxPropTypes.observableObject.isRequired,
};

const FaxPrescriptionsBanner = ({ authStore }) => {
  const config = {
    [VERIFIED]: {
      type: 'info',
      content: [
        'To complete the prescription submission process, ',
        'please fax a copy of the uploaded prescription(s) ',
        'to (307) 761-6060 in compliance with State and Federal ',
        'laws. ZooPharm Customer Service may be reached at ',
        '(866) 823-9314, Monday – Friday, 8:00 – 5:00 Mountain Time Zone.',
      ].join(''),
    },
  };
  const { getUser } = authStore;
  const { vetprofile } = getUser;
  const { licenseStatus } = vetprofile;
  const bannerProps = config[licenseStatus];

  return (
    licenseStatus && licenseStatus === VERIFIED && (
      <Banner open type={bannerProps.type} dismissable={false}>
        <Typography variant="body2" color="primary">
          {bannerProps.content}
        </Typography>
      </Banner>
    )
  );
};

FaxPrescriptionsBanner.propTypes = propTypes;
export default inject('authStore')(observer(FaxPrescriptionsBanner));
