import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import RefillRequestForm from '../../containers/RefillRequestForm/RefillRequestForm';
import { VERIFIED } from '../../constants/deaAndLicenseStatus';

const propTypes = {
  stateStore: mobxPropTypes.observableObject.isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
};

const RefillRequestAddPage = ({ stateStore, authStore: { getLicenseStatus }, ...props }) => {
  const states = stateStore.states
    ? stateStore.states.reduce(
      (acc, state) => acc.concat({
        label: state.name,
        value: state.id,
      }),
      [],
    )
    : [];

  return getLicenseStatus === VERIFIED ? (
    <RefillRequestForm {...props} states={states} />
  ) : (
    <Redirect to="/refill-request" />
  );
};

RefillRequestAddPage.propTypes = propTypes;
export default inject('authStore', 'stateStore')(observer(RefillRequestAddPage));
