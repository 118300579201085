import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Edit, Visibility } from '@material-ui/icons';
import {
  TablePagination, TableRow, IconButton, Hidden, Typography,
} from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import {
  DEA_LICENSE_STATUS_LIST,
  DEA_LICENSE_STATUS_LABEL,
  DEA_LICENSE_STATUS_COLOR,
} from '../../constants/deaAndLicenseStatus';
import { ROLE_LIST, ROLE_VET, ROLE_WATCHER } from '../../constants/roles';
import { STATUS_LIST, STATUS_LABEL } from '../../constants/users';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import StatusIcon from '../../components/StatusIcon/StatusIcon';
import Banner from '../../components/Banner/Banner';

import removeEmptyKeys from '../../utils/removeEmptyKeys';

class UsersTable extends Component {
  static propTypes = {
    userStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    loadingStore: mobxPropTypes.observableObject.isRequired,
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    paginate: PropTypes.bool,
    showEmptyRows: PropTypes.bool,
    search: PropTypes.bool,
    defaultLimit: PropTypes.number,
  };

  static defaultProps = {
    paginate: false,
    search: false,
    showEmptyRows: false,
    defaultLimit: 10,
  };

  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;

    this.state = {
      data: [],
      _limit: defaultLimit,
      filters: { role: ROLE_VET },
      total: 0,
      page: 0,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'Name' },
      { id: 2, label: 'Email', hidden: { xsDown: true } },
      { id: 3, label: 'Vet License Number', hidden: { smDown: true } },
      { id: 4, label: 'Status of Vet License', hidden: { smDown: true } },
      { id: 5, label: 'Status' },
      { id: 6, label: '' },
    ];
  }

  fetchData = async (searchFilters = null, reset = false) => {
    const { userStore, loadingStore } = this.props;
    loadingStore.setReady(false);

    const { _limit, page, filters } = this.state;

    const newFilters = removeEmptyKeys(searchFilters || filters);
    if (newFilters) {
      if (newFilters.name_contains) {
        newFilters.firstName_contains = searchFilters.name_contains;
        newFilters.lastName_contains = searchFilters.name_contains;
        delete newFilters.name_contains;
      }
    }
    const offset = _limit * page;
    const params = Object.assign(
      { _limit, _start: offset, _sort: 'id:DESC' },
      reset ? {} : newFilters,
    );

    try {
      const users = await userStore.api.users.get({ id: null, params });

      this.setState({
        data: users.results,
        filters: reset ? {} : newFilters,
        total: users.totalCount,
      });
    } catch (error) {
      this.setState({
        data: [],
        filters: newFilters,
      });
    }

    loadingStore.setReady(true);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.fetchData());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ _limit: event.target.value }, () => this.fetchData());
  };

  handleEditClick = (row) => {
    const { history } = this.props;
    history.push(`/users/edit/${row.id}`);
  };

  renderRows = () => {
    const { data } = this.state;
    const { authStore } = this.props;
    const { getRole } = authStore;

    return data.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell component="th" scope="row" padding="default">
          {`${row.firstName} ${row.lastName}`}
        </TableCell>
        <Hidden xsDown>
          <TableCell>{row.email}</TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell>
            <Typography variant="button" color="textSecondary" gutterBottom>
              {row.vetprofile ? row.vetprofile.licenseNumber : '-'}
            </Typography>
          </TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell>
            {row.vetprofile ? (
              <React.Fragment>
                <StatusIcon
                  color={DEA_LICENSE_STATUS_COLOR[row.vetprofile.licenseStatus]}
                  spacing="right"
                />
                {DEA_LICENSE_STATUS_LABEL[row.vetprofile.licenseStatus]}
              </React.Fragment>
            ) : (
              '-'
            )}
          </TableCell>
        </Hidden>
        <TableCell>{STATUS_LABEL[row.blocked]}</TableCell>
        <TableCell padding="none" isActions>
          <IconButton
            aria-label={getRole === ROLE_WATCHER ? 'View' : 'Edit'}
            color="secondary"
            onClick={() => this.handleEditClick(row)}
          >
            {getRole === ROLE_WATCHER ? <Visibility /> : <Edit />}
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  renderPagination = () => {
    const { total, _limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={_limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  };

  renderSearch = () => {
    const fields = [
      {
        id: 'name_contains',
        name: 'name_contains',
        label: 'Name',
        type: 'text',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
      },
      {
        id: 'role',
        name: 'role',
        label: 'Role',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'text',
        defaultVal: ROLE_VET,
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
        customProps: {
          inputProps: {
            name: 'role',
            id: 'role',
          },
          data: ROLE_LIST,
        },
      },
      {
        id: 'blocked',
        name: 'blocked',
        label: 'Status',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'bool',
        defaultVal: '',
        required: false,
        customProps: {
          inputProps: {
            name: 'blocked',
            id: 'blocked',
          },
          data: STATUS_LIST,
        },
      },
      {
        id: 'licenseStatus',
        name: 'licenseStatus',
        label: 'Status of Vet License',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'text',
        defaultVal: '',
        required: false,
        customProps: {
          inputProps: {
            name: 'licenseStatus',
            id: 'licenseStatus',
          },
          data: DEA_LICENSE_STATUS_LIST,
        },
      },
      {
        id: 'deaStatus',
        name: 'deaStatus',
        label: 'Status of the DEA Number',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'text',
        defaultVal: '',
        required: false,
        customProps: {
          inputProps: {
            name: 'deaStatus',
            id: 'deaStatus',
          },
          data: DEA_LICENSE_STATUS_LIST,
        },
      },
    ];

    return (
      <SearchForm
        onSubmit={this.fetchData}
        onReset={() => this.fetchData(null, true)}
        fields={fields}
      />
    );
  };

  render() {
    const { data, _limit, page } = this.state;
    const {
      paginate,
      search,
      showEmptyRows,
      authStore: { getRole },
    } = this.props;
    const isWatcher = getRole === ROLE_WATCHER;

    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = _limit - Math.min(_limit, data.length - page * _limit);
    }

    const searchComponent = search ? this.renderSearch() : null;

    return (
      <React.Fragment>
        {!isWatcher && (
          <Banner open type="info" dismissable={false}>
            <Typography variant="body2" color="primary">
              {'To verify veterinary licence number, please '}
              <a href="www" target="_blank" rel="noopener noreferrer">
                {'click here'}
              </a>
            </Typography>
          </Banner>
        )}
        {searchComponent}
        <DataTable
          header={UsersTable.getTableHeader()}
          renderRows={this.renderRows}
          emptyRows={emptyRows}
          paginate={paginate}
          renderPagination={this.renderPagination}
          empty={data.length === 0}
        />
      </React.Fragment>
    );
  }
}

export default inject('authStore', 'userStore', 'loadingStore')(observer(UsersTable));
