import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import OnlineRefillsTable from '../../containers/OnlineRefillsTable/OnlineRefillsTable';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const OnlineRefillsList = ({ classes, ...props }) => (
  <div className={classes.list}>
    <OnlineRefillsTable {...props} paginate />
  </div>
);

OnlineRefillsList.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(OnlineRefillsList);
