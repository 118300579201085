import React, { Component, Fragment } from 'react';
import {
  shape, number, arrayOf, object, oneOf,
} from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import FaxPrescriptionsBanner from '../FaxPrescriptionsBanner/FaxPrescriptionsBanner';

import FormCard from '../../components/Form/FormCard';
import FormHeader from '../../components/FormHeader/FormHeader';

import {
  NEW,
  PRESCRIPTIONS_STATUS_COLOR,
  PRESCRIPTIONS_STATUS_LIST,
} from '../../constants/prescriptions';
import { VERIFIED } from '../../constants/deaAndLicenseStatus';

class PrescriptionVetForm extends Component {
  static propTypes = {
    prescriptionStore: mobxPropTypes.observableObject.isRequired,
    medicationStore: mobxPropTypes.observableObject.isRequired,
    customerStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    states: arrayOf(object).isRequired,
    history: shape({}).isRequired,
    prescriptionId: number,
    type: oneOf(['medication', 'client']),
    typeId: number,
  };

  static defaultProps = {
    prescriptionId: null,
    type: null,
    typeId: null,
  };

  state = {
    customer: null,
    medication: null,
    showFields: false,
    prescription: null,
    defaultCustomers: [],
  };

  async componentWillMount() {
    const {
      prescriptionId, type, typeId, customerStore,
    } = this.props;

    const customers = await customerStore.fetchCustomers();
    this.setState({ defaultCustomers: customers });

    if (prescriptionId) {
      const { prescriptionStore } = this.props;
      const prescription = await prescriptionStore.fetchPrescription(prescriptionId);

      if (prescription.id) {
        this.setState({ prescription, showFields: true });
      }
    }

    if (type && typeId) {
      if (type === 'medication') {
        const { medicationStore } = this.props;
        const medication = await medicationStore.fetchMedication(typeId);

        if (medication.id) {
          this.setState({ medication, showFields: true });
        }
      } else if (type === 'client') {
        const customer = await customerStore.fetchCustomer(typeId);

        if (customer.id) {
          this.setState({
            customer: {
              ...customer,
              state: customer.state.id,
            },
            showFields: true,
          });
        }
      }
    }
  }

  formatPayload = (data) => {
    const payload = new FormData();
    const { prescriptionFile, ...fields } = data;

    Object.keys(fields).forEach((key) => {
      payload.append(key, fields[key]);
    });
    payload.append('prescriptionFile', prescriptionFile);

    return payload;
  };

  handleClear = (clearObj) => {
    this.setState(clearObj);
  };

  handleCustomersAutoComplete = (input) => {
    if (!input) return [];

    const { customerStore } = this.props;

    return customerStore.fetchCustomers(input);
  };

  handleMedicationsAutoComplete = (input) => {
    if (!input) return [];

    const {
      medicationStore,
      authStore: { getDeaStatus },
    } = this.props;
    const filter = {
      ...(getDeaStatus !== VERIFIED && { deaControlled: false }),
    };

    return medicationStore.fetchMedications(input, filter);
  };

  handleCreate = async (values) => {
    const { prescriptionStore, history } = this.props;
    const prescription = await prescriptionStore.create(values);

    if (prescription) {
      history.push('/prescriptions');
    }
  };

  handleUpdate = async (id, values) => {
    const { prescriptionStore } = this.props;
    const result = await prescriptionStore.update(id, values);

    if (result) {
      this.setState({ prescription: result });
    }
  };

  fetchCustomer = async (val, setFieldValue) => {
    if (val === 'create_customer') {
      const { history, prescriptionId } = this.props;
      history.push(
        prescriptionId
          ? `/clients/add/prescription/${prescriptionId}`
          : '/clients/add/prescription',
      );
    } else {
      const { customerStore } = this.props;
      const customer = await customerStore.fetchCustomer(val);

      setFieldValue('customerEmail', customer.email);
      setFieldValue('customerPhoneNumber', customer.phoneNumber);
      setFieldValue('customerStreet', customer.street);
      setFieldValue('customerCity', customer.city);
      setFieldValue('customerState', customer.state.id);
      setFieldValue('customerZip', customer.zip);

      this.setState({ showFields: true });
    }
  };

  fetchShippingData = async (val, setFieldValue, formValues) => {
    const data = {};
    const { authStore, customerStore } = this.props;

    if (val === 'customer' && formValues.customer) {
      const customer = await customerStore.fetchCustomer(formValues.customer);

      data.name = `${customer.firstName} ${customer.lastName}`;
      data.email = customer.email;
      data.street = customer.street;
      data.city = customer.city;
      data.state = customer.state.id;
      data.zip = customer.zip;
    } else if (val === 'profile') {
      data.name = `${authStore.getUser.firstName} ${authStore.getUser.lastName}`;
      data.email = authStore.getUser.email;
      data.street = authStore.getUser.vetprofile.clinicStreet;
      data.city = authStore.getUser.vetprofile.clinicCity;
      data.state = authStore.getUser.vetprofile.clinicState;
      data.zip = authStore.getUser.vetprofile.clinicZip;
    }

    setFieldValue('shippingName', data.name || '');
    setFieldValue('shippingEmail', data.email || '');
    setFieldValue('shippingStreet', data.street || '');
    setFieldValue('shippingCity', data.city || '');
    setFieldValue('shippingState', data.state || '');
    setFieldValue('shippingZip', data.zip || '');
  };

  render() {
    const {
      prescriptionId,
      states,
      type,
      typeId,
      authStore: { getUser: prescribing },
    } = this.props;
    const {
      prescription, medication, customer, showFields, defaultCustomers,
    } = this.state;

    if (prescriptionId && !prescription) return null;
    if (type && typeId && !(medication || customer)) return null;

    const readOnly = prescription && prescription.status !== NEW;

    const medicationFieldVals = {
      defaultVal:
        (prescription ? prescription.medication.id : null) || (medication ? medication.id : null),
      defaultInputValue:
        (prescription
          ? `${prescription.medication.name} ${prescription.medication.presentation}`
          : null) || (medication ? medication.name : null),
    };

    const customerFieldVals = {
      defaultVal:
        (customer ? customer.id : null) || (prescription ? prescription.customer.id : null),
      defaultInputValue:
        (customer ? `${customer.firstName} ${customer.lastName}` : null)
        || (prescription
          ? `${prescription.customer.firstName} ${prescription.customer.lastName}`
          : null),
    };

    const fieldsBlock = [
      {
        title: 'Client Information',
        fields: [
          {
            id: 'customer',
            name: 'customer',
            label: 'Client Name',
            type: 'autocomplete',
            validateAs: 'string',
            defaultVal: customerFieldVals.defaultVal || '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
            customProps: {
              defaultInputValue: customerFieldVals.defaultInputValue || '',
              defaultOptions: [
                ...defaultCustomers,
                {
                  label: 'Create Client',
                  value: 'create_customer',
                },
              ],
              loadOptions: this.handleCustomersAutoComplete,
              handleClear: this.handleClear,
              handleOnChange: this.fetchCustomer,
              clearCustomer: false,
            },
          },
          {
            id: 'customerEmail',
            name: 'customerEmail',
            label: 'Email',
            type: 'email',
            validateAs: 'email',
            defaultVal:
              (customer && customer.email) || (prescription ? prescription.customer.email : ''),
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerPhoneNumber',
            name: 'customerPhoneNumber',
            label: 'Phone Number',
            type: 'text',
            validateAs: 'string',
            defaultVal:
              (customer && customer.phoneNumber)
              || (prescription ? prescription.customer.phoneNumber : ''),
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerStreet',
            name: 'customerStreet',
            label: 'Street',
            type: 'text',
            validateAs: 'text',
            defaultVal:
              (customer && customer.street) || (prescription ? prescription.customer.street : ''),
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerCity',
            name: 'customerCity',
            label: 'City',
            type: 'text',
            validateAs: 'text',
            defaultVal:
              (customer && customer.city) || (prescription ? prescription.customer.city : ''),
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerState',
            name: 'customerState',
            label: 'State',
            type: 'select',
            validateAs: 'text',
            defaultVal:
              (customer && customer.state) || (prescription ? prescription.customer.state.id : ''),
            required: false,
            disabled: true,
            show: showFields,
            placeholder: 'Select one',
            customProps: {
              inputProps: {
                name: 'customerState',
                id: 'customerState',
              },
              data: states,
            },
          },
          {
            id: 'customerZip',
            name: 'customerZip',
            label: 'Zip code',
            type: 'number',
            validateAs: 'text',
            defaultVal:
              (customer && customer.zip) || (prescription ? prescription.customer.zip : ''),
            required: false,
            disabled: true,
            show: showFields,
          },
        ],
      },
      {
        title: 'Animal',
        show: showFields,
        fields: [
          {
            id: 'animalspecie',
            name: 'animalspecie',
            label: 'Animal / Species',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription ? prescription.animalspecie : '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
        ],
      },
      {
        title: 'Shipping Information',
        show: showFields,
        fields: [
          {
            id: 'shippingSelectData',
            name: 'shippingSelectData',
            label: 'Use pre exist shipping data',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: '',
            required: false,
            customProps: {
              inputProps: {
                name: 'shippingSelectData',
                id: 'shippingSelectData',
              },
              data: [
                {
                  value: 'customer',
                  label: 'Use client information',
                },
                {
                  value: 'profile',
                  label: 'Use my profile information',
                },
              ],
              handleOnChange: this.fetchShippingData,
            },
            span: { xs: 0, sm: 9, md: 9 },
          },
          {
            id: 'shippingName',
            name: 'shippingName',
            label: 'Complete Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription ? prescription.shippingName : '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'shippingEmail',
            name: 'shippingEmail',
            label: 'Email',
            type: 'email',
            validateAs: 'email',
            defaultVal: prescription ? prescription.shippingEmail : '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'shippingStreet',
            name: 'shippingStreet',
            label: 'Street',
            type: 'text',
            validateAs: 'text',
            defaultVal: prescription ? prescription.shippingStreet : '',
            required: true,
          },
          {
            id: 'shippingCity',
            name: 'shippingCity',
            label: 'City',
            type: 'text',
            validateAs: 'text',
            defaultVal: prescription ? prescription.shippingCity : '',
            required: true,
          },
          {
            id: 'shippingState',
            name: 'shippingState',
            label: 'State',
            type: 'select',
            validateAs: 'text',
            defaultVal: prescription ? prescription.shippingState.id : '',
            required: true,
            placeholder: 'Select one',
            customProps: {
              inputProps: {
                name: 'shippingState',
                id: 'shippingState',
              },
              data: states,
            },
          },
          {
            id: 'shippingZip',
            name: 'shippingZip',
            label: 'Zip code',
            type: 'number',
            validateAs: 'number',
            defaultVal: prescription ? prescription.shippingZip : '',
            required: true,
          },
        ],
      },
      {
        title: 'Medication Information',
        show: showFields,
        fields: [
          {
            id: 'medicationOfficeUse',
            name: 'medicationOfficeUse',
            label: 'Office Use',
            type: 'checkbox',
            validateAs: 'mixed',
            defaultVal: prescription ? prescription.medicationOfficeUse : '',
            span: { xs: 0, sm: 9, md: 9 },
          },
          {
            id: 'medication',
            name: 'medication',
            label: 'Medication',
            type: 'autocomplete',
            validateAs: 'text',
            defaultVal: medicationFieldVals.defaultVal || '',
            required: true,
            customProps: {
              defaultInputValue: medicationFieldVals.defaultInputValue || '',
              defaultOptions: [],
              loadOptions: this.handleMedicationsAutoComplete,
              handleClear: this.handleClear,
              clearMedication: false,
            },
          },
          {
            id: 'medicationQuantity',
            name: 'medicationQuantity',
            label: 'Quantity',
            type: 'number',
            validateAs: 'number',
            defaultVal: prescription ? prescription.medicationQuantity : '',
            required: true,
          },
          {
            id: 'medicationRefills',
            name: 'medicationRefills',
            label: 'Refill',
            type: 'number',
            validateAs: 'number',
            defaultVal: prescription ? prescription.medicationRefills : '',
            required: true,
          },
          {
            id: 'medicationNumAnimals',
            name: 'medicationNumAnimals',
            label: '# of Animals',
            type: 'number',
            validateAs: 'number',
            defaultVal: prescription ? prescription.medicationNumAnimals : '',
            required: true,
          },
          {
            id: 'medicationDirectionsUse',
            name: 'medicationDirectionsUse',
            label: 'Directions of Use',
            type: 'text',
            validateAs: 'text',
            defaultVal: prescription ? prescription.medicationDirectionsUse : '',
            required: true,
            span: { xs: 0, sm: 9, md: 9 },
            customProps: {
              InputLabelProps: {
                shrink: true,
              },
            },
          },
        ],
      },
      {
        title: 'Scanned Paper-Based Prescription',
        show: showFields,
        fields: [
          {
            id: 'prescriptionFile',
            name: 'prescriptionFile',
            label: 'Scaned Prescription',
            type: 'upload',
            validateAs: 'string',
            defaultVal: '',
            required: !prescription,
            customProps: {
              model: 'prescriptions',
              defaultFile: prescription ? prescription.prescriptionFile : null,
              accept: 'application/pdf, .jpg, .jpeg, .png',
            },
          },
        ],
      },
      {
        title: 'Prescribing by',
        show: showFields,
        fields: [
          {
            id: 'veterinaryName',
            name: 'veterinaryName',
            label: 'Veterinary Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription
              ? `${prescription.user.firstName} ${prescription.user.lastName}`
              : `${prescribing.firstName} ${prescribing.lastName}`,
            disabled: true,
          },
          {
            id: 'veterinaryAddress',
            name: 'veterinaryAddress',
            label: 'Veterinary Address',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription
              ? prescription.user.vetprofile.clinicStreet
              : prescribing.vetprofile.clinicStreet,
            disabled: true,
          },
          {
            id: 'veterinaryPhone',
            name: 'veterinaryPhone',
            label: 'Phone Number',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription
              ? prescription.user.phoneNumber || ''
              : prescribing.phoneNumber || '',
            disabled: true,
          },
          {
            id: 'veterinaryDeaNumber',
            name: 'veterinaryDeaNumber',
            label: 'DEA License Number',
            type: 'string',
            validateAs: 'string',
            defaultVal: prescription
              ? prescription.user.vetprofile.deaNumber || ''
              : prescribing.vetprofile.deaNumber || '',
            disabled: true,
          },
        ],
      },
    ];

    return (
      <Fragment>
        <FaxPrescriptionsBanner />
        {prescription && (
          <FormHeader
            readOnly
            onChangeStatus={() => {}}
            status={prescription.status}
            date={prescription.created_at}
            statusList={PRESCRIPTIONS_STATUS_LIST}
            statusColors={PRESCRIPTIONS_STATUS_COLOR}
            title={`ZooPharm Prescription Number ${prescription.prescriptionId || '-'}`}
          />
        )}
        <FormCard
          readOnly={readOnly}
          fields={fieldsBlock}
          onSubmit={async (values, { setSubmitting }) => {
            const payload = this.formatPayload(values);

            try {
              if (prescription) {
                await this.handleUpdate(prescription.id, payload);
              } else {
                await this.handleCreate(payload);
              }

              setSubmitting(false);
            } catch (e) {
              setSubmitting(false);
            }
          }}
          termsOfServices={{
            required: true,
            type: 'checkbox',
            show: showFields,
            id: 'termsOfServices',
            validateAs: 'onlyTrue',
            name: 'termsOfServices',
            defaultVal: !!prescription,
            label: [
              'I hereby confirm that the information uploaded is correct. ',
              'I understand that due to State and Federal laws, prescriptions ',
              'must be faxed to pharmacy at (307) 761-6060 to complete this process.',
            ].join(''),
          }}
          columns={4}
          spacing={24}
          submitColor="secondary"
          showSubmitBtn={showFields}
          submitLabel="Save Prescription"
        />
      </Fragment>
    );
  }
}

export default inject('authStore', 'customerStore', 'medicationStore', 'prescriptionStore')(
  observer(PrescriptionVetForm),
);
