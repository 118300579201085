import React from 'react';
import { shape, oneOfType, object } from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import OnlineRefillForm from '../../containers/OnlineRefillForm/OnlineRefillForm';

const propTypes = {
  match: oneOfType([object]).isRequired,
  history: shape({}).isRequired,
  stateStore: mobxPropTypes.observableObject.isRequired,
};

const OnlineRefillEditPage = ({ match, history, stateStore }) => {
  const refillId = parseInt(match.params.refillId, 10);
  const states = stateStore.states
    ? stateStore.states.reduce(
      (acc, state) => acc.concat({
        label: state.name,
        value: state.id,
      }),
      [],
    )
    : [];
  const props = {
    history,
    match,
    states,
  };

  if (!Number.isNaN(refillId)) {
    props.refillId = refillId;
  }

  return <OnlineRefillForm {...props} />;
};

OnlineRefillEditPage.propTypes = propTypes;
export default inject('stateStore')(observer(OnlineRefillEditPage));
