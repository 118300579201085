import React from 'react';
import PropTypes from 'prop-types';
import AuditLogTable from '../containers/AuditLogTable/AuditLogTable';

const propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const Activity = ({ history }) => <AuditLogTable history={history} paginate search />;

Activity.propTypes = propTypes;
export default Activity;
