import React from 'react';
import moment from 'moment';
import {
  string, oneOfType, object, func, bool, arrayOf, shape,
} from 'prop-types';
import { Typography, IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { withStyles } from '@material-ui/core/styles';

import StatusButton from '../StatusButton/StatusButton';

const styles = () => ({
  date: {
    margin: 0,
    fontSize: 18,
    fontWeight: 600,
  },
  title: {
    margin: 0,
    fontSize: 14,
    lineHeight: 1.6,
    fontWeight: 500,
    color: '#808080',
  },
  subtitle: {
    fontWeight: 'bold',
    color: '#333333b3',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 22,
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  printBtn: {
    marginLeft: 15,
    backgroundColor: '#fff',
  },
});

const propTypes = {
  date: string.isRequired,
  title: string.isRequired,
  subtitle: string,
  status: string.isRequired,
  statusList: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  statusColors: oneOfType([object]).isRequired,
  onChangeStatus: func.isRequired,
  print: bool,
  onPrint: func,
  readOnly: bool,
  classes: oneOfType([object]).isRequired,
};

const defaultProps = {
  subtitle: '',
  print: false,
  onPrint: () => {},
  readOnly: false,
};

const FormHeader = ({
  date,
  title,
  subtitle,
  status,
  statusList,
  statusColors,
  onChangeStatus,
  print,
  onPrint,
  readOnly,
  classes,
}) => (
  <div className={classes.container}>
    <div>
      <Typography className={classes.date}>
        {title}
        {subtitle ? (
          <span className={classes.subtitle}>
            {`#${subtitle}`}
          </span>
        ) : ''}
      </Typography>
      <Typography className={classes.title}>
        {`Date ${moment(date).format('MMM D, YYYY')}`}
      </Typography>
    </div>
    <div className={classes.innerContainer}>
      <StatusButton
        list={statusList}
        colors={statusColors}
        selected={status}
        onChange={onChangeStatus}
        readOnly={readOnly}
      />
      {print && !readOnly && (
        <IconButton
          aria-label="Print"
          color="secondary"
          onClick={onPrint}
          className={classes.printBtn}
        >
          <PrintIcon />
        </IconButton>
      )}
    </div>
  </div>
);

FormHeader.propTypes = propTypes;
FormHeader.defaultProps = defaultProps;
export default withStyles(styles, { withTheme: true })(FormHeader);
