import { observable, action, decorate } from 'mobx';

import errorParse from '../utils/errorParse';
import storage from '../services/storage';
import config from '../config';

class OnlineRefillsStore {
  constructor(api, snackbarStore, loadingStore) {
    this.api = api;
    this.snackbarStore = snackbarStore;
    this.loadingStore = loadingStore;
    this.onlineRefills = null;
  }

  setOnlineRefills(onlineRefills) {
    this.onlineRefills = onlineRefills;
  }

  get getOnlineRefills() {
    return this.onlineRefills;
  }

  async fetch() {
    try {
      const response = await this.api.onlineRefills.get();
      if (response) {
        this.setOnlineRefills(response);
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    } catch (e) {
      e.error = true;
      return Promise.reject(e);
    }
  }

  async fetchOnlineRefill(id) {
    this.loadingStore.setReady(false);

    try {
      const response = await this.api.onlineRefills.get({ id });
      this.loadingStore.setReady(true);
      return response;
    } catch (e) {
      this.snackbarStore.setError({
        title: e.response.status === 404 ? 'Refill Request not found' : 'Sorry, an error occurred.',
      });
      this.loadingStore.setReady(true);
      e.error = true;
      return e;
    }
  }

  async update(id, payload) {
    this.loadingStore.setReady(false);

    try {
      const data = await this.api.onlineRefills.update(id, payload);

      this.snackbarStore.setSuccess({
        title: 'Online Refill has been successfully updated.',
      });
      this.loadingStore.setReady(true);

      return data;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred.${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return false;
    }
  }

  async changeStatus(newStatus, id) {
    this.loadingStore.setReady(false);

    try {
      const data = await this.api.onlineRefills.patch(id, { status: newStatus });

      this.snackbarStore.setSuccess({
        title: 'Online Refill Request has been successfully updated status.',
      });
      this.loadingStore.setReady(true);

      return data;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred.${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return e;
    }
  }

  download(id) {
    this.loadingStore.setReady(false);

    const token = storage.getItem('token');
    this.loadingStore.setReady(true);
    return `${config.api.baseURL}/onlinerefillrequests/${id}/pdf/?token=${token}/`;
  }
}

decorate(OnlineRefillsStore, {
  onlineRefills: observable,
  setOnlineRefills: action,
});

export default OnlineRefillsStore;
