export const ENABLED = false;
export const DISABLED = true;

export const STATUS_LABEL = {
  [ENABLED]: 'Enabled',
  [DISABLED]: 'Disabled',
};

export const STATUS_LIST = Object.keys(STATUS_LABEL).map(value => ({
  label: STATUS_LABEL[value],
  value,
}));
