import { observable, action, decorate } from 'mobx';

class SpecieStore {
  constructor(api, snackbarStore, loadingStore) {
    this.api = api;
    this.snackbarStore = snackbarStore;
    this.loadingStore = loadingStore;
    this.species = null;
  }

  setSpecies(species) {
    this.species = species;
  }

  get getSpecies() {
    return this.species;
  }

  async fetch() {
    try {
      const response = await this.api.species.get();
      if (response) {
        this.setSpecies(response);
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    } catch (e) {
      e.error = true;
      return Promise.reject(e);
    }
  }

  async fetchSpecie(id) {
    this.loadingStore.setReady(false);

    try {
      const response = await this.api.species.get({ id });

      this.loadingStore.setReady(true);

      if (response) {
        return Promise.resolve(response);
      }

      return Promise.reject(response);
    } catch (e) {
      this.loadingStore.setReady(true);
      e.error = true;

      return Promise.reject(e);
    }
  }

  async fetchSpecies(name) {
    let results = [];
    const params = {
      name_contains: name,
    };

    const response = await this.api.species.get({ id: null, params });

    if (response) {
      results = response.map(specie => ({
        value: specie.id,
        label: specie.name,
      }));
    }

    return results;
  }
}

decorate(SpecieStore, {
  species: observable,
  setSpecies: action,
});

export default SpecieStore;
