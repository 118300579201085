import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import AppBar from '../../components/AppBar/AppBar';
import Drawer from '../../components/Drawer/Drawer';
import Snackbar from '../../components/Snackbar/Snackbar';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import AddFab from '../../components/AddFab/AddFab';

import ProfilePage from '../../pages/Profile';
import LoginPage from '../../pages/Login';
import UsersPage from '../../pages/Users/Users';
import Page404 from '../../pages/404';
import ActivityPage from '../../pages/Activity';
import CustomersPage from '../../pages/Customers/Customers';
import PrescriptionPage from '../../pages/Prescriptions/Prescriptions';
import OnlineRefillsPage from '../../pages/OnlineRefills/OnlineRefills';
import MedicationsPage from '../../pages/Medications/Medications';
// import TechBulletinsPage from '../../pages/TechBulletins/TechBulletins';
import RefillRequestPage from '../../pages/RefillRequest/RefillRequest';
import PortalLoginPage from '../../pages/PortalLogin';

import Footer from '../Footer/Footer';

import {
  ROLE_PHARMACIST,
  ROLE_VET,
  ROLE_WATCHER,
  ROLE_NON_VETERINARIAN,
} from '../../constants/roles';
import { VERIFIED } from '../../constants/deaAndLicenseStatus';

import './App.css';
import 'typeface-roboto';

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflowX: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  contentLogged: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 5,
    marginTop: 55,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
    },
  },
  contentColumn: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)',
      marginLeft: 240,
    },
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
  stateStore: mobxPropTypes.observableObject.isRequired,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  async componentWillMount() {
    const { stateStore } = this.props;

    await stateStore.fetch();
  }

  handleDrawerToggle() {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  }

  render() {
    const { classes, authStore } = this.props;
    const { mobileOpen } = this.state;
    const { user, isLoggedIn, isReady } = authStore;

    let isVetRole = false;
    let isPharmacist = false;
    let isLicenseVerified = false;

    if (isLoggedIn) {
      const { getRole, getLicenseStatus } = authStore;

      isVetRole = getRole === ROLE_VET;
      isPharmacist = getRole === ROLE_PHARMACIST;
      isLicenseVerified = getLicenseStatus === VERIFIED;
    }

    const AppBarComponent = isLoggedIn ? <AppBar toggleHandler={this.handleDrawerToggle} /> : null;
    const DrawerComponent = isLoggedIn ? (
      <Drawer mobileOpen={mobileOpen} toggleHandler={this.handleDrawerToggle} role={user.role.id} />
    ) : null;

    return (
      <Router>
        {isReady && (
          <div>
            <div className={classes.root}>
              {AppBarComponent}
              {DrawerComponent}
              <main className={isLoggedIn ? classes.contentColumn : classes.content}>
                <div className={isLoggedIn ? classes.contentLogged : ''}>
                  <Switch>
                    <Route exact path="/" component={LoginPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/portal-login" component={PortalLoginPage} />
                    <PrivateRoute exact path="/profile" component={ProfilePage} />
                    <PrivateRoute
                      path="/users"
                      component={UsersPage}
                      roles={[ROLE_PHARMACIST, ROLE_WATCHER]}
                    />
                    <PrivateRoute path="/clients" component={CustomersPage} roles={[ROLE_VET]} />
                    <PrivateRoute
                      path="/medications"
                      component={MedicationsPage}
                      roles={[ROLE_VET, ROLE_NON_VETERINARIAN]}
                    />
                    {/* <PrivateRoute
                      path="/techbulletins"
                      component={TechBulletinsPage}
                      roles={[ROLE_VET, ROLE_WATCHER, ROLE_NON_VETERINARIAN]}
                    /> */}
                    <PrivateRoute
                      path="/prescriptions"
                      component={PrescriptionPage}
                      roles={[ROLE_PHARMACIST, ROLE_VET, ROLE_WATCHER]}
                    />
                    <PrivateRoute
                      path="/refill-request"
                      component={RefillRequestPage}
                      roles={[ROLE_PHARMACIST, ROLE_VET, ROLE_WATCHER]}
                    />
                    <PrivateRoute
                      path="/online-refill-request"
                      component={OnlineRefillsPage}
                      roles={[ROLE_PHARMACIST, ROLE_WATCHER]}
                    />
                    <PrivateRoute
                      exact
                      path="/activity-log"
                      component={ActivityPage}
                      roles={[ROLE_PHARMACIST, ROLE_WATCHER]}
                    />
                    <Route component={Page404} />
                  </Switch>
                </div>
                {isLoggedIn && <Footer />}
              </main>
              <Snackbar />
            </div>
            <Route exact path="/clients" component={AddFab} />
            {isPharmacist && <Route exact path="/users" component={AddFab} />}
            {isVetRole && isLicenseVerified && (
              <Fragment>
                <Route exact path="/prescriptions" component={AddFab} />
                <Route exact path="/refill-request" component={AddFab} />
              </Fragment>
            )}
          </div>
        )}
      </Router>
    );
  }
}

App.propTypes = propTypes;
const AppStore = inject('authStore', 'stateStore', 'loadingStore')(observer(App));
export default withStyles(styles, { withTheme: true })(AppStore);
