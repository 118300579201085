import React, { Component } from 'react';
import { shape, number } from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import moment from 'moment';

import FormCard from '../../components/Form/FormCard';

import {
  ROLE_LIST, ROLE_VET, ROLE_WATCHER, ROLE_NON_VETERINARIAN,
} from '../../constants/roles';
import { STATUS_LIST } from '../../constants/users';
import { DEA_LICENSE_STATUS_LIST } from '../../constants/deaAndLicenseStatus';

import removeEmptyKeys from '../../utils/removeEmptyKeys';

class UserForm extends Component {
  static propTypes = {
    userStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    stateStore: mobxPropTypes.observableObject.isRequired,
    userId: number,
    history: shape({}).isRequired,
  };

  static defaultProps = {
    userId: null,
  };

  state = {
    user: null,
    isVetRole: false,
    isNotVetRole: false,
  };

  async componentWillMount() {
    const { userId } = this.props;

    if (userId) {
      const { userStore } = this.props;
      const user = await userStore.fetchUser(userId);

      if (user.id) {
        const state = { user };
        switch (user.role.id) {
          case ROLE_VET:
            state.isVetRole = true;
            break;
          case ROLE_NON_VETERINARIAN:
            state.isNotVetRole = true;
            break;
          default:
            state.isVetRole = false;
            state.isNotVetRole = false;
            break;
        }
        this.setState(state);
      }
    }
  }

  handleChangeRole = (role) => {
    const state = {};

    switch (parseInt(role, 10)) {
      case ROLE_VET:
        state.isVetRole = true;
        state.isNotVetRole = false;
        break;
      case ROLE_NON_VETERINARIAN:
        state.isVetRole = false;
        state.isNotVetRole = true;
        break;
      default:
        state.isVetRole = false;
        state.isNotVetRole = false;
        break;
    }
    this.setState(state);
  };

  formatPayload = (data) => {
    const { isVetRole, isNotVetRole } = this.state;
    const payload = new FormData();
    const {
      firstName, lastName, email, phoneNumber, blocked, role, ...rest
    } = data;

    payload.append('firstName', firstName);
    payload.append('lastName', lastName);
    payload.append('email', email);
    payload.append('phoneNumber', phoneNumber);
    payload.append('blocked', blocked);
    payload.append('role', role);
    payload.append('username', email);

    if (isVetRole) {
      const { deaFile, licenseFile, ...restVet } = rest;

      payload.append(
        'vetProfile',
        JSON.stringify({
          ...removeEmptyKeys(restVet),
        }),
      );

      if (deaFile) {
        payload.append('deaFile', deaFile);
      }
      payload.append('licenseFile', licenseFile);
    } else if (isNotVetRole) {
      payload.append(
        'nonVeterinarian',
        JSON.stringify({
          ...removeEmptyKeys(rest),
        }),
      );
    }

    return payload;
  };

  async handleCreate(values) {
    const { userStore, history } = this.props;
    const user = await userStore.create(values);

    if (user) {
      history.push('/users');
    }
  }

  async handleUpdate(id, values) {
    const { userStore } = this.props;
    const result = await userStore.update(id, values);

    if (result) {
      this.setState({ user: result });
    }
  }

  render() {
    const { userId, stateStore, authStore } = this.props;
    const { user, isVetRole, isNotVetRole } = this.state;

    const states = stateStore.states
      ? stateStore.states.reduce(
        (acc, state) => acc.concat({
          label: state.name,
          value: state.id,
        }),
        [],
      )
      : [];

    if (userId && !user) return null;

    const vetWhen = {
      fields: ['role'],
      // eslint-disable-next-line max-len
      compare: (role, schema) => (parseInt(role, 10) === ROLE_VET ? schema.required() : schema.notRequired()),
    };

    const notVetWhen = {
      fields: ['role'],
      // eslint-disable-next-line max-len
      compare: (role, schema) => (parseInt(role, 10) === ROLE_NON_VETERINARIAN ? schema.required() : schema.notRequired()),
    };

    const fieldsBlock = [
      {
        title: 'User Information',
        fields: [
          {
            id: 'firstName',
            name: 'firstName',
            label: 'First Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: (user && user.firstName) || '',
            required: true,
          },
          {
            id: 'lastName',
            name: 'lastName',
            label: 'Last Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: (user && user.lastName) || '',
            required: true,
          },
          {
            id: 'email',
            name: 'email',
            label: 'Email',
            type: 'email',
            validateAs: 'email',
            defaultVal: (user && user.email) || '',
            required: true,
          },
          {
            id: 'phoneNumber',
            name: 'phoneNumber',
            label: 'Phone Number',
            type: 'text',
            validateAs: 'string',
            defaultVal: (user && user.phoneNumber) || '',
          },
          {
            id: 'blocked',
            name: 'blocked',
            label: 'Status',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: user && `${user.blocked}` ? `${user.blocked}` : '',
            required: true,
            customProps: {
              inputProps: {
                name: 'blocked',
                id: 'blocked',
              },
              data: STATUS_LIST,
            },
          },
          {
            id: 'role',
            name: 'role',
            label: 'Role',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: `${(user && user.role.id) || ''}`,
            required: true,
            customProps: {
              inputProps: {
                name: 'role',
                id: 'role',
              },
              data: ROLE_LIST,
              handleOnChange: this.handleChangeRole,
            },
          },
        ],
      },
      {
        title: 'Veterinary Clinic / Practice',
        show: isVetRole,
        fields: [
          {
            id: 'clinicName',
            name: 'clinicName',
            label: 'Name *',
            type: 'text',
            validateAs: 'string',
            defaultVal: user && user.vetprofile ? user.vetprofile.clinicName : '',
            when: vetWhen,
          },
          {
            id: 'clinicStreet',
            name: 'clinicStreet',
            label: 'Street *',
            type: 'text',
            validateAs: 'string',
            defaultVal: user && user.vetprofile ? user.vetprofile.clinicStreet : '',
            when: vetWhen,
          },
          {
            id: 'clinicCity',
            name: 'clinicCity',
            label: 'City *',
            type: 'text',
            validateAs: 'string',
            defaultVal: user && user.vetprofile ? user.vetprofile.clinicCity : '',
            when: vetWhen,
          },
          {
            id: 'clinicState',
            name: 'clinicState',
            label: 'State *',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: user && user.vetprofile ? user.vetprofile.clinicState : '',
            when: vetWhen,
            customProps: {
              inputProps: {
                name: 'clinicState',
                id: 'clinicState',
              },
              data: states,
            },
          },
          {
            id: 'clinicZip',
            name: 'clinicZip',
            label: 'Zip Code *',
            type: 'number',
            validateAs: 'number',
            defaultVal: user && user.vetprofile ? user.vetprofile.clinicZip || '' : '',
            when: vetWhen,
          },
        ],
      },
      {
        title: 'ZooPharm  Customer ID',
        show: isVetRole,
        fields: [
          {
            id: 'customerId',
            name: 'customerId',
            label: 'ID *',
            type: 'text',
            validateAs: 'text',
            defaultVal:
              user && user.vetprofile && user.vetprofile.customerId
                ? user.vetprofile.customerId
                : '',
            when: vetWhen,
          },
        ],
      },
      {
        title: 'Licence Information',
        show: isVetRole,
        fields: [
          {
            id: 'licenseNumber',
            name: 'licenseNumber',
            label: 'License Number *',
            type: 'text',
            validateAs: 'text',
            defaultVal: user && user.vetprofile ? user.vetprofile.licenseNumber : '',
            when: vetWhen,
          },
          {
            id: 'licenseStatus',
            name: 'licenseStatus',
            label: 'Status *',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: user && user.vetprofile ? user.vetprofile.licenseStatus : '',
            when: vetWhen,
            customProps: {
              inputProps: {
                name: 'licenseStatus',
                id: 'licenseStatus',
              },
              data: DEA_LICENSE_STATUS_LIST,
            },
          },
          {
            id: 'licenseExpiration',
            name: 'licenseExpiration',
            label: 'Veterinary License Expiration Date *',
            type: 'date',
            validateAs: 'string',
            defaultVal:
              user && user.vetprofile
                ? moment(user.vetprofile.licenseExpiration).format('YYYY-MM-DD')
                : '',
            when: vetWhen,
            customProps: {
              InputLabelProps: {
                shrink: true,
              },
            },
          },
          {
            id: 'licenseFile',
            name: 'licenseFile',
            label: 'License File *',
            type: 'upload',
            validateAs: 'string',
            defaultVal: '',
            ...(userId === null ? { when: vetWhen, required: false } : { required: false }),
            customProps: {
              model: 'vetprofiles',
              accept: 'application/pdf, .jpg, .jpeg, .png',
              defaultFile:
                user && user.vetprofile && user.vetprofile.licenseFile
                  ? user.vetprofile.licenseFile
                  : null,
            },
          },
        ],
      },
      {
        title: 'DEA Information',
        show: isVetRole,
        fields: [
          {
            id: 'deaNumber',
            name: 'deaNumber',
            label: 'DEA Number',
            type: 'text',
            validateAs: 'text',
            defaultVal: user && user.vetprofile ? user.vetprofile.deaNumber || '' : '',
            required: false,
          },
          {
            id: 'deaStatus',
            name: 'deaStatus',
            label: 'Status',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: user && user.vetprofile ? user.vetprofile.deaStatus || '' : '',
            required: false,
            customProps: {
              inputProps: {
                name: 'deaStatus',
                id: 'deaStatus',
              },
              data: DEA_LICENSE_STATUS_LIST,
            },
          },
          {
            id: 'deaFile',
            name: 'deaFile',
            label: 'Dea File',
            type: 'upload',
            validateAs: 'string',
            defaultVal: '',
            required: false,
            customProps: {
              model: 'vetprofiles',
              accept: 'application/pdf, .jpg, .jpeg, .png',
              defaultFile:
                user && user.vetprofile && user.vetprofile.deaFile ? user.vetprofile.deaFile : null,
            },
          },
        ],
      },
      {
        title: '',
        show: isNotVetRole,
        fields: [
          {
            id: 'actualRole',
            name: 'actualRole',
            label: 'Role',
            type: 'text',
            validateAs: 'text',
            defaultVal: user && user.nonveterinarian ? user.nonveterinarian.actualRole : '',
            when: notVetWhen,
          },
          {
            id: 'organization',
            name: 'organization',
            label: 'Organization Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: user && user.nonveterinarian ? user.nonveterinarian.organization : '',
            when: notVetWhen,
          },
          {
            id: 'street',
            name: 'street',
            label: 'Street',
            type: 'text',
            validateAs: 'string',
            defaultVal: user && user.nonveterinarian ? user.nonveterinarian.street : '',
            when: notVetWhen,
          },
          {
            id: 'state',
            name: 'state',
            label: 'State',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: user && user.nonveterinarian ? user.nonveterinarian.state : '',
            when: notVetWhen,
            customProps: {
              inputProps: {
                name: 'state',
                id: 'state',
              },
              data: states,
            },
          },
          {
            id: 'city',
            name: 'city',
            label: 'City',
            type: 'text',
            validateAs: 'string',
            defaultVal: user && user.nonveterinarian ? user.nonveterinarian.city : '',
            when: notVetWhen,
          },
          {
            id: 'zip',
            name: 'zip',
            label: 'Zip',
            type: 'text',
            validateAs: 'string',
            defaultVal: user && user.nonveterinarian ? user.nonveterinarian.zip : '',
            when: notVetWhen,
          },
        ],
      },
    ];

    return (
      <FormCard
        fields={fieldsBlock}
        readOnly={authStore.getRole === ROLE_WATCHER}
        onSubmit={async (values, { setSubmitting }) => {
          const payload = this.formatPayload(values);
          try {
            if (user) {
              await this.handleUpdate(user.id, payload);
            } else {
              await this.handleCreate(payload);
            }

            setSubmitting(false);
          } catch (e) {
            setSubmitting(false);
          }
        }}
        submitColor="secondary"
        submitLabel="Save User"
        columns={4}
        spacing={24}
      />
    );
  }
}

export default inject('authStore', 'userStore', 'stateStore')(observer(UserForm));
