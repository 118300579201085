import React from 'react';
import { Typography } from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import Banner from '../../components/Banner/Banner';
import {
  VERIFIED, REJECTED, NEW, EXPIRED,
} from '../../constants/deaAndLicenseStatus';

const propTypes = {
  authStore: mobxPropTypes.observableObject.isRequired,
};

const LicenseStatusBanner = ({ authStore }) => {
  const config = {
    [REJECTED]: {
      type: 'danger',
      dismissable: false,
      content:
        'Your DEA license has been rejected. Please update your information in the profile screen.',
    },
    [NEW]: {
      type: 'warning',
      dismissable: true,
      content: 'Your DEA license is being processed.',
    },
    [EXPIRED]: {
      type: 'danger',
      dismissable: false,
      content:
        'Your DEA license has expired. Please update your information in the profile screen.',
    },
    empty: {
      type: 'warning',
      dismissable: true,
      content: 'Upload your profile with a DEA license to prescribe restricted medications.',
    },
  };
  const { getDeaStatus } = authStore;
  const bannerProps = config[getDeaStatus || 'empty'];

  return (
    getDeaStatus && getDeaStatus !== VERIFIED && (
      <Banner open type={bannerProps.type} dismissable={bannerProps.dismissable}>
        <Typography variant="body2" color="primary">
          {bannerProps.content}
        </Typography>
      </Banner>
    )
  );
};

LicenseStatusBanner.propTypes = propTypes;
export default inject('authStore')(observer(LicenseStatusBanner));
