import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import logo from '../assets/images/icon-page-not-found.png';

const styles = () => ({
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 100,
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  content: {
    paddingLeft: 50,
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
  title: {
    fontSize: 80,
    lineHeight: 1,
    color: '#CCCCCC',
  },
  subTitle: {
    fontSize: 28,
    lineHeight: 2,
    color: '#757575',
  },
  messageTitle: {
    fontSize: 18,
    lineHeight: 'normal',
    color: '#757575',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const Page404 = ({ classes }) => (
  <div className={classes.error}>
    <img src={logo} alt="404-logo" />
    <div className={classes.content}>
      <Typography className={classes.title} component="h2">
        404
      </Typography>
      <Typography className={classes.subTitle} component="h3">
        Page not Found
      </Typography>
      <Typography className={classes.messageTitle}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>
    </div>
  </div>
);

Page404.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(Page404);
