import React from 'react';
import { Typography } from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import Banner from '../../components/Banner/Banner';
import {
  VERIFIED, REJECTED, NEW, EXPIRED,
} from '../../constants/deaAndLicenseStatus';

const propTypes = {
  authStore: mobxPropTypes.observableObject.isRequired,
};

const LicenseStatusBanner = ({ authStore }) => {
  const config = {
    [REJECTED]: {
      type: 'danger',
      content:
        'Your license has been rejected. Please update your information in the profile screen.',
    },
    [NEW]: {
      type: 'warning',
      content: 'Your account is being processed.',
    },
    [EXPIRED]: {
      type: 'danger',
      content: 'Your license has expired. Please update your information in the profile screen.',
    },
  };
  const { getLicenseStatus } = authStore;
  const bannerProps = config[getLicenseStatus];

  return (
    getLicenseStatus && getLicenseStatus !== VERIFIED && (
      <Banner open type={bannerProps.type} dismissable={false}>
        <Typography variant="body2" color="primary">
          {bannerProps.content}
        </Typography>
      </Banner>
    )
  );
};

LicenseStatusBanner.propTypes = propTypes;
export default inject('authStore')(observer(LicenseStatusBanner));
