import React from 'react';
import { shape } from 'prop-types';

import CustomerForm from '../../containers/CustomerForm/CustomerForm';

const propTypes = {
  match: shape({}).isRequired,
  history: shape({}).isRequired,
};

const CustomerEditPage = ({ history, match }) => {
  const { params } = match;
  const props = { history };
  const referrerId = parseInt(params.referrerId, 10);

  if (params.referrer) {
    props.referrer = params.referrer;
  }

  if (!Number.isNaN(referrerId)) {
    props.referrerId = referrerId;
  }

  return <CustomerForm {...props} />;
};

CustomerEditPage.propTypes = propTypes;
export default CustomerEditPage;
