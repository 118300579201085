import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import OnlineRefillsList from './Index';
import OnlineRefillEditPage from './Edit';

import { ROLE_PHARMACIST, ROLE_WATCHER } from '../../constants/roles';

const propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const OnlineRefillsPage = ({ match }) => (
  <Switch>
    <PrivateRoute
      path={match.url}
      exact
      component={OnlineRefillsList}
      roles={[ROLE_PHARMACIST, ROLE_WATCHER]}
    />
    <PrivateRoute
      path={`${match.url}/edit/:refillId`}
      component={OnlineRefillEditPage}
      roles={[ROLE_PHARMACIST, ROLE_WATCHER]}
    />
    <Redirect to="/online-refill-request" />
  </Switch>
);

OnlineRefillsPage.propTypes = propTypes;
export default OnlineRefillsPage;
