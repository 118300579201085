export const ROLE_VET = 5;
export const ROLE_WATCHER = 6;
export const ROLE_PHARMACIST = 4;
export const ROLE_NON_VETERINARIAN = 7;

export const ROLE_LABEL = {
  [ROLE_VET]: 'Veterinary',
  [ROLE_WATCHER]: 'Watcher',
  [ROLE_PHARMACIST]: 'Pharmacist',
  [ROLE_NON_VETERINARIAN]: 'Non Veterinarian',
};

export const ROLE_LIST = Object.keys(ROLE_LABEL).map(value => ({
  label: ROLE_LABEL[value],
  value,
}));
