import React from 'react';
import { shape } from 'prop-types';

import Fab from '../Fab/Fab';

const propTypes = {
  history: shape({}).isRequired,
  match: shape({}).isRequired,
};

const CustomersAddFab = ({ history, match }) => (
  <Fab onClick={() => history.push(`${match.url}/add`)} />
);

CustomersAddFab.propTypes = propTypes;
export default CustomersAddFab;
