export const NEW = 'New';
export const IN_PROGRESS = 'In Progress';
export const COMPLETED = 'Completed';
export const REJECTED = 'Rejected';

export const REFILLS_STATUS_LABEL = {
  [NEW]: 'New',
  [IN_PROGRESS]: 'In Progress',
  [COMPLETED]: 'Completed',
  [REJECTED]: 'Rejected',
};

export const REFILLS_STATUS_COLOR = {
  [NEW]: 'blue',
  [IN_PROGRESS]: 'yellow',
  [COMPLETED]: 'green',
  [REJECTED]: 'red',
};

export const REFILLS_STATUS_LIST = Object.keys(REFILLS_STATUS_LABEL).map(value => ({
  label: REFILLS_STATUS_LABEL[value],
  value,
}));
