import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import isMobile from '../../utils/isMobile';

import logo from '../../assets/images/footer-logo.png';
import smallLogo from '../../assets/images/footer-icon.png';

const styles = theme => ({
  container: {
    padding: `0px ${theme.spacing.unit * 5}px`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px 0px ${theme.spacing.unit
        * 2}px`,
    },
  },
  content: {
    width: '100%',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  textDescription: {
    fontSize: 13,
    '@media (max-width: 900px)': {
      fontSize: 10,
    },
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const Footer = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.content}>
      <img src={isMobile() ? smallLogo : logo} alt="footer-logo" />
      <Typography align="right" color="textSecondary" className={classes.textDescription}>
        If you have any questions or need assistance please call us at (866) 823-9314.
      </Typography>
    </div>
  </div>
);

Footer.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(Footer);
