import React from 'react';
import {
  Devices,
  Group,
  Autorenew,
  LocalHospital,
  NoteAdd,
  Assessment,
  ScreenShare,
  // Bookmarks,
  // Book,
} from '@material-ui/icons';
import {
  ROLE_PHARMACIST, ROLE_WATCHER, ROLE_VET, ROLE_NON_VETERINARIAN,
} from '../constants/roles';
import {
  PATHS,
  MEDICATIONS,
  // TECH_BULLETINS,
  // JOURNAL_ARTICLES_AND_PUBLICATIONS,
  PRESCRIPTIONS,
  CUSTOMERS,
  REFILL_REQUEST,
  USERS,
  ACTIVITY,
  ONLINE_REFILL,
  WEBSITE,
} from '../constants/paths';

let routes = [];
routes = PATHS.map((item) => {
  let icon = null;
  let external = false;
  switch (item.key) {
    case USERS:
    case CUSTOMERS:
      icon = <Group />;
      break;
    case MEDICATIONS:
      icon = <LocalHospital />;
      break;
    // case TECH_BULLETINS:
    //   icon = <Bookmarks />;
    //   break;
    // case JOURNAL_ARTICLES_AND_PUBLICATIONS:
    //   icon = <Book />;
    //   external = true;
    //   break;
    case PRESCRIPTIONS:
      icon = <NoteAdd />;
      break;
    case REFILL_REQUEST:
      icon = <Autorenew />;
      break;
    case ONLINE_REFILL:
      icon = <ScreenShare />;
      break;
    case ACTIVITY:
      icon = <Assessment />;
      break;
    case WEBSITE:
      icon = <Devices />;
      external = true;
      break;
    default:
      icon = null;
      break;
  }
  return Object.assign({ icon, external }, item);
});

const adminRoutes = routes.reduce((newRoutes, route) => {
  if (
    route.key === USERS
    || route.key === PRESCRIPTIONS
    || route.key === REFILL_REQUEST
    || route.key === ONLINE_REFILL
    // || route.key === JOURNAL_ARTICLES_AND_PUBLICATIONS
    || route.key === ACTIVITY
    || route.key === WEBSITE
  ) {
    newRoutes.push(route);
  }
  return newRoutes;
}, []);

const watcherRoutes = routes.reduce((newRoutes, route) => {
  if (
    route.key === USERS
    || route.key === PRESCRIPTIONS
    || route.key === REFILL_REQUEST
    || route.key === ONLINE_REFILL
    // || route.key === TECH_BULLETINS
    // || route.key === JOURNAL_ARTICLES_AND_PUBLICATIONS
    || route.key === ACTIVITY
    || route.key === WEBSITE
  ) {
    newRoutes.push(route);
  }
  return newRoutes;
}, []);

const vetRoutes = routes.reduce((newRoutes, route) => {
  if (
    route.key === CUSTOMERS
    || route.key === MEDICATIONS
    // || route.key === TECH_BULLETINS
    // || route.key === JOURNAL_ARTICLES_AND_PUBLICATIONS
    || route.key === PRESCRIPTIONS
    || route.key === REFILL_REQUEST
    || route.key === WEBSITE
  ) {
    newRoutes.push(route);
  }
  return newRoutes;
}, []);

const nonVetRoutes = routes.reduce((newRoutes, route) => {
  if (route.key === MEDICATIONS
    // || route.key === TECH_BULLETINS
    || route.key === WEBSITE) {
    newRoutes.push(route);
  }
  return newRoutes;
}, []);

export default {
  [ROLE_VET]: vetRoutes,
  [ROLE_WATCHER]: watcherRoutes,
  [ROLE_PHARMACIST]: adminRoutes,
  [ROLE_NON_VETERINARIAN]: nonVetRoutes,
};
