import React, { Fragment, Component } from 'react';
import { number, arrayOf, object } from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import {
  REFILLS_STATUS_COLOR,
  REFILLS_STATUS_LIST,
  IN_PROGRESS,
} from '../../constants/onlineRefillsStatus';
import { ROLE_WATCHER } from '../../constants/roles';
import FormHeader from '../../components/FormHeader/FormHeader';
import FormCard from '../../components/Form/FormCard';
import PrescriptionsInnerTable from '../PrescriptionsInnerTable/PrescriptionsInnerTable';

const filterPhoneNumber = number => {
  if (number && number !== '') {
    // First remove all characters except for numbers
    const cleanNumber = number.replace(/[^0-9]/g, '');
    // Then piece the number back with dashes
    return `(${cleanNumber.slice(0, 3)}) ${cleanNumber.slice(3, 6)}-${cleanNumber.slice(6, 11)}`;
  }
  return '';
};

class OnlineRefillForm extends Component {
  state = {
    data: null,
  };

  static propTypes = {
    onlineRefillsStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    states: arrayOf(object).isRequired,
    refillId: number.isRequired,
  };

  async componentDidMount() {
    const { refillId, onlineRefillsStore } = this.props;
    const data = await onlineRefillsStore.fetchOnlineRefill(refillId);

    if (data.id) {
      this.setState({ data });
    }
  }

  handleSubmit = async (data) => {
    const { refillId, onlineRefillsStore } = this.props;

    const result = await onlineRefillsStore.update(refillId, data);

    if (result) {
      this.setState({ data: result });
    }
  };

  handleDownload = () => {
    const { onlineRefillsStore, refillId } = this.props;

    const url = onlineRefillsStore.download(refillId);
    window.open(url, '_blank', 'noopener');
  };

  handleStatusChange = async (newStatus) => {
    const { refillId, onlineRefillsStore } = this.props;
    const { data } = this.state;

    const { status = null } = await onlineRefillsStore.changeStatus(newStatus, refillId);

    if (status) {
      const updatedRefill = { ...data, status };

      this.setState({ data: updatedRefill });
      return true;
    }
    return false;
  };

  updateMedication = (row, value) => {
    const { data } = this.state;
    const updatedMedications = data.medications.map((medication) => {
      if (medication.prescriptionNumber === row.prescriptionNumber) {
        return { ...medication, ...value };
      }
      return medication;
    });
    data.medications = updatedMedications;
    this.setState({ data });
  };

  removeMedication = (row) => {
    const { data } = this.state;
    const updatedMedications = data.medications.filter(
      medication => medication.prescriptionNumber !== row.prescriptionNumber,
    );
    data.medications = updatedMedications;
    this.setState({ data });
  };

  renderPrescriptionsInner(medications) {
    const { authStore } = this.props;
    const { data } = this.state;
    const isWatcher = authStore.getRole === ROLE_WATCHER;
    const readOnly = data.status !== IN_PROGRESS;

    return (
      <PrescriptionsInnerTable
        prescriptions={medications}
        readOnly={isWatcher || readOnly}
        onEdit={this.updateMedication}
        onDelete={this.removeMedication}
        empty={data && data.medications && data.medications.length === 0}
      />
    );
  }

  render() {
    const { authStore, states } = this.props;
    const { data } = this.state;
    const isWatcher = authStore.getRole === ROLE_WATCHER;

    if (!data) return null;

    const readOnly = data.status !== IN_PROGRESS;
    const fieldsBlock = [
      {
        title: 'Basic Information',
        fields: [
          {
            id: 'customerId',
            name: 'customerId',
            label: 'ZooPharm Customer ID',
            type: 'text',
            validateAs: 'string',
            defaultVal: data ? data.customerId : '',
            required: true,
          },
          {
            id: 'accountHolderName',
            name: 'accountHolderName',
            label: 'Individual Account Holder Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: data ? data.accountHolderName : '',
            required: true,
          },
          {
            id: 'facility',
            name: 'facility',
            label: 'Facility or Ranch Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: data ? data.facility : '',
            required: true,
          },
          {
            id: 'veterinarianName',
            name: 'veterinarianName',
            label: 'Prescriber Name',
            type: 'text',
            validateAs: 'text',
            defaultVal: data ? data.veterinarianName : '',
            required: true,
          },
        ],
      },
      {
        title: 'Prescriptions',
        component: this.renderPrescriptionsInner(data.medications),
        fields: [],
      },
      {
        title: 'Shipping Information',
        fields: [
          {
            id: 'addressLine1',
            name: 'addressLine1',
            label: 'Address Line 1',
            type: 'text',
            validateAs: 'string',
            defaultVal: data ? data.addressLine1 : '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'addressLine2',
            name: 'addressLine2',
            label: 'Address Line 2',
            type: 'text',
            validateAs: 'string',
            defaultVal: data ? data.addressLine2 : '',
            required: false,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'city',
            name: 'city',
            label: 'City',
            type: 'text',
            validateAs: 'text',
            defaultVal: data ? data.city : '',
            required: true,
            span: { xs: 0, sm: 1, md: 1 },
          },
          {
            id: 'state',
            name: 'state',
            label: 'State',
            type: 'select',
            validateAs: 'text',
            defaultVal: data ? data.state.id : '',
            required: true,
            placeholder: 'Select one',
            span: { xs: 0, sm: 1, md: 1 },
            customProps: {
              inputProps: {
                name: 'state',
                id: 'state',
              },
              data: states,
            },
          },
          {
            id: 'zip',
            name: 'zip',
            label: 'Zip',
            type: 'text',
            validateAs: 'text',
            defaultVal: data ? data.zip : '',
            required: true,
            span: { xs: 0, sm: 1, md: 1 },
          },
          {
            id: 'phoneNumber',
            name: 'phoneNumber',
            label: 'Direct Dial Phone Number',
            type: 'text',
            validateAs: 'string',
            defaultVal: data ? filterPhoneNumber(data.phoneNumber) : '',
            required: true,
            span: { xs: 0, sm: 1, md: 1 },
          },
          {
            id: 'phoneExt',
            name: 'phoneExt',
            label: 'Ext. #',
            type: 'text',
            validateAs: 'text',
            defaultVal: data ? data.phoneExt : '',
            span: { xs: 0, sm: 1, md: 1 },
          },
          {
            id: 'email',
            name: 'email',
            label: 'Email',
            type: 'text',
            validateAs: 'text',
            defaultVal: data ? data.email : '',
            required: true,
            span: { xs: 0, sm: 1, md: 1 },
          },
        ],
      },
    ];

    return (
      <Fragment>
        <FormHeader
          statusList={REFILLS_STATUS_LIST}
          statusColors={REFILLS_STATUS_COLOR}
          status={data.status}
          onChangeStatus={this.handleStatusChange}
          print
          onPrint={this.handleDownload}
          title={data.customerName}
          date={data.created_at}
          readOnly={isWatcher}
        />
        <FormCard
          fields={fieldsBlock}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const onlineRefill = { ...values, medications: data.medications };
              this.handleSubmit(onlineRefill);
              setSubmitting(false);
            } catch (e) {
              setSubmitting(false);
            }
          }}
          readOnly={isWatcher || readOnly}
          submitLabel="Save Online Refill"
          submitColor="secondary"
          showSubmitBtn={!isWatcher && !readOnly}
          columns={4}
          spacing={24}
        />
      </Fragment>
    );
  }
}

export default inject('onlineRefillsStore', 'authStore')(observer(OnlineRefillForm));
