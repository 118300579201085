import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { Typography } from '@material-ui/core';
import Form from '../../components/Form/Form';

const styles = () => ({
  wrapper: {
    marginBottom: '30px',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
};

const fields = [
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    type: 'email',
    validateAs: 'email',
    defaultVal: '',
    required: true,
  },
];

const ForgotPasswordForm = ({ classes, authStore }) => (
  <div>
    <div className={classes.wrapper}>
      <Typography>
        Please enter your user name, and we
        {"'"}
        ll send you an email with instructions on creating a new password.
      </Typography>
    </div>
    <div className={classes.wrapper}>
      <Form
        fields={fields}
        onSubmit={async (values, { resetForm }) => {
          await authStore.forgotPassword(values.email);
          resetForm();
        }}
        submitLabel="Reset Password"
      />
    </div>
    <Typography align="center" color="textSecondary">
      <Link to="/login">Back to Login</Link>
    </Typography>
  </div>
);

ForgotPasswordForm.propTypes = propTypes;
const ForgotPasswordFormStyled = withStyles(styles, { withTheme: true })(ForgotPasswordForm);
export default inject('authStore')(observer(ForgotPasswordFormStyled));
