import { observable, action, decorate } from 'mobx';

import errorParse from '../utils/errorParse';
import storage from '../services/storage';
import config from '../config';

class RefillRequestStore {
  constructor(api, snackbarStore, loadingStore) {
    this.api = api;
    this.snackbarStore = snackbarStore;
    this.loadingStore = loadingStore;
    this.refillRequests = null;
  }

  setRefillsRequest(refillRequests) {
    this.refillRequests = refillRequests;
  }

  get getRefillsRequests() {
    return this.refillRequests;
  }

  async fetch() {
    try {
      const response = await this.api.refillRequests.get();
      this.setRefillsRequest(response);
      return response;
    } catch (e) {
      e.error = true;
      return e;
    }
  }

  async fetchRefillRequest(id) {
    this.loadingStore.setReady(false);

    try {
      const response = await this.api.refillRequests.get({ id });
      this.loadingStore.setReady(true);
      return response;
    } catch (e) {
      this.snackbarStore.setError({
        title: e.response.status === 404 ? 'Refill Request not found' : 'Sorry, an error occurred.',
      });
      this.loadingStore.setReady(true);
      e.error = true;
      return e;
    }
  }

  async create(payload) {
    this.loadingStore.setReady(false);

    try {
      await this.api.refillRequests.create(payload);

      this.snackbarStore.setSuccess({
        title: 'Refill Request has been successfully created.',
      });
      this.loadingStore.setReady(true);

      return true;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred. ${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return false;
    }
  }

  async update(id, payload) {
    this.loadingStore.setReady(false);

    try {
      const result = await this.api.refillRequests.update(id, payload);

      this.snackbarStore.setSuccess({
        title: 'Online Refill has been successfully updated.',
      });
      this.loadingStore.setReady(true);

      return result;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred.${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return false;
    }
  }

  async changeStatus(newStatus, id) {
    this.loadingStore.setReady(false);

    try {
      const data = await this.api.refillRequests.patch(id, { status: newStatus });

      this.snackbarStore.setSuccess({
        title: 'Refill Request has been successfully updated status.',
      });
      this.loadingStore.setReady(true);

      return data;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred.${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return e;
    }
  }

  download(id) {
    this.loadingStore.setReady(false);

    const token = storage.getItem('token');
    this.loadingStore.setReady(true);
    return `${config.api.baseURL}/refillrequests/${id}/pdf/?token=${token}/`;
  }
}

decorate(RefillRequestStore, {
  refillRequests: observable,
  setRefillsRequest: action,
});

export default RefillRequestStore;
