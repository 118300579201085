import React, { Fragment } from 'react';
import {
  bool, func, string, node, arrayOf, object,
} from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  withMobileDialog,
  IconButton,
} from '@material-ui/core';

import Form from '../Form/Form';

class FormDialogButton extends React.Component {
  static propTypes = {
    fullScreen: bool.isRequired,
    onConfirm: func.isRequired,
    title: string.isRequired,
    cancelText: string,
    confirmText: string,
    icon: node.isRequired,
    fields: arrayOf(object).isRequired,
  };

  static defaultProps = {
    cancelText: 'Cancel',
    confirmText: 'Submit',
  };

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirm = (value) => {
    const { onConfirm } = this.props;
    onConfirm(value);
    this.handleClose();
  };

  render() {
    const {
      fullScreen, title, cancelText, confirmText, icon, fields,
    } = this.props;
    const { open } = this.state;
    return (
      <Fragment>
        <IconButton aria-label="open" color="secondary" onClick={this.handleOpen}>
          {icon}
        </IconButton>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent style={{ minWidth: '400px' }}>
            <Form
              fields={fields}
              onSubmit={value => this.handleConfirm(value)}
              submitLabel={confirmText}
            />
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={this.handleClose}
                color="inherit"
                autoFocus
                style={{ width: '100%' }}
              >
                {cancelText}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default withMobileDialog()(FormDialogButton);
