export const COMPANION_ANIMALS = 1;
export const LAB_ANIMALS = 2;
export const WILDLIFE = 3;
export const ZOO_ANIMALS = 4;

export const CATEGORIES_LABEL = {
  [COMPANION_ANIMALS]: 'Companion Animals',
  [LAB_ANIMALS]: 'Lab Animals',
  [WILDLIFE]: 'Wildlife',
  [ZOO_ANIMALS]: 'Zoo Animals',
};

export const CATEGORIES_LIST = Object.keys(CATEGORIES_LABEL).map(value => ({
  label: CATEGORIES_LABEL[value],
  value,
}));
