import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';

import {
  TablePagination, TableRow, IconButton, Hidden,
} from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import { ROLE_WATCHER } from '../../constants/roles';
import {
  NEW,
  REFILLS_STATUS_LIST,
  REFILLS_STATUS_COLOR,
  REFILLS_STATUS_LABEL,
} from '../../constants/onlineRefillsStatus';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import StatusIcon from '../../components/StatusIcon/StatusIcon';

import removeEmptyKeys from '../../utils/removeEmptyKeys';

class OnlineRefillsTable extends Component {
  static propTypes = {
    onlineRefillsStore: mobxPropTypes.observableObject.isRequired,
    loadingStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    paginate: PropTypes.bool,
    showEmptyRows: PropTypes.bool,
    defaultLimit: PropTypes.number,
  };

  static defaultProps = {
    paginate: false,
    showEmptyRows: false,
    defaultLimit: 10,
  };

  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;

    this.state = {
      data: [],
      _limit: defaultLimit,
      filters: {},
      total: 0,
      page: 0,
    };
  }

  componentWillMount() {
    this.fetchData({ status: NEW });
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'Date', hidden: { smDown: true } },
      { id: 2, label: 'Client' },
      { id: 3, label: 'Prescriber', hidden: { smDown: true } },
      { id: 4, label: 'Status', hidden: { xsDown: true } },
      { id: 5, label: '' },
    ];
  }

  fetchData = async (searchFilters = null, reset = false) => {
    const { onlineRefillsStore, loadingStore } = this.props;
    loadingStore.setReady(false);

    const { _limit, page, filters } = this.state;
    const newFilters = removeEmptyKeys(searchFilters || filters);
    // Dirty trick to add time
    if (newFilters.created_at_gte && newFilters.dateTo_lte) {
      newFilters.created_at_gte = moment(newFilters.created_at_gte)
        .startOf('day')
        .format();
      newFilters.dateTo_lte = moment(newFilters.dateTo_lte)
        .endOf('day')
        .format();
    }
    const offset = _limit * page;
    const params = Object.assign(
      { _limit, _start: offset, _sort: 'id:DESC' },
      reset ? {} : newFilters,
    );

    try {
      const data = await onlineRefillsStore.api.onlineRefills.get({ id: null, params });
      this.setState({
        data: data.results || [],
        filters: reset ? {} : newFilters,
        total: data.totalCount || 0,
      });
    } catch (error) {
      this.setState({
        data: [],
        filters: newFilters,
      });
    }

    loadingStore.setReady(true);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.fetchData());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ _limit: event.target.value }, () => this.fetchData());
  };

  handleEditClick = (row) => {
    const { history, match } = this.props;
    history.push(`${match.url}/edit/${row.id}`);
  };

  renderRows = () => {
    const { data } = this.state;
    const { authStore } = this.props;
    const isWatcher = authStore.getRole === ROLE_WATCHER;
    return data.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <Hidden smDown>
          <TableCell component="th" scope="row" padding="default">
            {moment(row.created_at).format('MMM Do, YY')}
          </TableCell>
        </Hidden>
        <TableCell>{row.customerName}</TableCell>
        <Hidden smDown>
          <TableCell>{row.veterinarianName}</TableCell>
        </Hidden>
        <Hidden xsDown>
          <TableCell>
            {row.status ? (
              <React.Fragment>
                <StatusIcon color={REFILLS_STATUS_COLOR[row.status]} spacing="right" />
                {REFILLS_STATUS_LABEL[row.status]}
              </React.Fragment>
            ) : (
              '-'
            )}
          </TableCell>
        </Hidden>
        <TableCell padding="none" isActions>
          <IconButton
            aria-label={isWatcher ? 'View' : 'Edit'}
            color="secondary"
            onClick={() => this.handleEditClick(row)}
          >
            {isWatcher ? <ViewIcon /> : <EditIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  renderPagination = () => {
    const { total, _limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={_limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  };

  renderSearch = () => {
    const defaultFields = [
      {
        id: 'customerName',
        name: 'customerName',
        label: 'Client',
        type: 'text',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
      },
      {
        id: 'veterinarianName',
        name: 'veterinarianName',
        label: 'Prescriber',
        type: 'text',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
      },
      {
        id: 'created_at_gte',
        name: 'created_at_gte',
        label: 'Date From',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        id: 'dateTo_lte',
        name: 'dateTo_lte',
        label: 'Date To',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        id: 'status',
        name: 'status',
        label: 'Status',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'text',
        defaultVal: NEW,
        required: false,
        customProps: {
          inputProps: {
            name: 'status',
            id: 'status',
          },
          data: REFILLS_STATUS_LIST,
        },
      },
    ];

    return (
      <SearchForm
        onSubmit={this.fetchData}
        onReset={() => this.fetchData({ status: NEW }, true)}
        fields={defaultFields}
      />
    );
  };

  render() {
    const { data, _limit, page } = this.state;
    const { paginate, showEmptyRows } = this.props;

    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = _limit - Math.min(_limit, data.length - page * _limit);
    }

    const props = {
      header: OnlineRefillsTable.getTableHeader(),
      renderRows: this.renderRows,
      emptyRows,
      paginate,
      renderPagination: this.renderPagination,
      empty: data.length === 0,
    };

    return (
      <React.Fragment>
        {this.renderSearch()}
        <DataTable {...props} />
      </React.Fragment>
    );
  }
}

export default inject('onlineRefillsStore', 'authStore', 'loadingStore')(
  observer(OnlineRefillsTable),
);
