import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

const propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]),
  authStore: mobxPropTypes.observableObject.isRequired,
  roles: PropTypes.arrayOf(PropTypes.number),
};

const defaultProps = {
  location: null,
  roles: [],
};

const PrivateRoute = ({
  component: Component, authStore, roles, ...rest
}) => {
  const { isLoggedIn, user } = authStore;

  if (isLoggedIn && roles.length > 0 && roles.indexOf(user.role.id) === -1) {
    return <Redirect to="/profile" />;
  }

  return (
    <Route
      {...rest}
      render={props => (isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/portal-login', state: { from: props.location } }} />
      ))
      }
    />
  );
};

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;
export default withRouter(inject('authStore')(observer(PrivateRoute)));
