import React, { Fragment, Component } from 'react';
import { ErrorMessage } from 'formik';
import { bool, func, shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, Hidden, Grid } from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons/';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import Autocomplete from '../../components/Autocomplete/Autocomplete';
import ConfirmDialogButton from '../../components/ConfirmDialogButton/ConfirmDialogButton';
import FormDialogButton from '../../components/FormDialogButton/FormDialogButton';

import { COMPLETED } from '../../constants/prescriptions';

const styles = () => ({
  wrapper: {
    '&:first-child': {
      width: '100%',
    },
  },
  error: {
    color: '#f44336',
    fontSize: '0.75rem',
    textAlign: 'left',
  },
});

class PrescriptionsTable extends Component {
  static propTypes = {
    prescriptionStore: mobxPropTypes.observableObject.isRequired,
    classes: shape({}).isRequired,
    arrayHelpers: shape({}).isRequired,
    setFieldValue: func.isRequired,
    values: shape({}).isRequired,
    readOnly: bool,
    showAutocomplete: bool,
    minOne: bool,
  };

  static defaultProps = {
    readOnly: false,
    showAutocomplete: true,
    minOne: false,
  };

  state = {
    clearPrescription: false,
  };

  static getTableHeader() {
    return [
      { id: 1, label: 'Prescription ID' },
      { id: 2, label: 'Medication' },
      { id: 3, label: 'Animal', hidden: { xsDown: true } },
      { id: 4, label: 'Quantity', hidden: { smDown: true } },
      { id: 5, label: 'Comments', hidden: { smDown: true } },
      { id: 6, label: '' },
    ];
  }

  renderRows = () => {
    const {
      values, readOnly, arrayHelpers, setFieldValue, minOne,
    } = this.props;
    const { prescriptions } = values;
    const enableDelete = minOne ? prescriptions.length > 1 : true;

    return prescriptions.map((row, key) => (
      <TableRow hover tabIndex={-1} key={key.toString()}>
        <TableCell component="th" scope="row" padding="default">
          {`# ${row.prescriptionId}`}
        </TableCell>
        <TableCell>
          <strong>{row.medication}</strong>
        </TableCell>
        <Hidden xsDown>
          <TableCell>{row.animal}</TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell>{row.quantity}</TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell>{row.message}</TableCell>
        </Hidden>
        <TableCell padding="none" isActions>
          {!readOnly && (
            <Fragment>
              <FormDialogButton
                title={`Edit Prescription # ${row.prescription}`}
                icon={<EditIcon />}
                fields={[
                  {
                    id: 'quantity',
                    name: 'quantity',
                    label: 'Quantity',
                    type: 'number',
                    validateAs: 'number',
                    defaultVal: row ? row.quantity : '',
                    required: true,
                  },
                  {
                    id: 'message',
                    name: 'message',
                    label: 'Comments',
                    type: 'text',
                    validateAs: 'string',
                    defaultVal: row ? row.message : '',
                    required: true,
                  },
                ]}
                onConfirm={async (fields) => {
                  setFieldValue(`prescriptions[${key}].quantity`, fields.quantity);
                  setFieldValue(`prescriptions[${key}].message`, fields.message);
                }}
              />
              {enableDelete && (
                <ConfirmDialogButton
                  onConfirm={() => arrayHelpers.remove(key)}
                  title={`Delete Prescription # ${row.prescriptionId}`}
                  body={`Are you sure you want to delete the prescription # ${
                    row.prescriptionId
                  } ?`}
                  confirmText="Yes, Delete"
                  icon={<DeleteIcon color="error" />}
                />
              )}
            </Fragment>
          )}
        </TableCell>
      </TableRow>
    ));
  };

  handlePrescriptionsAutoComplete = (input) => {
    const {
      prescriptionStore,
      values: { customer },
    } = this.props;

    if (!input || !customer) return [];

    return prescriptionStore.fetchPrescriptions(input, { status: COMPLETED, customer });
  };

  handleOnChange = async (val) => {
    const {
      arrayHelpers,
      prescriptionStore,
      values: { prescriptions },
    } = this.props;
    let exist = false;

    prescriptions.forEach((el, key) => {
      if (el.prescription === val) {
        exist = true;
        arrayHelpers.remove(key);
        arrayHelpers.insert(key, {
          ...el,
          quantity: el.quantity + 1,
        });
      }
    });

    if (!exist) {
      const prescription = await prescriptionStore.fetchPrescription(val);

      if (prescription.id) {
        arrayHelpers.push({
          prescription: val,
          prescriptionId: prescription.prescriptionId,
          medication: prescription.medication.name,
          animal: prescription.animalspecie,
          quantity: 1,
          message: '-',
        });
      }
    }
    this.setState({ clearPrescription: true });
  };

  render() {
    const {
      classes, values, readOnly, showAutocomplete,
    } = this.props;
    const { clearPrescription } = this.state;
    const { prescriptions } = values;

    return (
      <Fragment>
        {!readOnly && showAutocomplete && (
          <Grid item container xs={12} sm={6} md={6}>
            <div className={classes.wrapper}>
              <Autocomplete
                placeholder="Prescription ID"
                onChange={this.handleOnChange}
                loadOptions={this.handlePrescriptionsAutoComplete}
                handleClear={clearObj => this.setState(clearObj)}
                clearPrescription={clearPrescription}
              />
            </div>
          </Grid>
        )}
        {prescriptions.length !== 0 && (
          <DataTable
            header={PrescriptionsTable.getTableHeader()}
            renderRows={this.renderRows}
            emptyRows={0}
            paginate={false}
            card={false}
          />
        )}
        <ErrorMessage name="prescriptions" className={classes.error} component="p" />
      </Fragment>
    );
  }
}
const PrescriptionsTableStyled = withStyles(styles, { withTheme: false })(PrescriptionsTable);
export default inject('prescriptionStore')(observer(PrescriptionsTableStyled));
