import { observable, action, decorate } from 'mobx';

import errorParse from '../utils/errorParse';
import storage from '../services/storage';
import config from '../config';

class PrescriptionStore {
  constructor(api, snackbarStore, loadingStore) {
    this.api = api;
    this.snackbarStore = snackbarStore;
    this.loadingStore = loadingStore;
    this.prescriptions = null;
  }

  setPrescriptions(prescriptions) {
    this.users = prescriptions;
  }

  get getPrescriptions() {
    return this.prescriptions;
  }

  async fetch() {
    try {
      const response = await this.api.prescriptions.get();
      if (response) {
        this.setPrescriptions(response);
        return response;
      }
      return Promise.reject(response);
    } catch (e) {
      e.error = true;
      return e;
    }
  }

  async fetchPrescription(id) {
    this.loadingStore.setReady(false);

    try {
      const response = await this.api.prescriptions.get({ id });
      this.loadingStore.setReady(true);
      return response;
    } catch (e) {
      this.snackbarStore.setError({
        title: e.response.status === 404 ? 'Prescription not found' : 'Sorry, an error occurred.',
      });
      this.loadingStore.setReady(true);
      e.error = true;
      return e;
    }
  }

  async fetchPrescriptions(name, extraParams = {}) {
    let results = [];
    const params = {
      prescriptionId_contains: name,
      ...extraParams,
    };

    const response = await this.api.prescriptions.get({ id: null, params });

    if (response && response.results) {
      results = response.results.map(prescription => ({
        value: prescription.id,
        label: `#${prescription.prescriptionId} - ${prescription.medication.name} ${
          prescription.medication.presentation
        }`,
      }));
    }

    return results;
  }

  async create(payload) {
    this.loadingStore.setReady(false);

    try {
      await this.api.prescriptions.create(payload);

      this.snackbarStore.setSuccess({
        title: 'Prescription has been successfully created.',
      });
      this.loadingStore.setReady(true);

      return true;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred. ${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return false;
    }
  }

  async update(id, payload) {
    this.loadingStore.setReady(false);

    try {
      const result = await this.api.prescriptions.update(id, payload);

      this.snackbarStore.setSuccess({
        title: 'Prescription has been successfully updated.',
      });
      this.loadingStore.setReady(true);

      return result;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred.${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return false;
    }
  }

  async changeStatus(newStatus, id) {
    this.loadingStore.setReady(false);

    try {
      const data = await this.api.prescriptions.patch(id, { status: newStatus });

      this.snackbarStore.setSuccess({
        title: 'Prescription has been successfully updated status.',
      });
      this.loadingStore.setReady(true);

      return data;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred.${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return e;
    }
  }

  download(id) {
    this.loadingStore.setReady(false);

    const token = storage.getItem('token');
    this.loadingStore.setReady(true);
    return `${config.api.baseURL}/prescriptions/${id}/pdf/?token=${token}/`;
  }
}

decorate(PrescriptionStore, {
  prescriptions: observable,
  setPrescriptions: action,
});

export default PrescriptionStore;
