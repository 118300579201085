import React, { Component, Fragment } from 'react';
import { number, arrayOf, object } from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import Form from '../../components/Form/FormCard';
import FormHeader from '../../components/FormHeader/FormHeader';

import {
  IN_PROGRESS,
  PRESCRIPTIONS_STATUS_COLOR,
  PRESCRIPTIONS_STATUS_LIST,
} from '../../constants/prescriptions';
import { ROLE_PHARMACIST, ROLE_WATCHER } from '../../constants/roles';

class PrescriptionVetForm extends Component {
  static propTypes = {
    prescriptionStore: mobxPropTypes.observableObject.isRequired,
    medicationStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    states: arrayOf(object).isRequired,
    prescriptionId: number,
  };

  static defaultProps = {
    prescriptionId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      showFields: false,
      prescription: null,
      clearMedication: false,
    };
  }

  async componentWillMount() {
    const { prescriptionId } = this.props;

    if (prescriptionId) this.fetchPrescription(prescriptionId);
  }

  fetchPrescription = async (prescriptionId) => {
    const { prescriptionStore } = this.props;
    const prescription = await prescriptionStore.fetchPrescription(prescriptionId);

    if (prescription.id) {
      this.setState({ prescription, showFields: true });
    }
  };

  formatPayload = (data) => {
    const payload = new FormData();
    const { prescriptionFile, customer, ...fields } = data;

    Object.keys(fields).forEach((key) => {
      payload.append(key, fields[key]);
    });
    payload.append('prescriptionFile', prescriptionFile);

    return payload;
  };

  handleClear = (clearObj) => {
    this.setState(clearObj);
  };

  handleMedicationsAutoComplete = (input) => {
    if (!input) return [];

    const { medicationStore } = this.props;

    return medicationStore.fetchMedications(input);
  };

  handleUpdate = async (id, values) => {
    const { prescriptionStore } = this.props;
    const result = await prescriptionStore.update(id, values);

    if (result) {
      this.setState({ prescription: result });
    }
  };

  handleUpdateStatus = async (newStatus) => {
    const { prescription } = this.state;
    const { prescriptionStore, prescriptionId } = this.props;
    const { status = null } = await prescriptionStore.changeStatus(newStatus, prescriptionId);

    if (status) {
      const updatedPrescription = { ...prescription, status };
      this.setState({ prescription: updatedPrescription });
      return true;
    }
    return false;
  };

  handleDownload = () => {
    const { prescriptionStore, prescriptionId } = this.props;

    const url = prescriptionStore.download(prescriptionId);
    window.open(url, '_blank', 'noopener');
  };

  render() {
    const {
      states,
      prescriptionId,
      authStore: { getUser: prescribing, getRole: userRole },
    } = this.props;
    const { prescription, showFields, clearMedication } = this.state;

    if (prescriptionId && !prescription) return null;

    const isWatcher = userRole === ROLE_WATCHER;
    const isPharmacists = userRole === ROLE_PHARMACIST;
    const readOnly = isWatcher || (isPharmacists && prescription.status !== IN_PROGRESS);
    const fieldsBlock = [
      {
        title: 'Client Information',
        fields: [
          {
            id: 'customer',
            name: 'customer',
            label: 'Client Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription
              ? `${prescription.customer.firstName} ${prescription.customer.lastName}`
              : '',
            required: true,
            disabled: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'customerEmail',
            name: 'customerEmail',
            label: 'Email',
            type: 'email',
            validateAs: 'email',
            defaultVal: prescription ? prescription.customer.email : '',
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerPhoneNumber',
            name: 'customerPhoneNumber',
            label: 'Phone Number',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription ? prescription.customer.phoneNumber : '',
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerStreet',
            name: 'customerStreet',
            label: 'Street',
            type: 'text',
            validateAs: 'text',
            defaultVal: prescription ? prescription.customer.street : '',
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerCity',
            name: 'customerCity',
            label: 'City',
            type: 'text',
            validateAs: 'text',
            defaultVal: prescription ? prescription.customer.city : '',
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerState',
            name: 'customerState',
            label: 'State',
            type: 'select',
            validateAs: 'text',
            defaultVal: prescription ? prescription.customer.state.id : '',
            required: false,
            disabled: true,
            show: showFields,
            placeholder: 'Select one',
            customProps: {
              inputProps: {
                name: 'customerState',
                id: 'customerState',
              },
              data: states,
            },
          },
          {
            id: 'customerZip',
            name: 'customerZip',
            label: 'Zip code',
            type: 'number',
            validateAs: 'text',
            defaultVal: prescription ? prescription.customer.zip : '',
            required: false,
            disabled: true,
            show: showFields,
          },
        ],
      },
      {
        title: 'Animal',
        show: showFields,
        fields: [
          {
            id: 'animalspecie',
            name: 'animalspecie',
            label: 'Animal / Species',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription ? prescription.animalspecie : '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
        ],
      },
      {
        title: 'Shipping Information',
        show: showFields,
        fields: [
          {
            id: 'shippingName',
            name: 'shippingName',
            label: 'Complete Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription ? prescription.shippingName : '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'shippingEmail',
            name: 'shippingEmail',
            label: 'Email',
            type: 'email',
            validateAs: 'email',
            defaultVal: prescription ? prescription.shippingEmail : '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'shippingStreet',
            name: 'shippingStreet',
            label: 'Street',
            type: 'text',
            validateAs: 'text',
            defaultVal: prescription ? prescription.shippingStreet : '',
            required: true,
          },
          {
            id: 'shippingCity',
            name: 'shippingCity',
            label: 'City',
            type: 'text',
            validateAs: 'text',
            defaultVal: prescription ? prescription.shippingCity : '',
            required: true,
          },
          {
            id: 'shippingState',
            name: 'shippingState',
            label: 'State',
            type: 'select',
            validateAs: 'text',
            defaultVal: prescription ? prescription.shippingState.id : '',
            required: true,
            placeholder: 'Select one',
            customProps: {
              inputProps: {
                name: 'shippingState',
                id: 'shippingState',
              },
              data: states,
            },
          },
          {
            id: 'shippingZip',
            name: 'shippingZip',
            label: 'Zip code',
            type: 'number',
            validateAs: 'number',
            defaultVal: prescription ? prescription.shippingZip : '',
            required: true,
          },
        ],
      },
      {
        title: 'Medication Information',
        show: showFields,
        fields: [
          {
            id: 'medicationOfficeUse',
            name: 'medicationOfficeUse',
            label: 'Office Use',
            type: 'checkbox',
            validateAs: 'mixed',
            defaultVal: prescription ? prescription.medicationOfficeUse : '',
            span: { xs: 0, sm: 9, md: 9 },
          },
          {
            id: 'medication',
            name: 'medication',
            label: 'Medication',
            type: 'autocomplete',
            validateAs: 'text',
            defaultVal: prescription ? prescription.medication.id : '',
            required: true,
            customProps: {
              defaultInputValue: prescription
                ? `${prescription.medication.name} ${prescription.medication.presentation}`
                : '',
              defaultOptions: [],
              loadOptions: this.handleMedicationsAutoComplete,
              handleClear: this.handleClear,
              clearMedication,
            },
          },
          {
            id: 'medicationQuantity',
            name: 'medicationQuantity',
            label: 'Quantity',
            type: 'number',
            validateAs: 'number',
            defaultVal: prescription ? prescription.medicationQuantity : '',
            required: true,
          },
          {
            id: 'medicationRefills',
            name: 'medicationRefills',
            label: 'Refill',
            type: 'number',
            validateAs: 'number',
            defaultVal: prescription ? prescription.medicationRefills : '',
            required: true,
          },
          {
            id: 'medicationNumAnimals',
            name: 'medicationNumAnimals',
            label: '# of Animals',
            type: 'number',
            validateAs: 'number',
            defaultVal: prescription ? prescription.medicationNumAnimals : '',
            required: true,
          },
          {
            id: 'medicationDirectionsUse',
            name: 'medicationDirectionsUse',
            label: 'Directions of Use',
            type: 'text',
            validateAs: 'text',
            defaultVal: prescription ? prescription.medicationDirectionsUse : '',
            required: true,
            span: { xs: 0, sm: 9, md: 9 },
            customProps: {
              InputLabelProps: {
                shrink: true,
              },
            },
          },
        ],
      },
      {
        title: 'Scanned Paper-Based Prescription',
        show: showFields,
        fields: [
          {
            id: 'prescriptionId',
            name: 'prescriptionId',
            label: 'ZooPharm Prescription ID',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription ? prescription.prescriptionId || '' : '',
            required: true,
            show: !readOnly,
          },
          {
            id: 'prescriptionFile',
            name: 'prescriptionFile',
            label: 'Scaned Prescription',
            type: 'upload',
            validateAs: 'string',
            defaultVal: '',
            required: !prescription,
            customProps: {
              model: 'prescriptions',
              defaultFile: prescription ? prescription.prescriptionFile : null,
              accept: 'application/pdf, .jpg, .jpeg, .png',
            },
          },
        ],
      },
      {
        title: 'Prescribing by',
        show: showFields,
        fields: [
          {
            id: 'veterinaryName',
            name: 'veterinaryName',
            label: 'Veterinary Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription
              ? `${prescription.user.firstName} ${prescription.user.lastName}`
              : `${prescribing.firstName} ${prescribing.lastName}`,
            disabled: true,
          },
          {
            id: 'veterinaryAddress',
            name: 'veterinaryAddress',
            label: 'Veterinary Address',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription
              ? prescription.user.vetprofile.clinicStreet
              : prescribing.vetprofile.clinicStreet,
            disabled: true,
          },
          {
            id: 'veterinaryPhone',
            name: 'veterinaryPhone',
            label: 'Phone Number',
            type: 'text',
            validateAs: 'string',
            defaultVal: prescription
              ? prescription.user.phoneNumber || ''
              : prescribing.phoneNumber || '',
            disabled: true,
          },
          {
            id: 'veterinaryDeaNumber',
            name: 'veterinaryDeaNumber',
            label: 'DEA License Number',
            type: 'text',
            validateAs: 'text',
            defaultVal: prescription
              ? prescription.user.vetprofile.deaNumber || ''
              : prescribing.vetprofile.deaNumber || '',
            disabled: true,
          },
        ],
      },
    ];

    return (
      <Fragment>
        <FormHeader
          print={!!prescription.prescriptionId}
          readOnly={isWatcher}
          onPrint={this.handleDownload}
          onChangeStatus={this.handleUpdateStatus}
          status={prescription.status}
          date={prescription.created_at}
          statusList={PRESCRIPTIONS_STATUS_LIST}
          statusColors={PRESCRIPTIONS_STATUS_COLOR}
          title={`ZooPharm Prescription Number ${prescription.prescriptionId || '-'}`}
        />
        <Form
          fields={fieldsBlock}
          onSubmit={async (values, { setSubmitting }) => {
            const payload = this.formatPayload(values);

            try {
              await this.handleUpdate(prescription.id, payload);
              setSubmitting(false);
            } catch (e) {
              setSubmitting(false);
            }
          }}
          readOnly={readOnly}
          termsOfServices={{
            required: true,
            type: 'checkbox',
            show: showFields,
            id: 'termsOfServices',
            validateAs: 'onlyTrue',
            name: 'termsOfServices',
            defaultVal: !!prescription,
            label: 'I hereby confirmed that the information uploaded is correct',
          }}
          submitLabel="Save Prescription"
          submitColor="secondary"
          showSubmitBtn={showFields}
          columns={4}
          spacing={24}
        />
      </Fragment>
    );
  }
}

export default inject('prescriptionStore', 'authStore', 'medicationStore')(
  observer(PrescriptionVetForm),
);
