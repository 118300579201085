import React, { Component } from 'react';
import { shape, number, oneOf } from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import Form from '../../components/Form/FormCard';

import { STATUS_LIST } from '../../constants/customers';

class CustomerForm extends Component {
  static propTypes = {
    customerStore: mobxPropTypes.observableObject.isRequired,
    stateStore: mobxPropTypes.observableObject.isRequired,
    history: shape({}).isRequired,
    customerId: number,
    referrer: oneOf(['prescription']),
    referrerId: number,
  };

  static defaultProps = {
    customerId: null,
    referrer: null,
    referrerId: null,
  };

  state = {
    customer: null,
  };

  async componentWillMount() {
    const { customerId } = this.props;

    if (customerId) {
      const { customerStore } = this.props;
      const customer = await customerStore.fetchCustomer(customerId);

      if (customer.id) {
        this.setState({ customer });
      }
    }
  }

  async handleCreate(values) {
    const {
      customerStore, history, referrer, referrerId,
    } = this.props;
    const customer = await customerStore.create(values);

    if (customer) {
      let path = '/clients';

      if (customer.status && referrer === 'prescription') {
        if (referrerId) {
          path = `/prescriptions/edit/${referrerId}/client/${customer.id}`;
        } else {
          path = `/prescriptions/add/client/${customer.id}`;
        }
      }

      history.push(path);
    }
  }

  async handleUpdate(id, values) {
    const { customerStore } = this.props;
    const result = await customerStore.update(id, values);

    if (result) {
      this.setState({ customer: result });
    }
  }

  render() {
    const { customerId, stateStore } = this.props;
    const { customer } = this.state;

    const states = stateStore.states
      ? stateStore.states.reduce(
        (acc, state) => acc.concat({
          label: state.name,
          value: state.id,
        }),
        [],
      )
      : [];

    if (customerId && !customer) return null;

    const fieldsBlock = [
      {
        title: 'Client Information',
        fields: [
          {
            id: 'firstName',
            name: 'firstName',
            label: 'First Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: (customer && customer.firstName) || '',
            required: true,
          },
          {
            id: 'lastName',
            name: 'lastName',
            label: 'Last Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: (customer && customer.lastName) || '',
            required: true,
          },
          {
            id: 'facility',
            name: 'facility',
            label: 'Facility or Ranch name',
            type: 'text',
            validateAs: 'string',
            defaultVal: (customer && customer.facility) || '',
            required: false,
          },
          {
            id: 'email',
            name: 'email',
            label: 'Email',
            type: 'email',
            validateAs: 'email',
            defaultVal: (customer && customer.email) || '',
            required: true,
          },
          {
            id: 'phoneNumber',
            name: 'phoneNumber',
            label: 'Phone Number',
            type: 'text',
            validateAs: 'string',
            defaultVal: (customer && customer.phoneNumber) || '',
          },
          {
            id: 'status',
            name: 'status',
            label: 'Status',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: customer && `${customer.status}` ? `${customer.status}` : '',
            required: true,
            customProps: {
              inputProps: {
                name: 'status',
                id: 'status',
              },
              data: STATUS_LIST,
            },
          },
        ],
      },
      {
        title: 'Shipping Information',
        fields: [
          {
            id: 'street',
            name: 'street',
            label: 'Street',
            type: 'text',
            validateAs: 'string',
            defaultVal: (customer && customer.street) || '',
            required: true,
          },
          {
            id: 'city',
            name: 'city',
            label: 'City',
            type: 'text',
            validateAs: 'string',
            defaultVal: (customer && customer.city) || '',
            required: true,
          },
          {
            id: 'state',
            name: 'state',
            label: 'State',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'mixed',
            defaultVal: (customer && customer.state.id) || '',
            required: true,
            customProps: {
              inputProps: {
                name: 'state',
                id: 'state',
              },
              data: states,
            },
          },
          {
            id: 'zip',
            name: 'zip',
            label: 'Zip code',
            type: 'number',
            validateAs: 'number',
            required: true,
            defaultVal: (customer && customer.zip) || '',
          },
        ],
      },
    ];

    return (
      <Form
        fields={fieldsBlock}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (customer) {
              await this.handleUpdate(customer.id, values);
            } else {
              await this.handleCreate(values);
            }
            setSubmitting(false);
          } catch (e) {
            setSubmitting(false);
          }
        }}
        submitColor="secondary"
        submitLabel="Save Client"
        columns={4}
        spacing={24}
      />
    );
  }
}

export default inject('customerStore', 'stateStore')(observer(CustomerForm));
