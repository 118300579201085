import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CustomersTable from '../../containers/CustomersTable/CustomersTable';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const CustomersListPage = ({ history, classes }) => (
  <div className={classes.list}>
    <CustomersTable history={history} paginate search />
  </div>
);

CustomersListPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(CustomersListPage);
