import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import DeaSeals from '../../assets/images/dea-seals.png';
import MedicationsTable from '../../containers/MedicationsTable/MedicationsTable';


const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
  img: {
    paddingTop: 20,
  },
  box: {
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing.unit,
    padding: '10px 15px',
    background: '#0E343B',
    color: '#fff',
    lineHeight: '1.5',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const MedicationsListPage = ({ classes, ...props }) => (
  <div className={classes.list}>
    <MedicationsTable {...props} paginate />
    <img src={DeaSeals} alt="dea-seals" className={classes.img} />
    <div className={classes.box}>
      <p>
        If you have any questions regarding ZooPharm formulations, please
        call ZooPharm Customer Service at (866) 823-9314
      </p>
    </div>
  </div>
);

MedicationsListPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(MedicationsListPage);
