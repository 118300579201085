import React from 'react';
import PropTypes from 'prop-types';
import Form from '../Form/Form';
import ResponsiveExpansionPanel from '../ResponsiveExpansionPanel/ResponsiveExpansionPanel';

const propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.number,
  buttonsWidth: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  showDownload: PropTypes.bool,
  onDownload: PropTypes.func,
  downloadEnabled: PropTypes.bool,
  noButtonMargin: PropTypes.bool,
};

const defaultProps = {
  onReset: () => {},
  noButtonMargin: false,
  buttonsWidth: {},
  columns: 4,
  showDownload: false,
  onDownload: null,
  downloadEnabled: false,
};

const SearchForm = ({
  fields, columns, buttonsWidth, onSubmit, onReset, showDownload, onDownload, downloadEnabled,
}) => (
  <ResponsiveExpansionPanel title="Search By" collapsedText="Show Filters">
    <Form
      fields={fields}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
        }
      }}
      onReset={async (values, { setSubmitting, resetForm }) => {
        const resetValues = {};
        Object.keys(values).map((prop) => {
          resetValues[prop] = '';
          return resetValues;
        });
        resetForm(resetValues);
        try {
          await onReset();
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
        }
      }}
      submitLabel="Search"
      reset
      noButtonMargin
      columns={columns}
      buttonsWidth={buttonsWidth}
      spacing={24}
      showDownload={showDownload}
      onDownload={values => onDownload(values)}
      downloadEnabled={downloadEnabled}
    />
  </ResponsiveExpansionPanel>
);

SearchForm.propTypes = propTypes;
SearchForm.defaultProps = defaultProps;

export default SearchForm;
