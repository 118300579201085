import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import UsersList from './Index';
import UserEditPage from './Edit';
import UserAddPage from './Add';
import { ROLE_PHARMACIST, ROLE_WATCHER } from '../../constants/roles';

const propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const UsersPage = ({ match }) => (
  <Switch>
    <PrivateRoute
      path={match.url}
      exact
      component={UsersList}
      roles={[ROLE_PHARMACIST, ROLE_WATCHER]}
    />
    <PrivateRoute path={`${match.url}/add`} component={UserAddPage} roles={[ROLE_PHARMACIST]} />
    <PrivateRoute
      path={`${match.url}/edit/:userId`}
      component={UserEditPage}
      roles={[ROLE_PHARMACIST, ROLE_WATCHER]}
    />
    <Redirect to="/users" />
  </Switch>
);

UsersPage.propTypes = propTypes;
export default UsersPage;
