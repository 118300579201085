import React, { Fragment } from 'react';
import { number } from 'prop-types';

import VetForm from './VetForm';
import PharmaForm from './PharmaForm';

import { ROLE_VET } from '../../constants/roles';

const propTypes = {
  role: number.isRequired,
};

const PrescriptionForm = ({ role, ...props }) => (
  <Fragment>
    {role === ROLE_VET ? <VetForm {...props} /> : <PharmaForm {...props} />}
  </Fragment>
);

PrescriptionForm.propTypes = propTypes;
export default PrescriptionForm;
