import config from '../config';

export const USERS = 'users';
export const USERS_ADD = 'users_add';
export const USERS_EDIT = 'users_edit';
export const CUSTOMERS = 'clients';
export const CUSTOMERS_ADD = 'clients_add';
export const CUSTOMERS_EDIT = 'clients_edit';
export const PROFILE = 'profile';
export const LOGIN = 'login';
export const MEDICATIONS = 'medications';
export const MEDICATIONS_VIEW = 'medications_view';
export const TECH_BULLETINS = 'techbulletins';
export const TECH_BULLETINS_VIEW = 'techbulletins_view';
export const PRESCRIPTIONS = 'prescriptions';
export const PRESCRIPTIONS_ADD = 'prescriptions_add';
export const PRESCRIPTIONS_EDIT = 'prescriptions_edit';
export const REFILL_REQUEST = 'refill_request';
export const REFILL_REQUEST_ADD = 'refill_request_add';
export const REFILL_REQUEST_EDIT = 'refill_request_edit';
export const ONLINE_REFILL = 'online_refill';
export const ONLINE_REFILL_EDIT = 'online_refill_edit';
export const ACTIVITY = 'activity';
export const WEBSITE = 'website';
export const JOURNAL_ARTICLES_AND_PUBLICATIONS = 'journal_articles_and_publications';

export const KEY_LABEL = {
  [LOGIN]: 'Login',
  [USERS]: 'Users',
  [PROFILE]: 'Profile',
  [CUSTOMERS]: 'Clients',
  [MEDICATIONS]: 'Formulations',
  [TECH_BULLETINS]: 'Tech Bulletins',
  [TECH_BULLETINS_VIEW]: 'Tech Bulletin View',
  [JOURNAL_ARTICLES_AND_PUBLICATIONS]: 'Journal Articles and Publications',
  [PRESCRIPTIONS]: 'Prescriptions',
  [REFILL_REQUEST]: 'Refill Request',
  [REFILL_REQUEST_ADD]: 'New Refill Request',
  [REFILL_REQUEST_EDIT]: 'Edit Refill Request',
  [ONLINE_REFILL]: 'Online Refill Request',
  [ACTIVITY]: 'Activity Log',
  [WEBSITE]: "Go back to ZooPharm's website",
};

export const PATH_NAME = {
  [LOGIN]: '/login',
  [PROFILE]: '/profile',
  [USERS]: '/users',
  [USERS_ADD]: '/users/add',
  [USERS_EDIT]: '/users/edit',
  [CUSTOMERS]: '/clients',
  [CUSTOMERS_ADD]: '/clients/add',
  [CUSTOMERS_EDIT]: '/clients/edit',
  [MEDICATIONS]: '/medications',
  [MEDICATIONS_VIEW]: '/medications/view',
  [TECH_BULLETINS]: '/techbulletins',
  [TECH_BULLETINS_VIEW]: '/techbulletins/view',
  [JOURNAL_ARTICLES_AND_PUBLICATIONS]: `${config.site}/journal-articles-publications/`,
  [PRESCRIPTIONS]: '/prescriptions',
  [PRESCRIPTIONS_ADD]: '/prescriptions/add',
  [PRESCRIPTIONS_EDIT]: '/prescriptions/edit',
  [ACTIVITY]: '/activity-log',
  [REFILL_REQUEST]: '/refill-request',
  [REFILL_REQUEST_ADD]: '/refill-request/add',
  [REFILL_REQUEST_EDIT]: '/refill-request/edit',
  [ONLINE_REFILL]: '/online-refill-request',
  [ONLINE_REFILL_EDIT]: '/online-refill-request/edit',
};

export const PATH_LABEL = {
  [PATH_NAME[LOGIN]]: 'Login',
  [PATH_NAME[PROFILE]]: 'Profile',
  [PATH_NAME[USERS]]: 'Users',
  [PATH_NAME[USERS_ADD]]: 'Create User',
  [PATH_NAME[USERS_EDIT]]: 'Edit User',
  [PATH_NAME[CUSTOMERS]]: 'Clients',
  [PATH_NAME[CUSTOMERS_ADD]]: 'Create Client',
  [PATH_NAME[CUSTOMERS_EDIT]]: 'Edit Client',
  [PATH_NAME[MEDICATIONS]]: 'Formulations',
  [PATH_NAME[MEDICATIONS_VIEW]]: 'Formulations Detail',
  [PATH_NAME[TECH_BULLETINS]]: 'Tech Bulletins',
  [PATH_NAME[TECH_BULLETINS_VIEW]]: 'Tech Bulletin Detail',
  [PATH_NAME[JOURNAL_ARTICLES_AND_PUBLICATIONS]]: 'Journal Articles and Publications',
  [PATH_NAME[PRESCRIPTIONS]]: 'Prescriptions',
  [PATH_NAME[PRESCRIPTIONS_ADD]]: 'New Prescription',
  [PATH_NAME[PRESCRIPTIONS_EDIT]]: 'Edit Prescription',
  [PATH_NAME[REFILL_REQUEST]]: 'Refill Request',
  [PATH_NAME[REFILL_REQUEST_ADD]]: 'New Refill Request',
  [PATH_NAME[REFILL_REQUEST_EDIT]]: 'Edit Refill Request',
  [PATH_NAME[ONLINE_REFILL]]: 'Online Refill Request',
  [PATH_NAME[ONLINE_REFILL_EDIT]]: 'Edit Online Refill Request',
  [PATH_NAME[ACTIVITY]]: 'Activity Log',
};

export const PATHS = [
  {
    key: USERS,
    path: PATH_NAME[USERS],
    text: KEY_LABEL[USERS],
  },
  {
    key: CUSTOMERS,
    path: PATH_NAME[CUSTOMERS],
    text: KEY_LABEL[CUSTOMERS],
  },
  {
    key: MEDICATIONS,
    path: PATH_NAME[MEDICATIONS],
    text: KEY_LABEL[MEDICATIONS],
  },
  {
    key: TECH_BULLETINS,
    path: PATH_NAME[TECH_BULLETINS],
    text: KEY_LABEL[TECH_BULLETINS],
  },
  {
    key: JOURNAL_ARTICLES_AND_PUBLICATIONS,
    path: PATH_NAME[JOURNAL_ARTICLES_AND_PUBLICATIONS],
    text: KEY_LABEL[JOURNAL_ARTICLES_AND_PUBLICATIONS],
  },
  {
    key: PRESCRIPTIONS,
    path: PATH_NAME[PRESCRIPTIONS],
    text: KEY_LABEL[PRESCRIPTIONS],
  },
  {
    key: REFILL_REQUEST,
    path: PATH_NAME[REFILL_REQUEST],
    text: KEY_LABEL[REFILL_REQUEST],
  },
  {
    key: ONLINE_REFILL,
    path: PATH_NAME[ONLINE_REFILL],
    text: KEY_LABEL[ONLINE_REFILL],
  },
  {
    key: ACTIVITY,
    path: PATH_NAME[ACTIVITY],
    text: KEY_LABEL[ACTIVITY],
  },
  {
    key: WEBSITE,
    path: config.site,
    text: KEY_LABEL[WEBSITE],
  },
];
