import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Card,
  Grid,
  Typography,
  Divider,
  CardContent,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import bannerIcon from '../../assets/images/alert-icon.png';

const styles = theme => ({
  root: {
    display: 'block',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing.unit,
  },
  cardContent: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.dark,
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  text: {
    alignSelf: 'center',
  },
  icon: {
    fontSize: 24,
  },
  dismiss: {
    marginTop: '-15px',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  open: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  onDismiss: PropTypes.func,
  dismissable: PropTypes.bool,
};

const defaultProps = {
  open: true,
  type: 'info',
  text: '',
  onDismiss: () => {},
  dismissable: true,
  children: null,
};

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.collapse = this.collapse.bind(this);
  }

  componentWillMount() {
    const { open } = this.props;
    this.setState({ open });
  }

  collapse() {
    const { onDismiss } = this.props;
    this.setState({ open: false }, () => { onDismiss(); });
  }

  render() {
    const {
      classes,
      text,
      type,
      dismissable,
      children,
    } = this.props;
    const { open } = this.state;
    let color = null;
    if (type === 'warning') {
      color = classes.warning;
    } else if (type === 'danger') {
      color = classes.danger;
    } else if (type === 'success') {
      color = classes.success;
    } else {
      color = classes.info;
    }

    let content = (
      <Typography variant="body2" color="primary">
        {text}
      </Typography>
    );
    if (children) {
      content = children;
    }

    return (
      <Collapse in={open}>
        <Paper elevation={0} className={classes.root}>
          <Card elevation={0} className={color}>
            <CardContent className={classes.cardContent}>
              <Grid
                container
                spacing={16}
                direction="row"
                wrap="nowrap"
                justify="flex-start"
              >
                <Grid item>
                  <img src={bannerIcon} height="50px" alt="footer-logo" />
                </Grid>
                <Grid xs={10} sm={10} item className={classes.text}>
                  {content}
                </Grid>
                {dismissable && (
                  <Grid xs={2} sm={2} item className={classes.dismiss}>
                    <Grid
                      container
                      justify="flex-end"
                      alignItems="flex-start"
                    >
                      <IconButton
                        color="primary"
                        aria-label="Close"
                        onClick={this.collapse}
                      >
                        <CloseIcon className={classes.icon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Divider />
        </Paper>
      </Collapse>
    );
  }
}

Banner.propTypes = propTypes;
Banner.defaultProps = defaultProps;
export default withStyles(styles, { withTheme: true })(Banner);
