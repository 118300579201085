import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import LoginForm from '../containers/LoginForm/LoginForm';
import ForgotPasswordForm from '../containers/ForgotPasswordForm/ForgotPasswordForm';
import ResetPasswordForm from '../containers/ResetPasswordForm/ResetPasswordForm';
import ConfirmEmail from '../containers/ConfirmEmail/ConfirmEmail';

import { PROFILE, LOGIN } from '../constants/paths';

import logo from '../assets/images/login-logo.png';

const styles = theme => ({
  container: {
    minHeight: '100vh',
  },
  paper: {
    width: '280px',
    padding: theme.spacing.unit * 3,
    '@media (max-width: 320px)': {
      width: '240px',
    },
    '@media (max-width: 240px)': {
      width: '255px',
    },
  },
  logoContainer: {
    marginLeft: '-24px',
    marginRight: '-24px',
    marginBottom: '30px',
    backgroundColor: '#fff',
    marginTop: '-24px',
    borderRadius: '4px 4px 0px 0px',
    padding: '40px',
  },
  logo: {
    width: '250px',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

class LoginPage extends Component {
  componentDidMount() {
    const { authStore, history } = this.props;

    if (authStore.isLoggedIn) {
      history.push(`/${PROFILE}`);
    }
  }

  render() {
    const { classes, location, match } = this.props;
    const { from } = location.state || { from: { pathname: '/' } };

    return (
      <Grid justify="center" alignItems="center" className={classes.container} container>
        <Paper className={classes.paper}>
          <div className={classes.logoContainer}>
            <Typography align="center">
              <img src={logo} alt="MyMobility" className={classes.logo} />
            </Typography>
          </div>
          <Switch>
            <Route path={match.url} exact render={() => <LoginForm from={from} />} />
            <Route path={`${match.url}/forgot-password`} component={ForgotPasswordForm} />
            <Route path={`${match.url}/reset-password/:token`} component={ResetPasswordForm} />
            <Route
              path={`${match.url}/confirm-email/:confirmationToken`}
              component={ConfirmEmail}
            />
            <Redirect to={`/${LOGIN}`} />
          </Switch>
        </Paper>
      </Grid>
    );
  }
}

LoginPage.propTypes = propTypes;
const LoginPageStyled = withStyles(styles, { withTheme: true })(LoginPage);
export default inject('authStore')(observer(LoginPageStyled));
