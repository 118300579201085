import React from 'react';
import { shape, oneOfType, object } from 'prop-types';
import UserForm from '../../containers/UserForm/UserForm';

const propTypes = {
  match: oneOfType([object]).isRequired,
  history: shape({}).isRequired,
};

const UserEditPage = ({ match, history }) => {
  const userId = parseInt(match.params.userId, 10);
  const props = { history };

  if (!Number.isNaN(userId)) {
    props.userId = userId;
  }

  return <UserForm {...props} />;
};

UserEditPage.propTypes = propTypes;
export default UserEditPage;
