import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Drawer as MuiDrawer,
  List,
  Hidden,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import config from '../../config';
import routes from '../../navigation/routes';
import logo from '../../assets/images/sidebar-logo.png';
import './Drawer.css';

const styles = theme => ({
  listItemRoot: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 22,
    paddingRight: 22,
  },
  activeText: {
    color: theme.drawer.bgColor,
  },
  toolbar: theme.mixins.toolbar,
  listRoot: {
    paddingTop: 0,
  },
  drawerPaper: {
    borderRight: 0,
    width: theme.drawer.width,
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
    },
  },
  listIconRoot: {
    color: theme.drawer.bgColor,
  },
  itemText: {
    padding: 0,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  mobileOpen: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
  role: PropTypes.number.isRequired,
};

const defaultProps = {
  mobileOpen: false,
};

const isActive = (path, current) => {
  if (path === current || current.indexOf(path) !== -1) {
    return true;
  }
  return false;
};

const getItems = (role, currentPath, classes, toggleHandler) => (
  <List className={classes.listRoot}>
    <a href={config.site} target="_blank" rel="noopener noreferrer">
      <ListItem className="listLogo" disableGutters>
        <img src={logo} className="logo" alt="logo" />
      </ListItem>
    </a>
    <Divider />
    {routes[role].map((route) => {
      const active = isActive(route.path, currentPath);
      const isExternalLink = route.external;
      const item = (
        <ListItem button disableGutters className={classes.listItemRoot}>
          <ListItemIcon className={active ? classes.listIconRoot : ''}>{route.icon}</ListItemIcon>
          <ListItemText
            className={classes.itemText}
            primary={route.text}
            primaryTypographyProps={{
              className: active ? classes.activeText : '',
            }}
          />
        </ListItem>
      );
      let link = null;
      if (isExternalLink) {
        link = (
          <a href={route.path} target="_blank" rel="noopener noreferrer">
            {item}
          </a>
        );
      } else {
        link = (
          <Link to={route.path} onClick={toggleHandler}>
            {item}
          </Link>
        );
      }
      return (
        <li className={active ? 'active' : ''} key={route.key}>
          {link}
        </li>
      );
    })}
  </List>
);

const Drawer = ({
  mobileOpen, toggleHandler, classes, theme, location, role,
}) => (
  <div>
    <Divider />
    <Hidden lgUp>
      <MuiDrawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={toggleHandler}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{ keepMounted: true }}
      >
        {getItems(role, location.pathname, classes, toggleHandler)}
      </MuiDrawer>
    </Hidden>
    <Hidden mdDown implementation="css">
      <MuiDrawer variant="permanent" open classes={{ paper: classes.drawerPaper }}>
        {getItems(role, location.pathname, classes)}
      </MuiDrawer>
    </Hidden>
  </div>
);

Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;

const DrawerStyled = withStyles(styles, { withTheme: true })(Drawer);
export default withRouter(DrawerStyled);
