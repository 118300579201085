import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { Typography } from '@material-ui/core';
import Form from '../../components/Form/Form';

const styles = () => ({
  wrapper: {
    marginBottom: '30px',
  },
});

const fields = [
  {
    id: 'newPassword',
    name: 'newPassword',
    label: 'New Password',
    type: 'password',
    validateAs: 'password',
    defaultVal: '',
    required: true,
    passwordSchema: {
      min: 8,
      matches: /(\d*[a-zA-Z]+\d*)+/,
      errorMsg: "New Password can't be entirely numeric.",
    },
  },
  {
    id: 'confirmNewPassword',
    name: 'confirmNewPassword',
    label: 'Confirm Password',
    type: 'password',
    validateAs: 'confirm',
    defaultVal: '',
    required: true,
    confirmSchema: {
      compareAgainst: 'newPassword',
      errorMsg: "Passwords don't match.",
    },
  },
];

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const ResetPasswordForm = ({
  classes, authStore, match, history,
}) => (
  <div>
    <div className={classes.wrapper}>
      <Typography>
        Enter your new password below. Once done, your new password can be modified from your
        profile.
      </Typography>
    </div>
    <div className={classes.wrapper}>
      <Form
        fields={fields}
        onSubmit={async ({ newPassword, confirmNewPassword }, { resetForm }) => {
          try {
            const { token } = match.params;

            await authStore.resetPassword(token, newPassword, confirmNewPassword);
            history.push('/login');
          } catch (error) {
            resetForm();
          }
        }}
        submitLabel="Save"
      />
    </div>
    <Typography align="center" color="textSecondary">
      <Link to="/login">Back to Login</Link>
    </Typography>
  </div>
);

ResetPasswordForm.propTypes = propTypes;
const ResetPasswordFormStyled = withStyles(styles, { withTheme: true })(ResetPasswordForm);
export default inject('authStore')(observer(ResetPasswordFormStyled));
