import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import MedicationsList from './Index';
import MedicationsView from './View';

import { ROLE_VET, ROLE_NON_VETERINARIAN } from '../../constants/roles';

const propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const MedicationsPage = ({ match }) => (
  <Switch>
    <PrivateRoute
      path={match.url}
      exact
      component={MedicationsList}
      roles={[ROLE_VET, ROLE_NON_VETERINARIAN]}
    />
    <PrivateRoute
      path={`${match.url}/view/:medicationId`}
      component={MedicationsView}
      roles={[ROLE_VET, ROLE_NON_VETERINARIAN]}
    />
    <Redirect to="/medications" />
  </Switch>
);

MedicationsPage.propTypes = propTypes;
export default MedicationsPage;
