import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer, PropTypes as mobxPropTypes } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Form from "../../components/Form/Form";
import {
  ROLE_NON_VETERINARIAN,
  ROLE_PHARMACIST,
  ROLE_VET,
  ROLE_WATCHER,
} from "../../constants/roles";

const styles = () => ({
  wrapper: {
    marginBottom: "30px",
  },
});

const propTypes = {
  authStore: mobxPropTypes.observableObject.isRequired,
  from: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const fields = [
  {
    id: "email",
    name: "email",
    label: "Email",
    type: "email",
    validateAs: "email",
    defaultVal: "",
    required: true,
  },
  {
    id: "password",
    name: "password",
    label: "Password",
    type: "password",
    validateAs: "string",
    defaultVal: "",
    required: true,
  },
];

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(values, { resetForm }) {
    const { authStore } = this.props;
    try {
      await authStore.login(values.email, values.password);
      this.setState({ redirectToReferrer: true });
    } catch (error) {
      resetForm();
    }
  }

  render() {
    const { from, authStore } = this.props;
    const { user, isExpired } = authStore;
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      if (from.pathname === "/" && user) {
        const { getRole } = authStore;

        switch (getRole) {
          case ROLE_PHARMACIST:
          case ROLE_WATCHER:
            from.pathname = "/prescriptions";
            break;
          case ROLE_VET:
            from.pathname = "/medications";
            break;
          case ROLE_NON_VETERINARIAN:
            from.pathname = "/medications";
            break;
          default:
            from.pathname = "/profile";
            break;
        }
      }

      return <Redirect to={from} />;
    }

    return (
      <div>
        {isExpired && (
          <Typography color="error" gutterBottom>
            Your session was closed because you were inactive for 15 minutes.
            Please re-enter your login credentials to verify that it
            {"'"}s you.
          </Typography>
        )}
        <Form
          fields={fields}
          onSubmit={this.handleSubmit}
          submitLabel="Login"
        />
        <Typography align="center" color="textSecondary">
          <Link to="/login/forgot-password">- I forgot my password -</Link>
        </Typography>
      </div>
    );
  }
}

LoginForm.propTypes = propTypes;
const LoginFormStyled = withStyles(styles, { withTheme: true })(LoginForm);
export default inject("authStore")(observer(LoginFormStyled));
