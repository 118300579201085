import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { oneOf, oneOfType, object } from 'prop-types';

const propTypes = {
  color: oneOf(['green', 'blue', 'red', 'yellow']).isRequired,
  spacing: oneOf(['right', 'left']).isRequired,
  classes: oneOfType([object]).isRequired,
};

const styles = () => ({
  root: {
    width: 10,
    height: 10,
    borderRadius: '100%',
    display: 'inline-block',
  },
  right: {
    marginRight: 10,
  },
  left: {
    marginLeft: 10,
  },
  blue: {
    backgroundColor: 'rgb(0, 137, 255)',
    boxShadow: '0 0 10px rgba(0, 137, 255, 0.8)',
  },
  yellow: {
    backgroundColor: 'rgb(255, 186, 0)',
    boxShadow: '0 0 10px rgba(255, 186, 0, 0.8)',
  },
  green: {
    backgroundColor: 'rgb(70, 201, 58)',
    boxShadow: '0 0 10px rgba(70, 201, 58, 0.8)',
  },
  red: {
    backgroundColor: 'rgb(255, 71, 87)',
    boxShadow: '0 0 10px rgba(255, 71, 87, 0.8)',
  },
});

const StatusIcon = ({ color, spacing, classes }) => (
  <span className={`${classes.root} ${classes[spacing]} ${classes[color]}`} />
);

StatusIcon.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(StatusIcon);
