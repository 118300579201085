import React, { Fragment, Component } from 'react';
import {
  oneOfType, bool, number, object,
} from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import {
  TablePagination, TableRow, IconButton, Hidden,
} from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import { ENABLED, STATUS_LIST, STATUS_LABEL } from '../../constants/customers';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import DeaStatusBaneer from '../DeaStatusBanner/DeaStatusBanner';
import LicenseStatusBanner from '../LicenseStatusBanner/LicenseStatusBanner';

import removeEmptyKeys from '../../utils/removeEmptyKeys';

class CustomersTable extends Component {
  static propTypes = {
    customerStore: mobxPropTypes.observableObject.isRequired,
    loadingStore: mobxPropTypes.observableObject.isRequired,
    history: oneOfType([object]).isRequired,
    paginate: bool,
    showEmptyRows: bool,
    search: bool,
    defaultLimit: number,
  };

  static defaultProps = {
    paginate: false,
    search: false,
    showEmptyRows: false,
    defaultLimit: 10,
  };

  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;

    this.state = {
      data: [],
      _limit: defaultLimit,
      filters: {},
      total: 0,
      page: 0,
    };
  }

  componentWillMount() {
    this.fetchData({ status: ENABLED });
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'Name' },
      { id: 2, label: 'Email', hidden: { xsDown: true } },
      { id: 3, label: 'Phone', hidden: { smDown: true } },
      { id: 4, label: 'Status' },
      { id: 5, label: '' },
    ];
  }

  fetchData = async (searchFilters = null, reset = false) => {
    const { customerStore, loadingStore } = this.props;
    loadingStore.setReady(false);

    const { _limit, page, filters } = this.state;
    const newFilters = removeEmptyKeys(searchFilters || filters);
    const offset = _limit * page;

    const params = Object.assign(
      { _limit, _start: offset, _sort: 'id:DESC' },
      reset ? {} : newFilters,
    );

    if (params.firstName) {
      params.firstName_contains = params.firstName;
      delete params.firstName;
    }

    try {
      const customers = await customerStore.api.customers.get({ id: null, params });

      this.setState({
        data: customers.results,
        filters: reset ? {} : newFilters,
        total: customers.totalCount,
      });
    } catch (error) {
      this.setState({
        data: [],
        filters: newFilters,
      });
    }

    loadingStore.setReady(true);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.fetchData());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ _limit: event.target.value }, () => this.fetchData());
  };

  handleEditClick = (row) => {
    const { history } = this.props;
    history.push(`/clients/edit/${row.id}`);
  };

  renderRows = () => {
    const { data } = this.state;

    return data.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell component="th" scope="row" padding="default">
          {`${row.firstName} ${row.lastName}`}
        </TableCell>
        <Hidden xsDown>
          <TableCell>{row.email}</TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell>{row.phoneNumber || '-'}</TableCell>
        </Hidden>
        <TableCell>{STATUS_LABEL[row.status]}</TableCell>
        <TableCell padding="none" isActions>
          <IconButton aria-label="Edit" color="secondary" onClick={() => this.handleEditClick(row)}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  renderPagination = () => {
    const { total, _limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={_limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  };

  renderSearch = () => {
    const fields = [
      {
        id: 'firstName',
        name: 'firstName',
        label: 'Name',
        type: 'text',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
      },
      {
        id: 'status',
        name: 'status',
        label: 'Status',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'bool',
        defaultVal: `${ENABLED}`,
        required: false,
        customProps: {
          inputProps: {
            name: 'status',
            id: 'status',
          },
          data: STATUS_LIST,
        },
      },
    ];

    return (
      <SearchForm
        onSubmit={this.fetchData}
        onReset={() => this.fetchData({ status: ENABLED }, true)}
        fields={fields}
      />
    );
  };

  render() {
    const { data, _limit, page } = this.state;
    const { paginate, search, showEmptyRows } = this.props;

    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = _limit - Math.min(_limit, data.length - page * _limit);
    }

    const searchComponent = search ? this.renderSearch() : null;
    return (
      <Fragment>
        <LicenseStatusBanner />
        <DeaStatusBaneer />
        {searchComponent}
        <DataTable
          header={CustomersTable.getTableHeader()}
          renderRows={this.renderRows}
          emptyRows={emptyRows}
          paginate={paginate}
          renderPagination={this.renderPagination}
          empty={data.length === 0}
        />
      </Fragment>
    );
  }
}

export default inject('customerStore', 'loadingStore')(observer(CustomersTable));
