import React from 'react';
import { oneOfType, object } from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';

import RefillsRequestList from './Index';
import RefillRequestAddPage from './Add';
import RefillRequestEditPage from './Edit';

import { ROLE_PHARMACIST, ROLE_VET, ROLE_WATCHER } from '../../constants/roles';

const propTypes = {
  match: oneOfType([object]).isRequired,
};

const OnlineRefillsPage = ({ match }) => (
  <Switch>
    <PrivateRoute
      path={match.url}
      exact
      component={RefillsRequestList}
      roles={[ROLE_PHARMACIST, ROLE_VET, ROLE_WATCHER]}
    />
    <PrivateRoute path={`${match.url}/add`} component={RefillRequestAddPage} roles={[ROLE_VET]} />
    <PrivateRoute
      path={`${match.url}/edit/:refillId`}
      component={RefillRequestEditPage}
      roles={[ROLE_PHARMACIST, ROLE_VET, ROLE_WATCHER]}
    />
    <Redirect to="/refill-request" />
  </Switch>
);

OnlineRefillsPage.propTypes = propTypes;
export default OnlineRefillsPage;
