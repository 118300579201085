import React from 'react';
import { Redirect } from 'react-router-dom';
import { shape, oneOfType, object } from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import RefillRequestForm from '../../containers/RefillRequestForm/RefillRequestForm';
import { VERIFIED } from '../../constants/deaAndLicenseStatus';
import { ROLE_VET } from '../../constants/roles';

const propTypes = {
  match: oneOfType([object]).isRequired,
  history: shape({}).isRequired,
  stateStore: mobxPropTypes.observableObject.isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
};

const RefillRequestEditPage = ({
  match,
  history,
  stateStore,
  authStore: { getRole, getLicenseStatus },
}) => {
  const refillId = parseInt(match.params.refillId, 10);
  const states = stateStore.states
    ? stateStore.states.reduce(
      (acc, state) => acc.concat({
        label: state.name,
        value: state.id,
      }),
      [],
    )
    : [];
  const props = {
    history,
    match,
    states,
  };

  if (!Number.isNaN(refillId)) {
    props.refillId = refillId;
  }

  const isValid = getRole === ROLE_VET ? getLicenseStatus === VERIFIED : true;

  return isValid ? <RefillRequestForm {...props} /> : <Redirect to="/refill-request" />;
};

RefillRequestEditPage.propTypes = propTypes;
export default inject('authStore', 'stateStore')(observer(RefillRequestEditPage));
