import React from 'react';
import { Redirect } from 'react-router-dom';
import { oneOfType, object } from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';
import PrescriptionForm from '../../containers/PrescriptionForm/PrescriptionForm';

import { VERIFIED } from '../../constants/deaAndLicenseStatus';

const propTypes = {
  match: oneOfType([object]).isRequired,
  history: oneOfType([object]).isRequired,
  stateStore: mobxPropTypes.observableObject.isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
};

const PrescriptionAddPage = ({
  match,
  history,
  stateStore,
  authStore: { getRole, getLicenseStatus },
}) => {
  const states = stateStore.states
    ? stateStore.states.reduce(
      (acc, state) => acc.concat({
        label: state.name,
        value: state.id,
      }),
      [],
    )
    : [];
  const typeId = parseInt(match.params.typeId, 10);
  const props = {
    history,
    match,
    states,
    role: getRole,
    type: match.params.type,
  };

  if (!Number.isNaN(typeId)) {
    props.typeId = typeId;
  }

  return getLicenseStatus === VERIFIED ? (
    <PrescriptionForm {...props} />
  ) : (
    <Redirect to="/prescriptions" />
  );
};

PrescriptionAddPage.propTypes = propTypes;
export default inject('authStore', 'stateStore')(observer(PrescriptionAddPage));
