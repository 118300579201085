import React, { Component, Fragment } from 'react';
import {
  shape, number, arrayOf, object,
} from 'prop-types';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import PrescriptionTable from './PrescriptionsTable';
import FormCard from '../../components/Form/FormCard';
import FormHeader from '../../components/FormHeader/FormHeader';

import {
  REFILLS_STATUS_LIST,
  REFILLS_STATUS_COLOR,
  NEW,
  IN_PROGRESS,
} from '../../constants/refillsStatus';
import { ROLE_PHARMACIST, ROLE_VET, ROLE_WATCHER } from '../../constants/roles';

class PrescriptionVetForm extends Component {
  static propTypes = {
    refillRequestStore: mobxPropTypes.observableObject.isRequired,
    prescriptionStore: mobxPropTypes.observableObject.isRequired,
    customerStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    states: arrayOf(object).isRequired,
    history: shape({}).isRequired,
    refillId: number,
  };

  static defaultProps = {
    refillId: null,
  };

  state = {
    showFields: false,
    refillRequest: null,
  };

  async componentWillMount() {
    const { refillId } = this.props;

    if (refillId) this.fetchRefillRequest(refillId);
  }

  fetchRefillRequest = async (refillId) => {
    const { refillRequestStore } = this.props;
    const refillRequest = await refillRequestStore.fetchRefillRequest(refillId);

    if (refillRequest.id) {
      this.setState({ refillRequest, showFields: true });
    }
  };

  handleCreate = async (values) => {
    const { refillRequestStore, history } = this.props;
    const refillRequest = await refillRequestStore.create(values);

    if (refillRequest) {
      history.push('/refill-request');
    }
  };

  handleUpdate = async (id, values) => {
    const { refillRequestStore } = this.props;
    const result = await refillRequestStore.update(id, values);

    if (result) {
      this.setState({ refillRequest: result });
    }
  };

  handleCustomersAutoComplete = (input) => {
    if (!input) return [];

    const { customerStore } = this.props;
    return customerStore.fetchCustomers(input);
  };

  handleUpdateStatus = async (newStatus) => {
    const { refillRequest } = this.state;
    const { refillRequestStore, refillId } = this.props;
    const { status = null } = await refillRequestStore.changeStatus(newStatus, refillId);

    if (status) {
      const updatedRefill = { ...refillRequest, status };
      this.setState({ refillRequest: updatedRefill });
      return true;
    }
    return false;
  };

  handleDownload = () => {
    const { refillRequestStore, refillId } = this.props;

    const url = refillRequestStore.download(refillId);
    window.open(url, '_blank', 'noopener');
  };

  fetchCustomer = async (id, setFieldValue) => {
    const { customerStore } = this.props;

    const customer = await customerStore.fetchCustomer(id);

    setFieldValue('customerEmail', customer.email);
    setFieldValue('customerPhoneNumber', customer.phoneNumber);
    setFieldValue('customerStreet', customer.street);
    setFieldValue('customerCity', customer.city);
    setFieldValue('customerState', customer.state.id);
    setFieldValue('customerZip', customer.zip);

    this.setState({ showFields: true });
  };

  fetchShippingData = async (val, setFieldValue, formValues) => {
    const data = {};
    const { authStore, customerStore } = this.props;

    if (val === 'customer' && formValues.customer) {
      const customer = await customerStore.fetchCustomer(formValues.customer);

      data.name = `${customer.firstName} ${customer.lastName}`;
      data.email = customer.email;
      data.street = customer.street;
      data.city = customer.city;
      data.state = customer.state.id;
      data.zip = customer.zip;
    } else if (val === 'profile') {
      data.name = `${authStore.getUser.firstName} ${authStore.getUser.lastName}`;
      data.email = authStore.getUser.email;
      data.street = authStore.getUser.vetprofile.clinicStreet;
      data.city = authStore.getUser.vetprofile.clinicCity;
      data.state = authStore.getUser.vetprofile.clinicState;
      data.zip = authStore.getUser.vetprofile.clinicZip;
    }

    setFieldValue('fullName', data.name || '');
    setFieldValue('email', data.email || '');
    setFieldValue('street', data.street || '');
    setFieldValue('city', data.city || '');
    setFieldValue('state', data.state || '');
    setFieldValue('zip', data.zip || '');
  };

  render() {
    const {
      refillId,
      states,
      authStore: { getRole },
    } = this.props;
    const { showFields, refillRequest } = this.state;
    const isPharmacist = getRole === ROLE_PHARMACIST;
    const isWatcher = getRole === ROLE_WATCHER;
    const isVeterinary = getRole === ROLE_VET;

    if (refillId && !refillRequest) return null;

    const readOnly = refillRequest
      && ((isPharmacist && refillRequest.status !== IN_PROGRESS)
        || (isVeterinary && refillRequest.status !== NEW)
        || isWatcher);

    const fieldsBlock = [
      {
        title: 'Client Information',
        fields: [
          {
            id: 'customer',
            name: 'customer',
            label: 'Client Name',
            type: 'autocomplete',
            validateAs: 'string',
            defaultVal: refillRequest ? refillRequest.customer.id : '',
            required: true,
            show: isVeterinary,
            span: { xs: 0, sm: 3, md: 3 },
            customProps: {
              defaultInputValue: refillRequest
                ? `${refillRequest.customer.firstName} ${refillRequest.customer.lastName}`
                : '',
              defaultOptions: [],
              loadOptions: this.handleCustomersAutoComplete,
              handleClear: () => {},
              handleOnChange: this.fetchCustomer,
              clearCustomer: false,
            },
          },
          {
            id: 'customerId',
            name: 'customerId',
            label: 'Client Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: refillRequest
              ? `${refillRequest.customer.firstName} ${refillRequest.customer.lastName}`
              : '',
            required: false,
            disabled: true,
            show: isPharmacist || isWatcher,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'customerEmail',
            name: 'customerEmail',
            label: 'Email',
            type: 'email',
            validateAs: 'email',
            defaultVal: refillRequest ? refillRequest.customer.email : '',
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerPhoneNumber',
            name: 'customerPhoneNumber',
            label: 'Phone Number',
            type: 'text',
            validateAs: 'string',
            defaultVal: refillRequest ? refillRequest.customer.phoneNumber : '',
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerStreet',
            name: 'customerStreet',
            label: 'Street',
            type: 'text',
            validateAs: 'text',
            defaultVal: refillRequest ? refillRequest.customer.street : '',
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerCity',
            name: 'customerCity',
            label: 'City',
            type: 'text',
            validateAs: 'text',
            defaultVal: refillRequest ? refillRequest.customer.city : '',
            required: false,
            disabled: true,
            show: showFields,
          },
          {
            id: 'customerState',
            name: 'customerState',
            label: 'State',
            type: 'select',
            validateAs: 'text',
            defaultVal: refillRequest ? refillRequest.customer.state : '',
            required: false,
            disabled: true,
            show: showFields,
            placeholder: 'Select one',
            customProps: {
              inputProps: {
                name: 'customerState',
                id: 'customerState',
              },
              data: states,
            },
          },
          {
            id: 'customerZip',
            name: 'customerZip',
            label: 'Zip code',
            type: 'number',
            validateAs: 'text',
            defaultVal: refillRequest ? refillRequest.zip : '',
            required: false,
            disabled: true,
            show: showFields,
          },
        ],
      },
      {
        title: 'Prescription',
        show: showFields,
        fields: [
          {
            id: 'prescriptions',
            name: 'prescriptions',
            label: 'Prescriptions',
            type: 'arrayField',
            required: true,
            span: { xs: 0, sm: 9, md: 9 },
            validateAs: {
              prescription: 'string',
              prescriptionId: 'string',
              medication: 'string',
              animal: 'string',
              quantity: 'number',
              message: 'string',
            },
            render: ({ ...props }) => (
              <PrescriptionTable
                showAutocomplete={isVeterinary}
                minOne={isPharmacist}
                readOnly={readOnly}
                {...props}
              />
            ),
            defaultVal: refillRequest
              ? refillRequest.refillasprescriptions.reduce(
                (acc, item) => acc.concat({
                  prescription: item.prescription.id,
                  prescriptionId: item.prescription.prescriptionId || '',
                  animal: item.prescription.animalspecie || '',
                  medication: item.prescription.medication.name || '',
                  quantity: item.quantity,
                  message: item.message,
                }),
                [],
              )
              : [],
          },
        ],
      },
      {
        title: 'Shipping Information',
        show: showFields,
        fields: [
          {
            id: 'shippingSelectData',
            name: 'shippingSelectData',
            label: 'Use pre exist shipping data',
            type: 'select',
            placeholder: 'Select one',
            validateAs: 'text',
            defaultVal: '',
            required: false,
            show: isVeterinary,
            span: { xs: 0, sm: 9, md: 9 },
            customProps: {
              inputProps: {
                name: 'shippingSelectData',
                id: 'shippingSelectData',
              },
              data: [
                {
                  value: 'customer',
                  label: 'Use client information',
                },
                {
                  value: 'profile',
                  label: 'Use my profile information',
                },
              ],
              handleOnChange: this.fetchShippingData,
            },
          },
          {
            id: 'fullName',
            name: 'fullName',
            label: 'Complete Name',
            type: 'text',
            validateAs: 'string',
            defaultVal: refillRequest ? refillRequest.fullName : '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'email',
            name: 'email',
            label: 'Email',
            type: 'email',
            validateAs: 'email',
            defaultVal: refillRequest ? refillRequest.email : '',
            required: true,
            span: { xs: 0, sm: 3, md: 3 },
          },
          {
            id: 'street',
            name: 'street',
            label: 'Street',
            type: 'text',
            validateAs: 'text',
            defaultVal: refillRequest ? refillRequest.street : '',
            required: true,
          },
          {
            id: 'city',
            name: 'city',
            label: 'City',
            type: 'text',
            validateAs: 'text',
            defaultVal: refillRequest ? refillRequest.city : '',
            required: true,
          },
          {
            id: 'state',
            name: 'state',
            label: 'State',
            type: 'select',
            validateAs: 'text',
            defaultVal: refillRequest ? refillRequest.state.id : '',
            required: true,
            placeholder: 'Select one',
            customProps: {
              inputProps: {
                name: 'state',
                id: 'state',
              },
              data: states,
            },
          },
          {
            id: 'zip',
            name: 'zip',
            label: 'Zip code',
            type: 'number',
            validateAs: 'number',
            defaultVal: refillRequest ? refillRequest.zip : '',
            required: true,
          },
        ],
      },
    ];

    return (
      <Fragment>
        {refillRequest && (
          <FormHeader
            print
            onPrint={this.handleDownload}
            onChangeStatus={this.handleUpdateStatus}
            readOnly={isWatcher || isVeterinary}
            status={refillRequest.status}
            date={refillRequest.created_at}
            statusList={REFILLS_STATUS_LIST}
            statusColors={REFILLS_STATUS_COLOR}
            title={`Refill Request Number ${refillRequest.id}`}
          />
        )}
        <FormCard
          readOnly={readOnly}
          fields={fieldsBlock}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              if (refillRequest) {
                await this.handleUpdate(refillRequest.id, values);
              } else {
                await this.handleCreate(values);
              }
              setSubmitting(false);
            } catch (e) {
              setSubmitting(false);
            }
          }}
          submitLabel="Save Refill Request"
          submitColor="secondary"
          showSubmitBtn={showFields}
          columns={4}
          spacing={24}
        />
      </Fragment>
    );
  }
}

export default inject('refillRequestStore', 'customerStore', 'prescriptionStore', 'authStore')(
  observer(PrescriptionVetForm),
);
