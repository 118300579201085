import { observable, decorate } from 'mobx';

class StateStore {
  constructor(api) {
    this.api = api;
    this.states = null;
  }

  setStates(states) {
    this.states = states;
  }

  get getStates() {
    return this.states;
  }

  async fetch() {
    if (!this.states) {
      try {
        const response = await this.api.states.get();

        this.setStates(response);
      } catch (e) {
        e.error = true;
        throw e;
      }
    }
  }
}
decorate(StateStore, {
  states: observable,
});

export default StateStore;
