import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import UsersTable from '../../containers/UsersTable/UsersTable';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const UsersListPage = ({ history, classes }) => (
  <div className={classes.list}>
    <UsersTable history={history} paginate search />
  </div>
);

UsersListPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(UsersListPage);
