import React from 'react';
import { shape, oneOfType, object } from 'prop-types';
import CustomerForm from '../../containers/CustomerForm/CustomerForm';

const propTypes = {
  match: oneOfType([object]).isRequired,
  history: shape({}).isRequired,
};

const CustomerEditPage = ({ match, history }) => {
  const customerId = parseInt(match.params.customerId, 10);
  const props = { history, match };

  if (!Number.isNaN(customerId)) {
    props.customerId = customerId;
  }

  return <CustomerForm {...props} />;
};

CustomerEditPage.propTypes = propTypes;
export default CustomerEditPage;
