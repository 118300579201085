/* eslint-disable react/no-danger */
import React, { Component, Fragment } from 'react';
import { number, oneOfType, object } from 'prop-types';
import {
  TableRow, TableCell, IconButton, Grid, Paper, Typography, Button,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';
import { VerticalAlignBottom } from '@material-ui/icons';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import { VERIFIED } from '../../constants/deaAndLicenseStatus';

import DeaStatusBaneer from '../DeaStatusBanner/DeaStatusBanner';

import storage from '../../services/storage';
import config from '../../config';

import DeaScheduleIcon1 from '../../assets/images/title-icon-1.png';
import DeaScheduleIcon2 from '../../assets/images/title-icon-2.png';
import DeaScheduleIcon3 from '../../assets/images/title-icon-3.png';
import DeaScheduleIcon4 from '../../assets/images/title-icon-4.png';

import DataTable from '../../components/DataTable/DataTable';

import removeEmptyKeys from '../../utils/removeEmptyKeys';

const styles = theme => ({
  caption: {
    fontSize: 15,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  link: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 15,
    color: '#248596',
  },
  techBulletinsAttachment: {
    width: 55,
  },
  headline: {
    fontSize: 30,
    fontWeight: 500,
    color: '#248596',
  },
  title: {
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.54)',
    paddingTop: theme.spacing.unit * 2,
  },
  subheading: {
    fontSize: 18,
    borderBottom: '1px solid #e6e6e6',
    paddingTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
  },
  pdRight: {
    paddingRight: '10px',
  },
  marginTop: {
    marginTop: theme.spacing.unit * 1.5,
  },
  root: {
    width: '100%',
    marginBottom: theme.spacing.unit * 3,
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
  },
  list: {
    '& > ol, ul': {
      margin: 0,
      padding: '10px 0 0 20px',
    },
  },
  text: {
    '& > p, ol > li, ul > li': {
      fontWeight: 400,
      lineHeight: 1.8,
      fontSize: '0.875rem',
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    '& > h3': {
      fontWeight: 'inherit',
    },
  },
  row: {
    display: 'flex',
  },
  deaScheduleIcon: {
    width: 50,
    paddingRight: 15,
  },
});

class MedicationView extends Component {
  state = {
    medication: null,
    techbulletins: [],
    filters: {},
  };

  static propTypes = {
    medicationStore: mobxPropTypes.observableObject.isRequired,
    techBulletinsStore: mobxPropTypes.observableObject.isRequired,
    loadingStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    classes: oneOfType([object]).isRequired,
    history: oneOfType([object]).isRequired,
    medicationId: number.isRequired,
  };

  async componentWillMount() {
    const { medicationId } = this.props;

    if (medicationId) {
      const { medicationStore } = this.props;
      const medication = await medicationStore.fetchMedication(medicationId);

      if (medication.id) {
        this.setState({ medication, filters: { medications: medication.id } });
        this.fetchTechBulletinsData();
      }
    }
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'Tech Bulletin' },
      { id: 2, label: 'Attachment' },
    ];
  }

  fetchTechBulletinsData = async (searchFilters = null, reset = false) => {
    const { loadingStore, techBulletinsStore } = this.props;
    loadingStore.setReady(false);

    const { _limit, page, filters } = this.state;
    const newFilters = removeEmptyKeys(searchFilters || filters);

    const offset = _limit * page;
    const params = Object.assign(
      { _limit, _start: offset, _sort: 'name:ASC' },
      reset ? {} : newFilters,
    );

    try {
      const data = await techBulletinsStore.api.techBulletins.get({ params });

      this.setState({
        techbulletins: data.results || [],
        filters: reset ? {} : newFilters,
      });
    } catch (error) {
      this.setState({
        techbulletins: [],
        filters: newFilters,
      });
    }

    loadingStore.setReady(true);
  };

  handleDownload = () => {
    const { medication } = this.state;
    const { dataSheetFile } = medication;
    const token = storage.getItem('token');

    window.open(
      `${config.api.baseURL}/medications${dataSheetFile.url}/?token=${token}/`,
      '_blank',
      'noopener',
    );
  };

  handleDownloadTechbulletins = ({ attachment }) => {
    const token = storage.getItem('token');

    window.open(
      `${config.api.baseURL}/techbulletins${attachment.url}/?token=${token}/`,
      '_blank',
      'noopener',
    );
  };

  handlePrescription = () => {
    const { history } = this.props;
    const { medication } = this.state;

    history.push(`/prescriptions/add/medication/${medication.id}`);
  };

  renderRows = () => {
    const { classes } = this.props;
    const { techbulletins } = this.state;

    return techbulletins.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell component="th" scope="row" padding="default">
          {row.name}
        </TableCell>
        <TableCell className={classes.techBulletinsAttachment}>
          <IconButton color="secondary" onClick={() => this.handleDownloadTechbulletins(row)}>
            <DownloadIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  formatFaqs = () => {
    const { medication } = this.state;
    const { faqs } = medication;

    return faqs.reduce(
      (acc, item) => acc.concat({
        title: item.question,
        content: item.answer,
      }),
      [],
    );
  };

  prescriptionBtn = () => {
    const {
      medication: { deaControlled },
    } = this.state;
    const {
      authStore: { getLicenseStatus, getDeaStatus },
    } = this.props;

    // eslint-disable-next-line max-len
    const renderBtn = getLicenseStatus === VERIFIED && (deaControlled ? getDeaStatus === VERIFIED : true);

    return (
      renderBtn && (
        <Button color="secondary" variant="contained" onClick={this.handlePrescription}>
          {'SEND PRESCRIPTION'}
        </Button>
      )
    );
  };

  render() {
    const { classes } = this.props;
    const { medication, techbulletins } = this.state;

    if (!medication) return null;

    let title = null;
    let DeaScheduleIcon = null;

    if (
      medication.indications
      || medication.references
      || medication.pharmacokinetics
      || medication.contraindications
      || medication.howSupplied
      || medication.chemistry
      || medication.adverseReaction
      || medication.doseGuidelines
      || medication.faqs
      || medication.moreInformation
    ) {
      title = (
        <Typography className={classes.title} variant="h6" component="h3">
          {'Drug characteristics summary'}
        </Typography>
      );
    }

    switch (parseInt(medication.deaSchedule, 10)) {
      case 1:
        DeaScheduleIcon = DeaScheduleIcon1;
        break;
      case 2:
        DeaScheduleIcon = DeaScheduleIcon2;
        break;
      case 3:
        DeaScheduleIcon = DeaScheduleIcon3;
        break;
      case 4:
        DeaScheduleIcon = DeaScheduleIcon4;
        break;
      default:
        DeaScheduleIcon = null;
        break;
    }

    return (
      <Fragment>
        <DeaStatusBaneer />
        <Grid container justify="flex-end">
          <Paper className={classes.root}>
            <Grid container>
              <Grid item container justify="space-between" alignItems="center">
                <div>
                  {medication.animalcategories && (
                    <Typography variant="caption" className={classes.caption}>
                      {medication.animalcategories
                        .reduce((acc, categorie) => acc.concat(categorie.name), [])
                        .join(', ')}
                    </Typography>
                  )}

                  <div className={classes.row}>
                    {DeaScheduleIcon && (
                      <img
                        src={DeaScheduleIcon}
                        alt={`Dea Schedule: ${medication.deaSchedule}`}
                        className={classes.deaScheduleIcon}
                      />
                    )}
                    <div>
                      <Typography className={classes.headline} variant="h5" component="h2">
                        {medication.name}
                        {' '}
                        <span className={classes.caption}>
                          {' '}
                          {medication.presentation}
                        </span>
                      </Typography>
                      {medication.moreInformation && (
                        <Typography className={classes.link} dangerouslySetInnerHTML={{ __html: medication.moreInformation}}>
                        </Typography>
                      )}
                      <Typography variant="caption" className={classes.caption}>
                        {medication.releaseCategory}
                      </Typography>
                    </div>
                  </div>
                </div>
                {this.prescriptionBtn()}
              </Grid>

              <Grid item container direction="column">
                {medication.shortDescription && (
                  <div
                    className={`${classes.list} ${classes.text}`}
                    dangerouslySetInnerHTML={{ __html: medication.shortDescription }}
                  />
                )}
                {title}
                {medication.indications && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'Indications'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.indications }}
                    />
                  </Fragment>
                )}
                {medication.chemistry && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'Chemistry'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.chemistry }}
                    />
                  </Fragment>
                )}
                {medication.pharmacokinetics && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'Pharmacokinetics'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.pharmacokinetics }}
                    />
                  </Fragment>
                )}
                {medication.howSupplied && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'How supplied'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.howSupplied }}
                    />
                  </Fragment>
                )}
                {medication.doseGuidelines && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'Dose Guidelines'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.doseGuidelines }}
                    />
                  </Fragment>
                )}
                {medication.contraindications && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'Contraindications'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.contraindications }}
                    />
                  </Fragment>
                )}
                {medication.adverseReaction && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'Adverse Reaction'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.adverseReaction }}
                    />
                  </Fragment>
                )}
                {medication.faqs && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'Faqs'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.faqs }}
                    />
                  </Fragment>
                )}
                {medication.moreInformation && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'More Information'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.moreInformation }}
                    />
                  </Fragment>
                )}
                {medication.references && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.subheading}>
                      {'References'}
                    </Typography>
                    <div
                      className={`${classes.list} ${classes.text}`}
                      dangerouslySetInnerHTML={{ __html: medication.references }}
                    />
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Paper>

          {medication.dataSheetFile && (
            <Button variant="contained" onClick={this.handleDownload}>
              <VerticalAlignBottom className={classes.pdRight} />
              {'Download Data Sheet'}
            </Button>
          )}
        </Grid>
        <DataTable
          header={MedicationView.getTableHeader()}
          renderRows={this.renderRows}
          emptyRows={false}
          paginate={false}
          empty={techbulletins.length === 0}
        />
      </Fragment>
    );
  }
}

const MedicationViewStyled = withStyles(styles, { withTheme: true })(MedicationView);
export default inject('authStore', 'techBulletinsStore', 'medicationStore', 'loadingStore')(observer(MedicationViewStyled));
