import { observable, action, decorate } from 'mobx';

class AuditLogStore {
  constructor(api) {
    this.api = api;
    this.auditLog = null;
  }

  setAuditLog(auditLog) {
    this.auditLog = auditLog;
  }

  get getAuditLog() {
    return this.auditLog;
  }

  async fetch() {
    try {
      const response = await this.api.activitylogs.get();
      this.setAuditLog(response);
      return Promise.resolve(response);
    } catch (e) {
      e.error = true;
      return e;
    }
  }
}

decorate(AuditLogStore, {
  users: observable,
  setUsers: action,
});

export default AuditLogStore;
