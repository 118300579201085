import { observable, action, decorate } from 'mobx';

class SnackbarStore {
  constructor() {
    this.isOpen = false;
    this.duration = 5000;
    this.variant = 'info';
    this.title = '';
    this.messages = [];
    this.vertical = 'top';
    this.horizontal = 'right';
    this.close = this.close.bind(this);
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  setError({
    title,
    duration = 5000,
    messages = [],
    vertical = 'top',
    horizontal = 'right',
  }) {
    this.setMessage({
      title,
      duration,
      variant: 'error',
      messages,
      vertical,
      horizontal,
    });
  }

  setSuccess({
    title,
    duration = 5000,
    messages = [],
    vertical = 'top',
    horizontal = 'right',
  }) {
    this.setMessage({
      title,
      duration,
      variant: 'success',
      messages,
      vertical,
      horizontal,
    });
  }

  setMessage({
    title, duration, variant, messages, vertical, horizontal,
  }) {
    this.title = title;
    this.duration = duration;
    this.variant = variant;
    this.messages = Array.isArray(messages) ? messages : [];
    this.vertical = vertical;
    this.horizontal = horizontal;
    this.open();
  }
}

decorate(SnackbarStore, {
  isOpen: observable,
  duration: observable,
  variant: observable,
  title: observable,
  messages: observable,
  vertical: observable,
  horizontal: observable,
  open: action,
  close: action,
  setMessage: action,
});

export default SnackbarStore;
