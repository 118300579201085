import { observable, action, decorate } from 'mobx';

import errorParse from '../utils/errorParse';

class CustomerStore {
  constructor(api, snackbarStore, loadingStore) {
    this.api = api;
    this.snackbarStore = snackbarStore;
    this.loadingStore = loadingStore;
    this.customers = null;
  }

  setCustomers(customers) {
    this.customers = customers;
  }

  get getCustomers() {
    return this.customers;
  }

  async fetch() {
    try {
      const response = await this.api.customers.get();
      if (response) {
        this.setCustomers(response);
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    } catch (e) {
      e.error = true;
      return Promise.reject(e);
    }
  }

  async fetchCustomer(id) {
    this.loadingStore.setReady(false);

    try {
      const response = await this.api.customers.get({ id });

      this.loadingStore.setReady(true);

      if (response) {
        return Promise.resolve(response);
      }

      return Promise.reject(response);
    } catch (e) {
      this.loadingStore.setReady(true);
      e.error = true;

      return Promise.reject(e);
    }
  }

  async fetchCustomers(name) {
    let results = [];
    const params = {
      firstName_contains: name,
      lastName_contains: name,
    };

    const response = await this.api.customers.get({ id: null, params });

    if (response && response.results) {
      results = response.results.map(customer => ({
        value: customer.id,
        label: `${customer.firstName} ${customer.lastName}`,
      }));
    }

    return results;
  }

  async create(payload) {
    this.loadingStore.setReady(false);

    try {
      const customer = await this.api.customers.create(payload);

      this.snackbarStore.setSuccess({
        title: 'Client has been successfully created.',
      });
      this.loadingStore.setReady(true);

      return customer;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred. ${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });
      this.loadingStore.setReady(true);
      return false;
    }
  }

  async update(id, payload) {
    this.loadingStore.setReady(false);

    try {
      const result = await this.api.customers.update(id, payload);

      this.snackbarStore.setSuccess({
        title: 'Client has been successfully updated.',
      });
      this.loadingStore.setReady(true);

      return result;
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred.${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });

      this.loadingStore.setReady(true);
      return false;
    }
  }
}

decorate(CustomerStore, {
  customers: observable,
  setCustomers: action,
});

export default CustomerStore;
