import CreateClient from './createClient';
import events from '../utils/events';

export default ({ config }) => {
  let token = '';

  const client = CreateClient({
    config,
    getToken: () => token,
    setToken: (newToken) => {
      events.emit('setToken', newToken);
    },
    logout: () => {
      events.emit('logout');
    },
  });

  const api = {
    setToken: (t) => {
      token = t;
    },
    auth: {
      login({ email: identifier, password }) {
        return client.request({
          method: 'post',
          url: '/auth/local/',
          data: {
            identifier,
            password,
          },
        });
      },
      forgotPassword(email) {
        return client.request({
          method: 'post',
          url: '/auth/forgot-password',
          data: {
            email,
          },
        });
      },
      resetPassword({
        token: code,
        newPassword: password,
        confirmNewPassword: passwordConfirmation,
      }) {
        return client.request({
          method: 'post',
          url: '/auth/reset-password/',
          data: {
            code,
            password,
            passwordConfirmation,
          },
        });
      },
      changePassword({ oldPassword, newPassword, confirmNewPassword }) {
        return client.request({
          method: 'post',
          url: '/auth/change-password/',
          data: {
            oldPassword,
            password: newPassword,
            password2: confirmNewPassword,
          },
        });
      },
      refreshToken(refreshToken) {
        return client.request({
          method: 'post',
          url: '/auth/refresh-token/',
          data: {
            token: refreshToken,
          },
        });
      },
      confirmEmail(confirmationToken) {
        return client.request({
          method: 'post',
          url: '/local/users/confirm-email',
          data: {
            token: confirmationToken,
          },
        });
      },
      me() {
        return client.request({
          method: 'get',
          url: '/users/me/',
        });
      },
    },
    users: {
      get({ id, params } = {}) {
        let url = '/local/users';

        if (id) {
          url += `/${id}/`;
        }
        return client.request({
          method: 'get',
          url,
          params,
        });
      },
      create(data) {
        return client.request({
          method: 'post',
          url: '/local/users/',
          data,
          headers: { 'Content-Type': 'multipart/form-data;' },
        });
      },
      update(id, data) {
        return client.request({
          method: 'put',
          url: `/local/users/${id}/`,
          data,
          headers: { 'Content-Type': 'multipart/form-data;' },
        });
      },
      requestResetPassword(id) {
        return client.request({
          method: 'post',
          url: `/users/${id}/reset/`,
        });
      },
    },
    states: {
      get() {
        return client.request({
          method: 'get',
          url: '/states/',
        });
      },
    },
    customers: {
      get({ id, params } = {}) {
        let url = '/customers/';

        if (id) {
          url += `${id}/`;
        }
        return client.request({
          method: 'get',
          url,
          params,
        });
      },
      create(data) {
        return client.request({
          method: 'post',
          url: '/customers/',
          data,
        });
      },
      update(id, data) {
        return client.request({
          method: 'put',
          url: `/customers/${id}/`,
          data,
        });
      },
    },
    prescriptions: {
      get({ id, params } = {}) {
        let url = '/prescriptions/';

        if (id) {
          url += `${id}/`;
        }
        return client.request({
          method: 'get',
          url,
          params,
        });
      },
      create(data) {
        return client.request({
          method: 'post',
          url: '/prescriptions/',
          data,
          headers: { 'Content-Type': 'multipart/form-data;' },
        });
      },
      update(id, data) {
        return client.request({
          method: 'put',
          url: `/prescriptions/${id}/`,
          data,
          headers: { 'Content-Type': 'multipart/form-data;' },
        });
      },
      patch(id, data) {
        return client.request({
          method: 'patch',
          url: `/prescriptions/${id}/`,
          data,
        });
      },
    },
    medications: {
      get({ id, params } = {}) {
        let url = '/medications-portal/';

        if (id) {
          url = `/medications/${id}/`;
        }
        return client.request({
          method: 'get',
          url,
          params,
        });
      },
    },
    onlineRefills: {
      get({ id, params } = {}) {
        let url = '/onlinerefillrequests/';

        if (id) {
          url += `${id}/`;
        }
        return client.request({
          method: 'get',
          url,
          params,
        });
      },
      update(id, data) {
        return client.request({
          method: 'put',
          url: `/onlinerefillrequests/${id}/`,
          data,
        });
      },
      patch(id, data) {
        return client.request({
          method: 'patch',
          url: `/onlinerefillrequests/${id}/`,
          data,
        });
      },
    },
    refillRequests: {
      get({ id, params } = {}) {
        let url = '/refillrequests/';

        if (id) {
          url += `${id}/`;
        }
        return client.request({
          method: 'get',
          url,
          params,
        });
      },
      create(data) {
        return client.request({
          method: 'post',
          url: '/refillrequests/',
          data,
        });
      },
      update(id, data) {
        return client.request({
          method: 'put',
          url: `/refillrequests/${id}/`,
          data,
        });
      },
      patch(id, data) {
        return client.request({
          method: 'patch',
          url: `/refillrequests/${id}/`,
          data,
        });
      },
    },
    species: {
      get({ id, params } = {}) {
        let url = '/animalspecies/';

        if (id) {
          url += `${id}/`;
        }
        return client.request({
          method: 'get',
          url,
          params,
        });
      },
    },
    activitylogs: {
      get({ id, params } = {}) {
        let url = '/activitylogs/';

        if (id) {
          url += `${id}/`;
        }
        return client.request({
          method: 'get',
          url,
          params,
        });
      },
    },
    techBulletins: {
      get({ params } = {}) {
        const url = '/techbulletins/';

        return client.request({
          method: 'get',
          url,
          params,
        });
      },
    },
  };
  return api;
};
