import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Edit, Visibility } from '@material-ui/icons';
import {
  TablePagination, TableRow, IconButton, Hidden,
} from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import { ROLE_VET, ROLE_WATCHER } from '../../constants/roles';
import {
  NEW,
  PRESCRIPTIONS_STATUS_LIST,
  PRESCRIPTIONS_STATUS_COLOR,
  PRESCRIPTIONS_STATUS_LABEL,
} from '../../constants/prescriptions';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import StatusIcon from '../../components/StatusIcon/StatusIcon';
import DeaStatusBaneer from '../DeaStatusBanner/DeaStatusBanner';
import LicenseStatusBanner from '../LicenseStatusBanner/LicenseStatusBanner';
import FaxPrescriptionsBanner from '../FaxPrescriptionsBanner/FaxPrescriptionsBanner';
import removeEmptyKeys from '../../utils/removeEmptyKeys';

class PrescriptionsTable extends Component {
  static propTypes = {
    prescriptionStore: mobxPropTypes.observableObject.isRequired,
    loadingStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    userStore: mobxPropTypes.observableObject.isRequired,
    medicationStore: mobxPropTypes.observableObject.isRequired,
    customerStore: mobxPropTypes.observableObject.isRequired,
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    match: PropTypes.oneOfType([PropTypes.object]).isRequired,
    paginate: PropTypes.bool,
    showEmptyRows: PropTypes.bool,
    defaultLimit: PropTypes.number,
  };

  static defaultProps = {
    paginate: false,
    showEmptyRows: false,
    defaultLimit: 25,
  };

  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;

    this.state = {
      data: [],
      _limit: defaultLimit,
      filters: {},
      total: 0,
      page: 0,
      clearMedication: false,
      clearPrescriber: false,
      clearCustomer: false,
    };
  }

  componentWillMount() {
    this.fetchData({ status: NEW });
  }

  static getVetTableHeader() {
    return [
      { id: 1, label: 'Date', hidden: { smDown: true } },
      { id: 2, label: 'Client' },
      { id: 3, label: 'Medication' },
      { id: 4, label: 'Status', hidden: { xsDown: true } },
      { id: 5, label: 'ID', hidden: { xsDown: true } },
      { id: 6, label: '' },
    ];
  }

  static getPharmaTableHeader() {
    return [
      { id: 1, label: 'Date', hidden: { smDown: true } },
      { id: 2, label: 'Prescriber' },
      { id: 3, label: 'Client', hidden: { smDown: true } },
      { id: 4, label: 'Medication' },
      { id: 5, label: 'Status', hidden: { xsDown: true } },
      { id: 6, label: '' },
    ];
  }

  fetchData = async (searchFilters = null, reset = false) => {
    const { prescriptionStore, loadingStore } = this.props;
    loadingStore.setReady(false);

    const { _limit, page, filters } = this.state;
    const newFilters = removeEmptyKeys(searchFilters || filters);

    if (newFilters.created_at_gte && newFilters.created_atb_lte) {
      newFilters.created_at_gte = moment(newFilters.created_at_gte)
        .startOf('day')
        .format();
      newFilters.created_atb_lte = moment(newFilters.created_atb_lte)
        .endOf('day')
        .format();
    }

    const offset = _limit * page;
    const params = Object.assign(
      { _limit, _start: offset, _sort: 'id:DESC' },
      reset ? {} : newFilters,
    );

    try {
      const data = await prescriptionStore.api.prescriptions.get({ id: null, params });

      this.setState({
        data: data.results || [],
        filters: reset ? {} : newFilters,
        total: data.totalCount || 0,
      });
    } catch (error) {
      this.setState({
        data: [],
        filters: newFilters,
      });
    }

    loadingStore.setReady(true);
  };

  handleMedicationsAutoComplete = async (input) => {
    if (!input) {
      return [];
    }
    const { medicationStore } = this.props;
    return medicationStore.fetchMedications(input);
  };

  handleCustomersAutoComplete = async (input) => {
    if (!input) {
      return [];
    }
    const { customerStore } = this.props;
    return customerStore.fetchCustomers(input);
  };

  handlePrescribersAutoComplete = async (input) => {
    if (!input) {
      return [];
    }
    const { userStore } = this.props;
    return userStore.fetchUsers(input, ROLE_VET);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.fetchData());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ _limit: event.target.value }, () => this.fetchData());
  };

  handleEditClick = (row) => {
    const { history, match } = this.props;
    history.push(`${match.url}/edit/${row.id}`);
  };

  handleClear = (clearObj) => {
    this.setState(clearObj);
  };

  handleReset = async () => {
    this.setState(
      {
        filters: { status: NEW },
        clearMedication: true,
        clearPrescriber: true,
        clearCustomer: true,
      },
      () => this.fetchData(),
    );
  };

  renderVetRows = () => {
    const { data } = this.state;

    return data.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <Hidden smDown>
          <TableCell component="th" scope="row" padding="default">
            {moment(row.created_at).format('MMM D, YYYY')}
          </TableCell>
        </Hidden>
        <TableCell>
          {row.customer ? `${row.customer.firstName} ${row.customer.lastName}` : '-'}
        </TableCell>
        <TableCell>{row.medication ? row.medication.name : '-'}</TableCell>
        <Hidden xsDown>
          <TableCell>
            {row.status ? (
              <React.Fragment>
                <StatusIcon color={PRESCRIPTIONS_STATUS_COLOR[row.status]} spacing="right" />
                {PRESCRIPTIONS_STATUS_LABEL[row.status]}
              </React.Fragment>
            ) : (
              '-'
            )}
          </TableCell>
        </Hidden>
        <Hidden xsDown>
          <TableCell>{row.prescriptionId || '-'}</TableCell>
        </Hidden>
        <TableCell padding="none" isActions>
          <IconButton
            aria-label={row.status === NEW ? 'Edit' : 'View'}
            color="secondary"
            onClick={() => this.handleEditClick(row)}
          >
            {row.status === NEW ? <Edit /> : <Visibility />}
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  renderPharmaRows = () => {
    const { data } = this.state;
    const { authStore } = this.props;
    const { getRole: userRole } = authStore;
    const isWatcher = userRole === ROLE_WATCHER;

    return data.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <Hidden smDown>
          <TableCell component="th" scope="row" padding="default">
            {moment(row.created_at).format('MMM D, YYYY')}
          </TableCell>
        </Hidden>
        <TableCell>{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
        <Hidden smDown>
          <TableCell>
            {row.customer ? `${row.customer.firstName} ${row.customer.lastName}` : '-'}
          </TableCell>
        </Hidden>
        <TableCell>{row.medication ? row.medication.name : '-'}</TableCell>
        <Hidden xsDown>
          <TableCell>
            {row.status ? (
              <React.Fragment>
                <StatusIcon color={PRESCRIPTIONS_STATUS_COLOR[row.status]} spacing="right" />
                {PRESCRIPTIONS_STATUS_LABEL[row.status]}
              </React.Fragment>
            ) : (
              '-'
            )}
          </TableCell>
        </Hidden>
        <TableCell padding="none" isActions>
          <IconButton
            aria-label={row.status !== NEW && !isWatcher ? 'Edit' : 'View'}
            color="secondary"
            onClick={() => this.handleEditClick(row)}
          >
            {row.status !== NEW && !isWatcher ? <Edit /> : <Visibility />}
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  renderPagination = () => {
    const { total, _limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={_limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  };

  renderSearch = () => {
    const { authStore } = this.props;
    const { clearPrescriber, clearMedication, clearCustomer } = this.state;

    const vetFields = [
      {
        id: 'medication',
        name: 'medication',
        label: 'Medication',
        type: 'autocomplete',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
        customProps: {
          defaultOptions: [],
          loadOptions: this.handleMedicationsAutoComplete,
          handleClear: this.handleClear,
          clearMedication,
        },
      },
      {
        id: 'customer',
        name: 'customer',
        label: 'Client',
        type: 'autocomplete',
        validateAs: 'text',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
        customProps: {
          defaultOptions: [],
          loadOptions: this.handleCustomersAutoComplete,
          handleClear: this.handleClear,
          clearCustomer,
        },
      },
    ];

    const pharmaFields = [
      {
        id: 'user',
        name: 'user',
        label: 'Prescriber',
        type: 'autocomplete',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
        customProps: {
          defaultOptions: [],
          loadOptions: this.handlePrescribersAutoComplete,
          handleClear: this.handleClear,
          clearPrescriber,
        },
      },
      {
        id: 'medication',
        name: 'medication',
        label: 'Medication',
        type: 'autocomplete',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
        customProps: {
          defaultOptions: [],
          loadOptions: this.handleMedicationsAutoComplete,
          handleClear: this.handleClear,
          clearMedication,
        },
      },
    ];

    const defaultFields = [
      {
        id: 'created_at_gte',
        name: 'created_at_gte',
        label: 'Date From',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        id: 'created_atb_lte',
        name: 'created_atb_lte',
        label: 'Date To',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        id: 'status',
        name: 'status',
        label: 'Status',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'text',
        defaultVal: NEW,
        required: false,
        customProps: {
          inputProps: {
            name: 'status',
            id: 'status',
          },
          data: PRESCRIPTIONS_STATUS_LIST,
        },
      },
    ];

    return (
      <SearchForm
        onSubmit={this.fetchData}
        onReset={this.handleReset}
        fields={(authStore.getRole === ROLE_VET ? vetFields : pharmaFields).concat(defaultFields)}
      />
    );
  };

  render() {
    const { data, _limit, page } = this.state;
    const {
      paginate,
      showEmptyRows,
      authStore: { getRole },
    } = this.props;
    const isVetRole = getRole === ROLE_VET;

    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = _limit - Math.min(_limit, data.length - page * _limit);
    }

    const props = {
      header: isVetRole
        ? PrescriptionsTable.getVetTableHeader()
        : PrescriptionsTable.getPharmaTableHeader(),
      renderRows: isVetRole ? this.renderVetRows : this.renderPharmaRows,
      emptyRows,
      paginate,
      renderPagination: this.renderPagination,
      empty: data.length === 0,
    };

    return (
      <Fragment>
        {isVetRole && (
          <Fragment>
            <FaxPrescriptionsBanner />
            <LicenseStatusBanner />
            <DeaStatusBaneer />
          </Fragment>
        )}
        {this.renderSearch()}
        <DataTable {...props} />
      </Fragment>
    );
  }
}

export default inject(
  'prescriptionStore',
  'authStore',
  'loadingStore',
  'medicationStore',
  'customerStore',
  'userStore',
)(observer(PrescriptionsTable));
