import React, { Fragment, Component } from 'react';
import {
  oneOfType, object, bool, number,
} from 'prop-types';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';

import {
  TablePagination, TableRow, IconButton, Hidden,
} from '@material-ui/core';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import { ROLE_WATCHER, ROLE_VET } from '../../constants/roles';
import {
  NEW,
  REFILLS_STATUS_LIST,
  REFILLS_STATUS_COLOR,
  REFILLS_STATUS_LABEL,
} from '../../constants/refillsStatus';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import StatusIcon from '../../components/StatusIcon/StatusIcon';
import DeaStatusBaneer from '../DeaStatusBanner/DeaStatusBanner';
import LicenseStatusBanner from '../LicenseStatusBanner/LicenseStatusBanner';

import removeEmptyKeys from '../../utils/removeEmptyKeys';

class OnlineRefillsTable extends Component {
  static propTypes = {
    refillRequestStore: mobxPropTypes.observableObject.isRequired,
    customerStore: mobxPropTypes.observableObject.isRequired,
    loadingStore: mobxPropTypes.observableObject.isRequired,
    userStore: mobxPropTypes.observableObject.isRequired,
    authStore: mobxPropTypes.observableObject.isRequired,
    history: oneOfType([object]).isRequired,
    match: oneOfType([object]).isRequired,
    paginate: bool,
    showEmptyRows: bool,
    defaultLimit: number,
  };

  static defaultProps = {
    paginate: false,
    showEmptyRows: false,
    defaultLimit: 10,
  };

  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;

    this.state = {
      data: [],
      _limit: defaultLimit,
      filters: {},
      total: 0,
      page: 0,
      clearCustomer: false,
      clearPrescriber: false,
    };
  }

  componentWillMount() {
    this.fetchData({ status: NEW });
  }

  static getPharmaTableHeader() {
    return [
      { id: 1, label: 'Date', hidden: { smDown: true } },
      { id: 2, label: 'Client' },
      { id: 3, label: 'Prescriber', hidden: { smDown: true } },
      { id: 4, label: 'Status', hidden: { xsDown: true } },
      { id: 5, label: '' },
    ];
  }

  static getVetTableHeader() {
    return [
      { id: 1, label: 'ID' },
      { id: 2, label: 'Date', hidden: { smDown: true } },
      { id: 3, label: 'Client' },
      { id: 4, label: 'Status', hidden: { xsDown: true } },
      { id: 5, label: '' },
    ];
  }

  fetchData = async (searchFilters = null, reset = false) => {
    const { refillRequestStore, loadingStore } = this.props;
    loadingStore.setReady(false);

    const { _limit, page, filters } = this.state;
    const newFilters = removeEmptyKeys(searchFilters || filters);
    // Dirty trick to add time
    if (newFilters.created_at_gte && newFilters.dateTo_lte) {
      newFilters.created_at_gte = moment(newFilters.created_at_gte)
        .startOf('day')
        .format();
      newFilters.dateTo_lte = moment(newFilters.dateTo_lte)
        .endOf('day')
        .format();
    }
    const offset = _limit * page;
    const params = Object.assign(
      { _limit, _start: offset, _sort: 'id:DESC' },
      reset ? {} : newFilters,
    );

    try {
      const data = await refillRequestStore.api.refillRequests.get({ id: null, params });
      this.setState({
        data: data.results || [],
        filters: reset ? {} : newFilters,
        total: data.totalCount || 0,
      });
    } catch (error) {
      this.setState({
        data: [],
        filters: newFilters,
      });
    }

    loadingStore.setReady(true);
  };

  handleClear = (clearObj) => {
    this.setState(clearObj);
  };

  handleReset = async () => {
    this.setState(
      {
        filters: { status: NEW },
        clearCustomer: true,
        clearPrescriber: true,
      },
      () => this.fetchData(),
    );
  };

  handleCustomersAutoComplete = async (input) => {
    if (!input) {
      return [];
    }
    const { customerStore } = this.props;
    return customerStore.fetchCustomers(input);
  };

  handlePrescribersAutoComplete = async (input) => {
    if (!input) {
      return [];
    }
    const { userStore } = this.props;
    return userStore.fetchUsers(input, ROLE_VET);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.fetchData());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ _limit: event.target.value }, () => this.fetchData());
  };

  handleEditClick = (row) => {
    const { history, match } = this.props;
    history.push(`${match.url}/edit/${row.id}`);
  };

  renderPharmaRows = () => {
    const { data } = this.state;
    const { authStore } = this.props;
    const isWatcher = authStore.getRole === ROLE_WATCHER;

    return data.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <Hidden smDown>
          <TableCell component="th" scope="row" padding="default">
            {moment(row.created_at).format('MMM D, YY')}
          </TableCell>
        </Hidden>
        <TableCell>{`${row.customer.firstName} ${row.customer.lastName}`}</TableCell>
        <Hidden smDown>
          <TableCell>{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
        </Hidden>
        <Hidden xsDown>
          <TableCell>
            {row.status ? (
              <React.Fragment>
                <StatusIcon color={REFILLS_STATUS_COLOR[row.status]} spacing="right" />
                {REFILLS_STATUS_LABEL[row.status]}
              </React.Fragment>
            ) : (
              '-'
            )}
          </TableCell>
        </Hidden>
        <TableCell padding="none" isActions>
          <IconButton
            aria-label={row.status !== NEW && !isWatcher ? 'Edit' : 'View'}
            color="secondary"
            onClick={() => this.handleEditClick(row)}
          >
            {row.status !== NEW && !isWatcher ? <EditIcon /> : <ViewIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  renderVetRows = () => {
    const { data } = this.state;

    return data.map(row => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell component="th" scope="row" padding="default">
          {row.id}
        </TableCell>
        <Hidden smDown>
          <TableCell>{moment(row.created_at).format('MMM D, YY')}</TableCell>
        </Hidden>
        <TableCell>{`${row.customer.firstName} ${row.customer.lastName}`}</TableCell>
        <Hidden xsDown>
          <TableCell>
            {row.status ? (
              <React.Fragment>
                <StatusIcon color={REFILLS_STATUS_COLOR[row.status]} spacing="right" />
                {REFILLS_STATUS_LABEL[row.status]}
              </React.Fragment>
            ) : (
              '-'
            )}
          </TableCell>
        </Hidden>
        <TableCell padding="none" isActions>
          <IconButton
            aria-label={row.status === NEW ? 'Edit' : 'View'}
            color="secondary"
            onClick={() => this.handleEditClick(row)}
          >
            {row.status === NEW ? <EditIcon /> : <ViewIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  renderPagination = () => {
    const { total, _limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={_limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  };

  renderSearch = () => {
    const { authStore } = this.props;
    const { clearCustomer, clearPrescriber } = this.state;

    const vetFields = [
      {
        id: 'customer',
        name: 'customer',
        label: 'Client',
        type: 'autocomplete',
        validateAs: 'text',
        defaultVal: '',
        required: false,
        customProps: {
          defaultOptions: [],
          loadOptions: this.handleCustomersAutoComplete,
          handleClear: this.handleClear,
          clearCustomer,
        },
      },
    ];

    const pharmaFields = [
      {
        id: 'customer',
        name: 'customer',
        label: 'Client',
        type: 'autocomplete',
        validateAs: 'text',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
        customProps: {
          defaultOptions: [],
          loadOptions: this.handleCustomersAutoComplete,
          handleClear: this.handleClear,
          clearCustomer,
        },
      },
      {
        id: 'user',
        name: 'user',
        label: 'Prescriber',
        type: 'autocomplete',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        span: { xs: 0, sm: 3, md: 3 },
        customProps: {
          defaultOptions: [],
          loadOptions: this.handlePrescribersAutoComplete,
          handleClear: this.handleClear,
          clearPrescriber,
        },
      },
    ];

    const defaultFields = [
      {
        id: 'status',
        name: 'status',
        label: 'Status',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'text',
        defaultVal: NEW,
        required: false,
        customProps: {
          inputProps: {
            name: 'status',
            id: 'status',
          },
          data: REFILLS_STATUS_LIST,
        },
      },
      {
        id: 'created_at_gte',
        name: 'created_at_gte',
        label: 'Date From',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        id: 'dateTo_lte',
        name: 'dateTo_lte',
        label: 'Date To',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
    ];

    return (
      <SearchForm
        onSubmit={this.fetchData}
        onReset={this.handleReset}
        fields={(authStore.getRole === ROLE_VET ? vetFields : pharmaFields).concat(defaultFields)}
      />
    );
  };

  render() {
    const { data, _limit, page } = this.state;
    const {
      paginate,
      showEmptyRows,
      authStore: { getRole },
    } = this.props;
    const isVetRole = getRole === ROLE_VET;

    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = _limit - Math.min(_limit, data.length - page * _limit);
    }

    const props = {
      header: isVetRole
        ? OnlineRefillsTable.getVetTableHeader()
        : OnlineRefillsTable.getPharmaTableHeader(),
      renderRows: isVetRole ? this.renderVetRows : this.renderPharmaRows,
      emptyRows,
      paginate,
      renderPagination: this.renderPagination,
      empty: data.length === 0,
    };

    return (
      <Fragment>
        {isVetRole && (
          <Fragment>
            <LicenseStatusBanner />
            <DeaStatusBaneer />
          </Fragment>
        )}
        {this.renderSearch()}
        <DataTable {...props} />
      </Fragment>
    );
  }
}

export default inject(
  'refillRequestStore',
  'customerStore',
  'userStore',
  'authStore',
  'loadingStore',
)(observer(OnlineRefillsTable));
