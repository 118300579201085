import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import PrescriptionsList from './Index';
import PrescriptionEditPage from './Edit';
import PrescriptionAddPage from './Add';

import { ROLE_PHARMACIST, ROLE_VET, ROLE_WATCHER } from '../../constants/roles';

const propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const PrescriptionsPage = ({ match }) => (
  <Switch>
    <PrivateRoute
      path={match.url}
      exact
      component={PrescriptionsList}
      roles={[ROLE_PHARMACIST, ROLE_VET, ROLE_WATCHER]}
    />
    <PrivateRoute
      path={`${match.url}/add/:type?/:typeId?`}
      component={PrescriptionAddPage}
      roles={[ROLE_VET]}
    />
    <PrivateRoute
      path={`${match.url}/edit/:prescriptionId/:type?/:typeId?`}
      component={PrescriptionEditPage}
      roles={[ROLE_PHARMACIST, ROLE_VET, ROLE_WATCHER]}
    />
    <Redirect to="/prescriptions" />
  </Switch>
);

PrescriptionsPage.propTypes = propTypes;
export default PrescriptionsPage;
