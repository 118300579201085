import { observable, action, decorate } from 'mobx';

class MedicationStore {
  constructor(api, snackbarStore, loadingStore) {
    this.api = api;
    this.snackbarStore = snackbarStore;
    this.loadingStore = loadingStore;
    this.medications = null;
  }

  setMedications(medications) {
    this.medications = medications;
  }

  get getMedications() {
    return this.medications;
  }

  async fetch() {
    try {
      const response = await this.api.medications.get();
      if (response) {
        this.setMedications(response);
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    } catch (e) {
      e.error = true;
      return Promise.reject(e);
    }
  }

  async fetchMedication(id) {
    this.loadingStore.setReady(false);

    try {
      const response = await this.api.medications.get({ id });
      this.loadingStore.setReady(true);
      return response;
    } catch (e) {
      this.snackbarStore.setError({
        title: e.response.status === 404 ? 'Medication not found' : 'Sorry, an error occurred.',
      });
      this.loadingStore.setReady(true);
      e.error = true;
      return e;
    }
  }

  async fetchMedications(name, extraParams = {}) {
    let results = [];
    const params = {
      name_contains: name,
      ...extraParams,
    };

    const response = await this.api.medications.get({ id: null, params });

    if (response && response.results) {
      results = response.results.map(medication => ({
        value: medication.id,
        label: `${medication.name} ${medication.presentation}`,
      }));
    }

    return results;
  }
}

decorate(MedicationStore, {
  medications: observable,
  setMedications: action,
});

export default MedicationStore;
