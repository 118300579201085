import axios from 'axios';

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
  refreshSubscribers.map(cb => cb(token));
};

export default ({
  config: { api },
  getToken = () => undefined,
  setToken = () => undefined,
  logout = () => undefined,
} = {}) => {
  const client = axios.create(api);

  const parseErrors = (errors) => {
    const parsed = Object.keys(errors).map((keyError) => {
      const message = errors[keyError];
      return Array.isArray(message) ? message : Array(message);
    });

    return parsed.reduce((acc, val) => acc.concat(val), []);
  };

  client.interceptors.request.use(
    (config) => {
      const token = getToken && getToken();

      if (token) {
        Object.assign(config.headers, { Authorization: `Bearer ${token}` });
      }

      return config;
    },
    (e) => {
      e.error = true;
      return Promise.reject(e);
    },
  );

  client.interceptors.response.use(
    (response) => {
      const { data } = response;
      return data;
    },
    (e) => {
      const originalRequest = e.config;

      if (!e.response) {
        onRefreshed(null);
        logout();
      } else if (e.response.status === 400 && e.response.data.error) {
        e.errors = parseErrors(e.response.data.error);
      } else if (e.response.status === 401) {
        const token = getToken && getToken();

        if (token && originalRequest.retryRefresh === undefined) {
          if (!isRefreshing) {
            originalRequest.retryRefresh = true;

            isRefreshing = true;
            refreshSubscribers = [];

            axios
              .post(`${api.baseURL}/auth/refresh-token/`, { token })
              .then(({ data }) => {
                isRefreshing = false;

                setToken(data.token);
                onRefreshed(data.token);
              })
              .catch(() => {
                onRefreshed(null);
                logout();
              });
          }

          const retryOrigReq = new Promise((resolve, reject) => {
            subscribeTokenRefresh((refreshToken) => {
              if (refreshToken === null) {
                reject();
              } else {
                resolve(client(originalRequest));
              }
            });
          });

          return retryOrigReq;
        }
      }

      e.error = true;
      return Promise.reject(e);
    },
  );

  return client;
};
