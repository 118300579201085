import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as MobxProvider } from 'mobx-react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { AppContainer } from 'react-hot-loader';

import createApi from './services/api';
import config from './config';

import AuthStore from './stores/auth';
import UserStore from './stores/user';
import SnackbarStore from './stores/snackbar';
import LoadingStore from './stores/loading';
import CustomerStore from './stores/customer';
import PrescriptionStore from './stores/prescription';
import StateStore from './stores/state';
import MedicationStore from './stores/medication';
import SpecieStore from './stores/specie';
import AuditLogStore from './stores/auditLog';
import OnlineRefillsStore from './stores/onlineRefills';
import RefillRequestStore from './stores/refillRequest';
import TechBulletinsStore from './stores/techBulletins';

import Storage from './services/storage';
import App from './containers/App/App';
import defaultTheme from './styles/theme';

const api = createApi({
  config,
});

const snackbarStore = new SnackbarStore();
const loadingStore = new LoadingStore();
const stores = {
  snackbarStore,
  authStore: new AuthStore(api, Storage, snackbarStore, loadingStore),
  userStore: new UserStore(api, snackbarStore, loadingStore),
  customerStore: new CustomerStore(api, snackbarStore, loadingStore),
  prescriptionStore: new PrescriptionStore(api, snackbarStore, loadingStore),
  medicationStore: new MedicationStore(api, snackbarStore, loadingStore),
  specieStore: new SpecieStore(api, snackbarStore, loadingStore),
  stateStore: new StateStore(api),
  auditLogStore: new AuditLogStore(api, loadingStore),
  onlineRefillsStore: new OnlineRefillsStore(api, snackbarStore, loadingStore),
  refillRequestStore: new RefillRequestStore(api, snackbarStore, loadingStore),
  techBulletinsStore: new TechBulletinsStore(api, snackbarStore, loadingStore),
  loadingStore,
};

const theme = createMuiTheme(defaultTheme);

const render = (Component) => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <MobxProvider {...stores}>
        <AppContainer>
          <Component />
        </AppContainer>
      </MobxProvider>
    </MuiThemeProvider>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot && process.env.REACT_APP_STAGE === 'dev') {
  module.hot.accept('./containers/App/App', () => {
    render(App);
  });
}
