import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Hidden,
  Typography,
} from '@material-ui/core';

import TableCell from './TableCell';
import NotResultIcon from '../../assets/images/icon-no-search-result.png';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 4,
  },
  content: {
    width: '100%',
  },
  table: {
    width: '100%',
    '& thead > tr > th': {
      fontSize: 13,
      fontWeight: 'bold',
      color: '#000',
      backgroundColor: '#f2f2f2',
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  emptyContainer: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  emptyContent: {
    paddingLeft: 50,
    '@media (max-width: 900px)': {
      paddingLeft: 0,
      paddingTop: 15,
    },
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  header: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRows: PropTypes.func.isRequired,
  paginate: PropTypes.bool,
  renderPagination: PropTypes.func,
  emptyRows: PropTypes.number,
  card: PropTypes.bool,
  empty: PropTypes.bool,
};

const defaultProps = {
  renderPagination: () => {},
  paginate: false,
  emptyRows: 0,
  card: true,
  empty: false,
};

const renderHeader = colums => colums.map((column) => {
  const cell = (
    <TableCell key={column.id} padding="default">
      {column.label}
    </TableCell>
  );

  if (column.hidden) {
    return (
      <Hidden key={column.id} {...column.hidden}>
        {cell}
      </Hidden>
    );
  }

  return cell;
});

const renderEmpty = classes => (
  <div className={classes.content}>
    <div className={classes.emptyContainer}>
      <img src={NotResultIcon} alt="no-results" />
      <div className={classes.emptyContent}>
        <Typography variant="h5" component="h2">
          No Results Found
        </Typography>
      </div>
    </div>
  </div>
);

const DataTable = ({
  classes,
  header,
  renderRows,
  paginate,
  renderPagination,
  emptyRows,
  card,
  empty,
}) => {
  const pagination = paginate ? renderPagination() : null;
  let content = (
    <div className={classes.content}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>{renderHeader(header)}</TableRow>
          </TableHead>
          <TableBody>
            {renderRows()}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {pagination}
    </div>
  );
  if (card) {
    content = <Paper className={classes.root}>{content}</Paper>;
  }
  if (empty) {
    content = renderEmpty(classes);
  }
  return content;
};

DataTable.propTypes = propTypes;
DataTable.defaultProps = defaultProps;
export default withStyles(styles)(DataTable);
