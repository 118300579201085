import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer, PropTypes as mobxPropTypes } from 'mobx-react';

import ProfileForm from '../containers/ProfileForm/ProfileForm';
import ChangePasswordForm from '../containers/ChangePasswordForm/ChangePasswordForm';

const styles = () => ({
  mTop: {
    marginTop: 30,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  authStore: mobxPropTypes.observableObject.isRequired,
};

const ProfilePage = ({ classes, authStore }) => (
  <Fragment>
    <ProfileForm user={authStore.user} />
    <div className={classes.mTop}>
      <ChangePasswordForm />
    </div>
  </Fragment>
);

ProfilePage.propTypes = propTypes;
const ProfileStyled = withStyles(styles, { withTheme: false })(ProfilePage);
export default inject('authStore', 'snackbarStore')(observer(ProfileStyled));
