import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';

import CustomersList from './Index';
import CustomerEditPage from './Edit';
import CustomerAddPage from './Add';

import { ROLE_VET } from '../../constants/roles';

const propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const CustomersPage = ({ match }) => (
  <Switch>
    <PrivateRoute path={match.url} exact component={CustomersList} roles={[ROLE_VET]} />
    <PrivateRoute
      path={`${match.url}/add/:referrer?/:referrerId?`}
      component={CustomerAddPage}
      roles={[ROLE_VET]}
    />
    <PrivateRoute
      path={`${match.url}/edit/:customerId`}
      component={CustomerEditPage}
      roles={[ROLE_VET]}
    />
    <Redirect to="/clients" />
  </Switch>
);

CustomersPage.propTypes = propTypes;
export default CustomersPage;
