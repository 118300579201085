import {
  observable, computed, action, decorate,
} from 'mobx';

import { ROLE_LABEL, ROLE_VET } from '../constants/roles';

import events from '../utils/events';
import errorParse from '../utils/errorParse';

class AuthStore {
  constructor(api, storage, snackbarStore, loadingStore) {
    this.api = api;
    this.storage = storage;
    this.snackbarStore = snackbarStore;
    this.loadingStore = loadingStore;
    this.token = this.storage.getItem('token') || null;
    this.user = null;
    this.isReady = true;
    this.isExpired = false;

    if (this.token !== null) {
      this.isReady = false;
      this.api.setToken(this.token);

      this.me()
        .catch(() => {
          this.logout();
        })
        .finally(() => {
          this.isReady = true;
        });
    }

    events.addListener('logout', this.expire.bind(this));
    events.addListener('setToken', this.setToken.bind(this));
  }

  setToken(token) {
    this.api.setToken(token);
    this.token = token;
    this.storage.setItem('token', token);
  }

  get getToken() {
    return this.token;
  }

  setUser(user) {
    this.user = user;
  }

  get getUser() {
    return this.user;
  }

  get getRole() {
    return this.user.role.id;
  }

  get getDeaStatus() {
    const {
      role: { id },
      vetprofile,
    } = this.user;

    return id === ROLE_VET && vetprofile.deaNumber ? vetprofile.deaStatus : null;
  }

  get getLicenseStatus() {
    const {
      role: { id },
      vetprofile,
    } = this.user;

    return id === ROLE_VET && vetprofile.licenseStatus ? vetprofile.licenseStatus : null;
  }

  get isLoggedIn() {
    return this.token !== null && this.user !== null;
  }

  setExpired(val) {
    this.isExpired = val;
  }

  async login(email, password) {
    try {
      const response = await this.api.auth.login({ email, password });

      if (response.jwt && response.user) {
        if (Object.prototype.hasOwnProperty.call(ROLE_LABEL, response.user.role.id)) {
          this.setToken(response.jwt);
          this.setUser(response.user);
          this.setExpired(false);
          return Promise.resolve(true);
        }

        return Promise.reject(
          new Error(
            this.snackbarStore.setError({
              title: 'Incorrect username or password.',
            }),
          ),
        );
      }

      return Promise.reject(response);
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: 'Sorry, an error occurred',
        duration: null,
        messages: errors || null,
      });

      return Promise.reject(e);
    }
  }

  async forgotPassword(email) {
    try {
      await this.api.auth.forgotPassword(email);
      this.snackbarStore.setSuccess({
        title: "If the email exists in our system, we've sent a password reset link to it.",
      });
      return Promise.resolve(true);
    } catch (e) {
      e.error = true;
      this.snackbarStore.setError({
        title: 'Sorry, an error occurred. Please try again later.',
      });
      return Promise.reject(e);
    }
  }

  async resetPassword(token, newPassword, confirmNewPassword) {
    try {
      const payload = {
        token,
        newPassword,
        confirmNewPassword,
      };
      await this.api.auth.resetPassword(payload);

      this.snackbarStore.setSuccess({
        title: 'Your Password has been changed.',
      });

      return Promise.resolve(true);
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred. ${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });
      return Promise.reject(e);
    }
  }

  async changePassword(payload) {
    this.loadingStore.setReady(false);
    try {
      await this.api.auth.changePassword(payload);
      this.snackbarStore.setSuccess({
        title: 'Your password has been changed.',
      });
      this.loadingStore.setReady(true);

      return Promise.resolve(true);
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred. ${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });
      this.loadingStore.setReady(true);
      return Promise.reject(e);
    }
  }

  async updateProfile(id, payload) {
    this.loadingStore.setReady(false);
    try {
      await this.api.users.update(id, payload);
      this.me();

      this.snackbarStore.setSuccess({
        title: 'Your profile has been successfully updated.',
      });
      this.loadingStore.setReady(true);

      return Promise.resolve(true);
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred. ${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });
      this.loadingStore.setReady(true);
      return Promise.reject(e);
    }
  }

  async confirmEmail(confirmationToken) {
    this.loadingStore.setReady(false);
    try {
      await this.api.auth.confirmEmail(confirmationToken);

      this.snackbarStore.setSuccess({
        title: 'Your email has been confirmed.',
      });
      this.loadingStore.setReady(true);

      return Promise.resolve(true);
    } catch (e) {
      const errors = errorParse(e);
      e.error = true;

      this.snackbarStore.setError({
        title: `Sorry, an error occurred. ${errors ? ' Please correct the errors below:' : ''}`,
        duration: null,
        messages: errors || null,
      });
      this.loadingStore.setReady(true);
      return Promise.reject(e);
    }
  }

  logout() {
    this.setToken(null);
    this.setUser(null);
    this.storage.clear();
  }

  expire() {
    this.setExpired(true);
    this.logout();
  }

  async me() {
    this.loadingStore.setReady(false);
    try {
      const response = await this.api.auth.me();

      if (response) {
        this.setUser(response);
        this.loadingStore.setReady(true);
        return Promise.resolve(response);
      }
      this.loadingStore.setReady(true);
      return Promise.reject(response);
    } catch (e) {
      e.error = true;
      this.loadingStore.setReady(true);
      return Promise.reject(e);
    }
  }
}

decorate(AuthStore, {
  token: observable,
  user: observable,
  isReady: observable,
  isExpired: observable,
  isLoggedIn: computed,
  setToken: action,
  setUser: action,
  setExpired: action,
});

export default AuthStore;
